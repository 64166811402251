
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_91slug_93_9365A6ldxcc4Meta } from "/vercel/path0/app/pages/[[slug]].vue?macro=true";
import { default as _91slug_93PihMl2aW7bMeta } from "/vercel/path0/app/pages/events/[slug].vue?macro=true";
import { default as _91slug_93gb4eo8TLAGMeta } from "/vercel/path0/app/pages/legal/[slug].vue?macro=true";
import { default as _91slug_93Ka4Slt87YqMeta } from "/vercel/path0/app/pages/news/[slug].vue?macro=true";
import { default as _91slug_93Vy3uwduFf1Meta } from "/vercel/path0/app/pages/stores/[slug].vue?macro=true";
import { default as content_45searchEkDJPxBEPkMeta } from "/vercel/path0/app/pages/test/content-search.vue?macro=true";
import { default as developersjASFw7dA8WMeta } from "/vercel/path0/app/pages/test/developers.vue?macro=true";
import { default as formIDK15Bc3WlMeta } from "/vercel/path0/app/pages/test/form.vue?macro=true";
import { default as logos3gKFwgL0UcMeta } from "/vercel/path0/app/pages/test/logos.vue?macro=true";
import { default as styleguideBghdWyy9gUMeta } from "/vercel/path0/app/pages/test/styleguide.vue?macro=true";
import { default as _91slug_93imN3HEMXHWMeta } from "/vercel/path0/app/pages/trends/[slug].vue?macro=true";
import { default as component_45stubQb2NbO6TQ0Meta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.10.7_db0@0.2.4_encoding@0.1.13_eslint@9.20.1_km52ij6plhbn4iwjto2ucnby7a/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubQb2NbO6TQ0 } from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.10.7_db0@0.2.4_encoding@0.1.13_eslint@9.20.1_km52ij6plhbn4iwjto2ucnby7a/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___nl-be",
    path: "/nl-be/:slug?",
    component: () => import("/vercel/path0/app/pages/[[slug]].vue")
  },
  {
    name: "slug___fr-be",
    path: "/fr-be/:slug?",
    component: () => import("/vercel/path0/app/pages/[[slug]].vue")
  },
  {
    name: "events-slug___nl-be",
    path: "/nl-be/evenementen/:slug()",
    component: () => import("/vercel/path0/app/pages/events/[slug].vue")
  },
  {
    name: "events-slug___fr-be",
    path: "/fr-be/evenements/:slug()",
    component: () => import("/vercel/path0/app/pages/events/[slug].vue")
  },
  {
    name: "legal-slug___nl-be",
    path: "/nl-be/legal/:slug()",
    component: () => import("/vercel/path0/app/pages/legal/[slug].vue")
  },
  {
    name: "legal-slug___fr-be",
    path: "/fr-be/legal/:slug()",
    component: () => import("/vercel/path0/app/pages/legal/[slug].vue")
  },
  {
    name: "news-slug___nl-be",
    path: "/nl-be/nieuws/:slug()",
    component: () => import("/vercel/path0/app/pages/news/[slug].vue")
  },
  {
    name: "news-slug___fr-be",
    path: "/fr-be/nouvelles/:slug()",
    component: () => import("/vercel/path0/app/pages/news/[slug].vue")
  },
  {
    name: "stores-slug___nl-be",
    path: "/nl-be/vestigingen/:slug()",
    component: () => import("/vercel/path0/app/pages/stores/[slug].vue")
  },
  {
    name: "stores-slug___fr-be",
    path: "/fr-be/etablissements/:slug()",
    component: () => import("/vercel/path0/app/pages/stores/[slug].vue")
  },
  {
    name: "test-content-search___nl-be",
    path: "/nl-be/test/content-search",
    component: () => import("/vercel/path0/app/pages/test/content-search.vue")
  },
  {
    name: "test-content-search___fr-be",
    path: "/fr-be/test/content-search",
    component: () => import("/vercel/path0/app/pages/test/content-search.vue")
  },
  {
    name: "test-developers___nl-be",
    path: "/nl-be/test/developers",
    component: () => import("/vercel/path0/app/pages/test/developers.vue")
  },
  {
    name: "test-developers___fr-be",
    path: "/fr-be/test/developers",
    component: () => import("/vercel/path0/app/pages/test/developers.vue")
  },
  {
    name: "test-form___nl-be",
    path: "/nl-be/test/form",
    component: () => import("/vercel/path0/app/pages/test/form.vue")
  },
  {
    name: "test-form___fr-be",
    path: "/fr-be/test/form",
    component: () => import("/vercel/path0/app/pages/test/form.vue")
  },
  {
    name: "test-logos___nl-be",
    path: "/nl-be/test/logos",
    component: () => import("/vercel/path0/app/pages/test/logos.vue")
  },
  {
    name: "test-logos___fr-be",
    path: "/fr-be/test/logos",
    component: () => import("/vercel/path0/app/pages/test/logos.vue")
  },
  {
    name: "test-styleguide___nl-be",
    path: "/nl-be/test/styleguide",
    component: () => import("/vercel/path0/app/pages/test/styleguide.vue")
  },
  {
    name: "test-styleguide___fr-be",
    path: "/fr-be/test/styleguide",
    component: () => import("/vercel/path0/app/pages/test/styleguide.vue")
  },
  {
    name: "trends-slug___nl-be",
    path: "/nl-be/trends-inspiratie/:slug()",
    component: () => import("/vercel/path0/app/pages/trends/[slug].vue")
  },
  {
    name: "trends-slug___fr-be",
    path: "/fr-be/tendances-inspiration/:slug()",
    component: () => import("/vercel/path0/app/pages/trends/[slug].vue")
  },
  {
    name: component_45stubQb2NbO6TQ0Meta?.name,
    path: "/nl-be/home",
    component: component_45stubQb2NbO6TQ0
  },
  {
    name: component_45stubQb2NbO6TQ0Meta?.name,
    path: "/fr-be/home",
    component: component_45stubQb2NbO6TQ0
  },
  {
    name: component_45stubQb2NbO6TQ0Meta?.name,
    path: "/index.php",
    component: component_45stubQb2NbO6TQ0
  },
  {
    name: component_45stubQb2NbO6TQ0Meta?.name,
    path: "/nl/restaurant",
    component: component_45stubQb2NbO6TQ0
  },
  {
    name: component_45stubQb2NbO6TQ0Meta?.name,
    path: "/nl/producten/groothandel-horeca-food",
    component: component_45stubQb2NbO6TQ0
  },
  {
    name: component_45stubQb2NbO6TQ0Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubQb2NbO6TQ0
  }
]