
// @ts-nocheck


export const localeCodes =  [
  "nl-be",
  "fr-be"
]

export const localeLoaders = {
  "nl-be": [],
  "fr-be": []
}

export const vueI18nConfigs = [
  () => import("../../../../config/i18n-vue.config.ts?hash=62d2cf8d&config=1" /* webpackChunkName: "__config_i18n_vue_config_ts_62d2cf8d" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "./server/localeDetector.ts",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./config/i18n-vue.config.ts",
  "locales": [
    {
      "code": "nl-be",
      "name": "Nederlands",
      "language": "nl-be"
    },
    {
      "code": "fr-be",
      "name": "Français",
      "language": "fr-be"
    }
  ],
  "defaultLocale": "nl-be",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://www.spuntini.be",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "events/[slug]": {
      "nl-be": "/evenementen/[slug]",
      "fr-be": "/evenements/[slug]"
    },
    "news/[slug]": {
      "nl-be": "/nieuws/[slug]",
      "fr-be": "/nouvelles/[slug]"
    },
    "stores/[slug]": {
      "nl-be": "/vestigingen/[slug]",
      "fr-be": "/etablissements/[slug]"
    },
    "trends/[slug]": {
      "nl-be": "/trends-inspiratie/[slug]",
      "fr-be": "/tendances-inspiration/[slug]"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "nl-be",
    "name": "Nederlands",
    "language": "nl-be",
    "files": []
  },
  {
    "code": "fr-be",
    "name": "Français",
    "language": "fr-be",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
