import type { GraphQLClient, RequestOptions } from 'graphql-request';
import { GraphQLError, print } from 'graphql'
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BooleanType: { input: boolean; output: boolean; }
  CustomData: { input: Record<string, unknown>; output: Record<string, unknown>; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  FloatType: { input: number; output: number; }
  IntType: { input: number; output: number; }
  ItemId: { input: string; output: string; }
  JsonField: { input: unknown; output: unknown; }
  MetaTagAttributes: { input: Record<string, string>; output: Record<string, string>; }
  UploadId: { input: string; output: string; }
};

/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord = RecordInterface & {
  __typename: 'AccountPageRecord';
  _allActivateAccountActionLoginLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allActivateAccountActionSubmitLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allActivateAccountConfirmationLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allActivateAccountIntroLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allActivateAccountLabelPasswordLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allActivateAccountLabelUsernameLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allActivateAccountTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allActivateAccountValidationErrorLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allActivationMailIntroLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allActivationMailLinkHelpLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allActivationMailLinkTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allActivationMailOutroLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allActivationMailSubjectLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allChangeCustomerIntroLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allChangeCustomerTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allForgotPasswordActionLoginLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allForgotPasswordActionSubmitLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allForgotPasswordConfirmationLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allForgotPasswordIntroLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allForgotPasswordLabelEmailLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allForgotPasswordMailIntroLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allForgotPasswordMailLinkHelpLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allForgotPasswordMailLinkTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allForgotPasswordMailOutroLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allForgotPasswordMailSubjectLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allForgotPasswordTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allLoginActionForgotPasswordLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allLoginActionSubmitLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allLoginLabelPasswordLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allLoginLabelUsernameLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allLoginMessageErrorLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allLoginMessageInvalidCredentialsLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allLoginTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allResetPasswordActionLoginLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allResetPasswordActionSubmitLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allResetPasswordConfirmationLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allResetPasswordIntroLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allResetPasswordLabelConfirmPasswordLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allResetPasswordLabelCurrentPasswordLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allResetPasswordLabelNewPasswordLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allResetPasswordLabelUsernameLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allResetPasswordMessageInvalidPasswordLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allResetPasswordTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allResetPasswordValidationErrorLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  activateAccountActionLogin: Scalars['String']['output'];
  activateAccountActionSubmit: Scalars['String']['output'];
  activateAccountConfirmation: Scalars['String']['output'];
  activateAccountIntro: Scalars['String']['output'];
  activateAccountLabelPassword: Scalars['String']['output'];
  activateAccountLabelUsername: Scalars['String']['output'];
  activateAccountTitle: Scalars['String']['output'];
  activateAccountValidationError: Scalars['String']['output'];
  activationMailIntro: Scalars['String']['output'];
  activationMailLinkHelp: Scalars['String']['output'];
  activationMailLinkText: Scalars['String']['output'];
  activationMailOutro: Scalars['String']['output'];
  activationMailSubject: Scalars['String']['output'];
  changeCustomerIntro: Scalars['String']['output'];
  changeCustomerTitle: Scalars['String']['output'];
  forgotPasswordActionLogin: Scalars['String']['output'];
  forgotPasswordActionSubmit: Scalars['String']['output'];
  forgotPasswordConfirmation: Scalars['String']['output'];
  forgotPasswordIntro: Scalars['String']['output'];
  forgotPasswordLabelEmail: Scalars['String']['output'];
  forgotPasswordMailIntro: Scalars['String']['output'];
  forgotPasswordMailLinkHelp: Scalars['String']['output'];
  forgotPasswordMailLinkText: Scalars['String']['output'];
  forgotPasswordMailOutro: Scalars['String']['output'];
  forgotPasswordMailSubject: Scalars['String']['output'];
  forgotPasswordTitle: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  loginActionForgotPassword: Scalars['String']['output'];
  loginActionSubmit: Scalars['String']['output'];
  loginLabelPassword: Scalars['String']['output'];
  loginLabelUsername: Scalars['String']['output'];
  loginMessageError: Scalars['String']['output'];
  loginMessageInvalidCredentials: Scalars['String']['output'];
  loginTitle: Scalars['String']['output'];
  resetPasswordActionLogin: Scalars['String']['output'];
  resetPasswordActionSubmit: Scalars['String']['output'];
  resetPasswordConfirmation: Scalars['String']['output'];
  resetPasswordIntro: Scalars['String']['output'];
  resetPasswordLabelConfirmPassword: Scalars['String']['output'];
  resetPasswordLabelCurrentPassword: Scalars['String']['output'];
  resetPasswordLabelNewPassword: Scalars['String']['output'];
  resetPasswordLabelUsername: Scalars['String']['output'];
  resetPasswordMessageInvalidPassword: Scalars['String']['output'];
  resetPasswordTitle: Scalars['String']['output'];
  resetPasswordValidationError: Scalars['String']['output'];
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllActivateAccountActionLoginLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllActivateAccountActionSubmitLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllActivateAccountConfirmationLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllActivateAccountIntroLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllActivateAccountLabelPasswordLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllActivateAccountLabelUsernameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllActivateAccountTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllActivateAccountValidationErrorLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllActivationMailIntroLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllActivationMailLinkHelpLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllActivationMailLinkTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllActivationMailOutroLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllActivationMailSubjectLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllChangeCustomerIntroLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllChangeCustomerTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllForgotPasswordActionLoginLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllForgotPasswordActionSubmitLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllForgotPasswordConfirmationLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllForgotPasswordIntroLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllForgotPasswordLabelEmailLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllForgotPasswordMailIntroLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllForgotPasswordMailLinkHelpLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllForgotPasswordMailLinkTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllForgotPasswordMailOutroLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllForgotPasswordMailSubjectLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllForgotPasswordTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllLoginActionForgotPasswordLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllLoginActionSubmitLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllLoginLabelPasswordLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllLoginLabelUsernameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllLoginMessageErrorLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllLoginMessageInvalidCredentialsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllLoginTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllResetPasswordActionLoginLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllResetPasswordActionSubmitLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllResetPasswordConfirmationLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllResetPasswordIntroLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllResetPasswordLabelConfirmPasswordLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllResetPasswordLabelCurrentPasswordLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllResetPasswordLabelNewPasswordLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllResetPasswordLabelUsernameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllResetPasswordMessageInvalidPasswordLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllResetPasswordTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_AllResetPasswordValidationErrorLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordActivateAccountActionLoginArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordActivateAccountActionSubmitArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordActivateAccountConfirmationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordActivateAccountIntroArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordActivateAccountLabelPasswordArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordActivateAccountLabelUsernameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordActivateAccountTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordActivateAccountValidationErrorArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordActivationMailIntroArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordActivationMailLinkHelpArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordActivationMailLinkTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordActivationMailOutroArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordActivationMailSubjectArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordChangeCustomerIntroArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordChangeCustomerTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordForgotPasswordActionLoginArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordForgotPasswordActionSubmitArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordForgotPasswordConfirmationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordForgotPasswordIntroArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordForgotPasswordLabelEmailArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordForgotPasswordMailIntroArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordForgotPasswordMailLinkHelpArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordForgotPasswordMailLinkTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordForgotPasswordMailOutroArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordForgotPasswordMailSubjectArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordForgotPasswordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordLoginActionForgotPasswordArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordLoginActionSubmitArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordLoginLabelPasswordArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordLoginLabelUsernameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordLoginMessageErrorArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordLoginMessageInvalidCredentialsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordLoginTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordResetPasswordActionLoginArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordResetPasswordActionSubmitArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordResetPasswordConfirmationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordResetPasswordIntroArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordResetPasswordLabelConfirmPasswordArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordResetPasswordLabelCurrentPasswordArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordResetPasswordLabelNewPasswordArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordResetPasswordLabelUsernameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordResetPasswordMessageInvalidPasswordArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordResetPasswordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔑 Account (account_page) */
export type AccountPageRecordResetPasswordValidationErrorArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AlertModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<AlertModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AlertModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  displayType?: InputMaybe<BooleanFilter>;
  id?: InputMaybe<ItemIdFilter>;
  optTitle?: InputMaybe<StringFilter>;
  store?: InputMaybe<LinksFilter>;
  text?: InputMaybe<TextFilter>;
};

export enum AlertModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  DisplayTypeAsc = 'displayType_ASC',
  DisplayTypeDesc = 'displayType_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OptTitleAsc = 'optTitle_ASC',
  OptTitleDesc = 'optTitle_DESC'
}

/** Record of type 🔔 Alert (alert) */
export type AlertRecord = RecordInterface & {
  __typename: 'AlertRecord';
  _allOptTitleLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  displayType: Scalars['BooleanType']['output'];
  id: Scalars['ItemId']['output'];
  optTitle?: Maybe<Scalars['String']['output']>;
  store: Array<StoreRecord>;
  text: Scalars['String']['output'];
};


/** Record of type 🔔 Alert (alert) */
export type AlertRecord_AllOptTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🔔 Alert (alert) */
export type AlertRecord_AllTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🔔 Alert (alert) */
export type AlertRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔔 Alert (alert) */
export type AlertRecordOptTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🔔 Alert (alert) */
export type AlertRecordTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Block of type 📆 All Events section (all_events_section) */
export type AllEventsSectionRecord = RecordInterface & {
  __typename: 'AllEventsSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  title: Scalars['String']['output'];
};


/** Block of type 📆 All Events section (all_events_section) */
export type AllEventsSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 📯 All News section (all_news_section) */
export type AllNewsSectionRecord = RecordInterface & {
  __typename: 'AllNewsSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
};


/** Block of type 📯 All News section (all_news_section) */
export type AllNewsSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 🏢 All Stores section (all_stores_section) */
export type AllStoresSectionRecord = RecordInterface & {
  __typename: 'AllStoresSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
};


/** Block of type 🏢 All Stores section (all_stores_section) */
export type AllStoresSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 💖 All Trends & Inspiration section (all_trends_section) */
export type AllTrendsSectionRecord = RecordInterface & {
  __typename: 'AllTrendsSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
};


/** Block of type 💖 All Trends & Inspiration section (all_trends_section) */
export type AllTrendsSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter Boolean fields */
export type BooleanFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['BooleanType']['input']>;
};

export type BrandModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<BrandModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BrandModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  logo?: InputMaybe<FileFilter>;
  name?: InputMaybe<StringFilter>;
  optLinkUrl?: InputMaybe<StringFilter>;
};

export enum BrandModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OptLinkUrlAsc = 'optLinkUrl_ASC',
  OptLinkUrlDesc = 'optLinkUrl_DESC'
}

/** Record of type 🪧 Brand (brand) */
export type BrandRecord = RecordInterface & {
  __typename: 'BrandRecord';
  _allOptLinkUrlLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  logo: ImageFileField;
  name: Scalars['String']['output'];
  optLinkUrl?: Maybe<Scalars['String']['output']>;
};


/** Record of type 🪧 Brand (brand) */
export type BrandRecord_AllOptLinkUrlLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🪧 Brand (brand) */
export type BrandRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🪧 Brand (brand) */
export type BrandRecordOptLinkUrlArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 🪧 Brands section (brands_section) */
export type BrandsSectionRecord = RecordInterface & {
  __typename: 'BrandsSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  items: Array<BrandRecord>;
  optIntro?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};


/** Block of type 🪧 Brands section (brands_section) */
export type BrandsSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 🪧 Brands section (brands_section) */
export type BrandsSectionRecordOptIntroArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Block of type 🎯 Button (button) */
export type ButtonRecord = RecordInterface & {
  __typename: 'ButtonRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  label: Scalars['String']['output'];
  primary: Scalars['BooleanType']['output'];
  url: Scalars['String']['output'];
};


/** Block of type 🎯 Button (button) */
export type ButtonRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 👉 Call To Action section (call_to_action_section) */
export type CallToActionSectionRecord = RecordInterface & {
  __typename: 'CallToActionSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  backgroundImage: ImageFileField;
  id: Scalars['ItemId']['output'];
  pageLink: Array<PageLinkRecord>;
  segments: Array<CustomerSegmentRecord>;
  superTitle: Scalars['String']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Block of type 👉 Call To Action section (call_to_action_section) */
export type CallToActionSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 👉 Call To Action section (call_to_action_section) */
export type CallToActionSectionRecordTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Record of type 🏪 Catalog (catalog_page) */
export type CatalogPageRecord = RecordInterface & {
  __typename: 'CatalogPageRecord';
  _allOverviewFilterTextWithCountLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewFilterTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  overviewFilterTextWithCount: Scalars['String']['output'];
  overviewFilterTitle: Scalars['String']['output'];
};


/** Record of type 🏪 Catalog (catalog_page) */
export type CatalogPageRecord_AllOverviewFilterTextWithCountLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏪 Catalog (catalog_page) */
export type CatalogPageRecord_AllOverviewFilterTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏪 Catalog (catalog_page) */
export type CatalogPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏪 Catalog (catalog_page) */
export type CatalogPageRecordOverviewFilterTextWithCountArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏪 Catalog (catalog_page) */
export type CatalogPageRecordOverviewFilterTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type CollectionMetadata = {
  __typename: 'CollectionMetadata';
  count: Scalars['IntType']['output'];
};

export enum ColorBucketType {
  Black = 'black',
  Blue = 'blue',
  Brown = 'brown',
  Cyan = 'cyan',
  Green = 'green',
  Grey = 'grey',
  Orange = 'orange',
  Pink = 'pink',
  Purple = 'purple',
  Red = 'red',
  White = 'white',
  Yellow = 'yellow'
}

export type ColorField = {
  __typename: 'ColorField';
  alpha: Scalars['IntType']['output'];
  blue: Scalars['IntType']['output'];
  cssRgb: Scalars['String']['output'];
  green: Scalars['IntType']['output'];
  hex: Scalars['String']['output'];
  red: Scalars['IntType']['output'];
};

/** Block of type ✍️ Content Grid section (content_grid_section) */
export type ContentGridSectionRecord = RecordInterface & {
  __typename: 'ContentGridSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  content: Array<ContentSectionRecord>;
  id: Scalars['ItemId']['output'];
  maxColumns: Scalars['IntType']['output'];
};


/** Block of type ✍️ Content Grid section (content_grid_section) */
export type ContentGridSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ContentSectionModelBodyBlocksField = ButtonRecord | ImageBlockRecord | SplitItemRecord;

export type ContentSectionModelBodyField = {
  __typename: 'ContentSectionModelBodyField';
  blocks: Array<ContentSectionModelBodyBlocksField>;
  links: Array<Scalars['String']['output']>;
  value: Scalars['JsonField']['output'];
};

/** Block of type ✍️ Content section (content_section) */
export type ContentSectionRecord = RecordInterface & {
  __typename: 'ContentSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  body?: Maybe<ContentSectionModelBodyField>;
  id: Scalars['ItemId']['output'];
  segments: Array<CustomerSegmentRecord>;
};


/** Block of type ✍️ Content section (content_section) */
export type ContentSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by creation datetime */
export type CreatedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Block of type 🤝 Custom Link (custom_link) */
export type CustomLinkRecord = RecordInterface & {
  __typename: 'CustomLinkRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  customLink: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  text: Scalars['String']['output'];
};


/** Block of type 🤝 Custom Link (custom_link) */
export type CustomLinkRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type CustomerCtaSectionModelActionLinksField = ButtonRecord | CustomLinkRecord;

/** Block of type 👉 Customer CTA section (customer_cta_section) */
export type CustomerCtaSectionRecord = RecordInterface & {
  __typename: 'CustomerCtaSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  actionLinks: Array<CustomerCtaSectionModelActionLinksField>;
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
  segments: Array<CustomerSegmentRecord>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Block of type 👉 Customer CTA section (customer_cta_section) */
export type CustomerCtaSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 👉 Customer CTA section (customer_cta_section) */
export type CustomerCtaSectionRecordTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomerSegmentModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<CustomerSegmentModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CustomerSegmentModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  code?: InputMaybe<StringFilter>;
  computedName?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  name?: InputMaybe<StringFilter>;
};

export enum CustomerSegmentModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  ComputedNameAsc = 'computedName_ASC',
  ComputedNameDesc = 'computedName_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

/** Record of type 💁 Customer Segment (customer_segment) */
export type CustomerSegmentRecord = RecordInterface & {
  __typename: 'CustomerSegmentRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  code: Scalars['String']['output'];
  computedName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ItemId']['output'];
  name: Scalars['String']['output'];
};


/** Record of type 💁 Customer Segment (customer_segment) */
export type CustomerSegmentRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter Date fields */
export type DateFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['Date']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars['Date']['input']>;
  /** Filter records with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars['Date']['input']>;
  /** Filter records with a value that's less than the one specified */
  lt?: InputMaybe<Scalars['Date']['input']>;
  /** Filter records with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars['Date']['input']>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars['Date']['input']>;
};

export type EventModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<EventModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<EventModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  endDate?: InputMaybe<DateFilter>;
  id?: InputMaybe<ItemIdFilter>;
  seoMeta?: InputMaybe<SeoFilter>;
  slug?: InputMaybe<SlugFilter>;
  startDate?: InputMaybe<DateFilter>;
  teaserImage?: InputMaybe<FileFilter>;
  teaserLinkText?: InputMaybe<StringFilter>;
  teaserText?: InputMaybe<TextFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum EventModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  EndDateAsc = 'endDate_ASC',
  EndDateDesc = 'endDate_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  StartDateAsc = 'startDate_ASC',
  StartDateDesc = 'startDate_DESC',
  TeaserLinkTextAsc = 'teaserLinkText_ASC',
  TeaserLinkTextDesc = 'teaserLinkText_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type EventModelSectionsField = ContentSectionRecord | FormSectionRecord | HeaderSectionRecord | NewsletterSubscriptionSectionRecord | UpcomingEventsSectionRecord;

export type EventModelSectionsFieldListListNonNullMultiLocaleField = {
  __typename: 'EventModelSectionsFieldListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<EventModelSectionsField>;
};

/** Record of type 📅 Event (event) */
export type EventRecord = RecordInterface & {
  __typename: 'EventRecord';
  _allSectionsLocales?: Maybe<Array<EventModelSectionsFieldListListNonNullMultiLocaleField>>;
  _allSeoMetaLocales?: Maybe<Array<SeoFieldMultiLocaleField>>;
  _allSlugLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTeaserLinkTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTeaserTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ItemId']['output'];
  sections: Array<EventModelSectionsField>;
  seoMeta?: Maybe<SeoField>;
  slug: Scalars['String']['output'];
  startDate: Scalars['Date']['output'];
  teaserImage: ImageFileField;
  teaserLinkText: Scalars['String']['output'];
  teaserText: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Record of type 📅 Event (event) */
export type EventRecord_AllSectionsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📅 Event (event) */
export type EventRecord_AllSeoMetaLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📅 Event (event) */
export type EventRecord_AllSlugLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📅 Event (event) */
export type EventRecord_AllTeaserLinkTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📅 Event (event) */
export type EventRecord_AllTeaserTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 📅 Event (event) */
export type EventRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📅 Event (event) */
export type EventRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📅 Event (event) */
export type EventRecordSectionsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📅 Event (event) */
export type EventRecordSeoMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📅 Event (event) */
export type EventRecordSlugArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📅 Event (event) */
export type EventRecordTeaserLinkTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📅 Event (event) */
export type EventRecordTeaserTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 📅 Event (event) */
export type EventRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FaqModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<FaqModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  answer?: InputMaybe<TextFilter>;
  id?: InputMaybe<ItemIdFilter>;
  question?: InputMaybe<StringFilter>;
};

export enum FaqModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  QuestionAsc = 'question_ASC',
  QuestionDesc = 'question_DESC'
}

/** Record of type ❓ FAQ (faq) */
export type FaqRecord = RecordInterface & {
  __typename: 'FaqRecord';
  _allAnswerLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allQuestionLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  answer: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  question: Scalars['String']['output'];
};


/** Record of type ❓ FAQ (faq) */
export type FaqRecord_AllAnswerLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type ❓ FAQ (faq) */
export type FaqRecord_AllQuestionLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type ❓ FAQ (faq) */
export type FaqRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type ❓ FAQ (faq) */
export type FaqRecordAnswerArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type ❓ FAQ (faq) */
export type FaqRecordQuestionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type ❓ FAQ section (faq_section) */
export type FaqSectionRecord = RecordInterface & {
  __typename: 'FaqSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
  imagePosition: Scalars['BooleanType']['output'];
  items: Array<FaqRecord>;
  title: Scalars['String']['output'];
};


/** Block of type ❓ FAQ section (faq_section) */
export type FaqSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export enum FaviconType {
  AppleTouchIcon = 'appleTouchIcon',
  Icon = 'icon',
  MsApplication = 'msApplication'
}

export type FileField = FileFieldInterface & {
  __typename: 'FileField';
  _createdAt: Scalars['DateTime']['output'];
  /** The DatoCMS URL where you can edit this entity. To use this field, you need to set a X-Base-Editing-Url header in the request */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  alt?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  basename: Scalars['String']['output'];
  blurUpThumb?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  colors: Array<ColorField>;
  copyright?: Maybe<Scalars['String']['output']>;
  customData: Scalars['CustomData']['output'];
  exifInfo: Scalars['CustomData']['output'];
  filename: Scalars['String']['output'];
  focalPoint?: Maybe<FocalPoint>;
  format: Scalars['String']['output'];
  height?: Maybe<Scalars['IntType']['output']>;
  id: Scalars['UploadId']['output'];
  md5: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  responsiveImage?: Maybe<ResponsiveImage>;
  size: Scalars['IntType']['output'];
  smartTags: Array<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  thumbhash?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  video?: Maybe<UploadVideoField>;
  width?: Maybe<Scalars['IntType']['output']>;
};


export type FileFieldAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: Scalars['Float']['input'];
  quality?: Scalars['Int']['input'];
  size?: Scalars['Int']['input'];
};


export type FileFieldCustomDataArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldFocalPointArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldResponsiveImageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  imgixParams?: InputMaybe<ImgixParams>;
  locale?: InputMaybe<SiteLocale>;
  sizes?: InputMaybe<Scalars['String']['input']>;
};


export type FileFieldTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldUrlArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
};

export type FileFieldInterface = {
  _createdAt: Scalars['DateTime']['output'];
  /** The DatoCMS URL where you can edit this entity. To use this field, you need to set a X-Base-Editing-Url header in the request */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  alt?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  basename: Scalars['String']['output'];
  blurUpThumb?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  colors: Array<ColorField>;
  copyright?: Maybe<Scalars['String']['output']>;
  customData: Scalars['CustomData']['output'];
  exifInfo: Scalars['CustomData']['output'];
  filename: Scalars['String']['output'];
  focalPoint?: Maybe<FocalPoint>;
  format: Scalars['String']['output'];
  height?: Maybe<Scalars['IntType']['output']>;
  id: Scalars['UploadId']['output'];
  md5: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  responsiveImage?: Maybe<ResponsiveImage>;
  size: Scalars['IntType']['output'];
  smartTags: Array<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  thumbhash?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  video?: Maybe<UploadVideoField>;
  width?: Maybe<Scalars['IntType']['output']>;
};


export type FileFieldInterfaceAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldInterfaceBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: Scalars['Float']['input'];
  quality?: Scalars['Int']['input'];
  size?: Scalars['Int']['input'];
};


export type FileFieldInterfaceCustomDataArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldInterfaceFocalPointArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldInterfaceResponsiveImageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  imgixParams?: InputMaybe<ImgixParams>;
  locale?: InputMaybe<SiteLocale>;
  sizes?: InputMaybe<Scalars['String']['input']>;
};


export type FileFieldInterfaceTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type FileFieldInterfaceUrlArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
};

/** Specifies how to filter Single-file/image fields */
export type FileFilter = {
  /** Search for records with an exact match. The specified value must be an Upload ID */
  eq?: InputMaybe<Scalars['UploadId']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records that have one of the specified uploads */
  in?: InputMaybe<Array<InputMaybe<Scalars['UploadId']['input']>>>;
  /** Exclude records with an exact match. The specified value must be an Upload ID */
  neq?: InputMaybe<Scalars['UploadId']['input']>;
  /** Filter records that do not have one of the specified uploads */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['UploadId']['input']>>>;
};

export type FooterColumnModelLinksField = CustomLinkRecord | PageLinkRecord;

/** Block of type 🔡 Footer Column (footer_column) */
export type FooterColumnRecord = RecordInterface & {
  __typename: 'FooterColumnRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  links: Array<FooterColumnModelLinksField>;
  title: Scalars['String']['output'];
};


/** Block of type 🔡 Footer Column (footer_column) */
export type FooterColumnRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type FooterColumnRecordListListNonNullMultiLocaleField = {
  __typename: 'FooterColumnRecordListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<FooterColumnRecord>;
};

/** Block of type 🧑‍🤝‍🧑 Form section (form_section) */
export type FormSectionRecord = RecordInterface & {
  __typename: 'FormSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  optImage?: Maybe<ImageFileField>;
  optIntro?: Maybe<Scalars['String']['output']>;
  tallyFormId: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Block of type 🧑‍🤝‍🧑 Form section (form_section) */
export type FormSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 🧑‍🤝‍🧑 Form section (form_section) */
export type FormSectionRecordOptIntroArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GlobalSeoField = {
  __typename: 'GlobalSeoField';
  facebookPageUrl?: Maybe<Scalars['String']['output']>;
  fallbackSeo?: Maybe<SeoField>;
  siteName?: Maybe<Scalars['String']['output']>;
  titleSuffix?: Maybe<Scalars['String']['output']>;
  twitterAccount?: Maybe<Scalars['String']['output']>;
};

/** Block of type 🖼️ Header Image section (header_section) */
export type HeaderSectionRecord = RecordInterface & {
  __typename: 'HeaderSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  item: ImageFileField;
  optIntro?: Maybe<Scalars['String']['output']>;
};


/** Block of type 🖼️ Header Image section (header_section) */
export type HeaderSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 🖼️ Header Image section (header_section) */
export type HeaderSectionRecordOptIntroArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Block of type 💡 Hint section (hint_section) */
export type HintSectionRecord = RecordInterface & {
  __typename: 'HintSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  optTitle?: Maybe<Scalars['String']['output']>;
};


/** Block of type 💡 Hint section (hint_section) */
export type HintSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 💡 Hint section (hint_section) */
export type HintSectionRecordDescriptionArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HintSectionRecordListListNonNullMultiLocaleField = {
  __typename: 'HintSectionRecordListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<HintSectionRecord>;
};

export type HomepageHeroSectionModelPageLinkField = CustomLinkRecord | PageLinkRecord;

/** Block of type 🚀 Homepage Hero section (homepage_hero_section) */
export type HomepageHeroSectionRecord = RecordInterface & {
  __typename: 'HomepageHeroSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  heroImage: ImageFileField;
  heroText: Scalars['String']['output'];
  heroTitle: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  imageLinks: Array<ImageLinkRecord>;
  pageLink: Array<HomepageHeroSectionModelPageLinkField>;
};


/** Block of type 🚀 Homepage Hero section (homepage_hero_section) */
export type HomepageHeroSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 🚀 Homepage Hero section (homepage_hero_section) */
export type HomepageHeroSectionRecordHeroTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Block of type 🕑 Hours (hour) */
export type HourRecord = RecordInterface & {
  __typename: 'HourRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  dayOfWeek: Scalars['String']['output'];
  endHour?: Maybe<Scalars['String']['output']>;
  id: Scalars['ItemId']['output'];
  startHour?: Maybe<Scalars['String']['output']>;
};


/** Block of type 🕑 Hours (hour) */
export type HourRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 🌅 Image block (image_block) */
export type ImageBlockRecord = RecordInterface & {
  __typename: 'ImageBlockRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
};


/** Block of type 🌅 Image block (image_block) */
export type ImageBlockRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ImageFileField = FileFieldInterface & {
  __typename: 'ImageFileField';
  _createdAt: Scalars['DateTime']['output'];
  /** The DatoCMS URL where you can edit this entity. To use this field, you need to set a X-Base-Editing-Url header in the request */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  alt?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  basename: Scalars['String']['output'];
  blurUpThumb?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  colors: Array<ColorField>;
  copyright?: Maybe<Scalars['String']['output']>;
  customData: Scalars['CustomData']['output'];
  exifInfo: Scalars['CustomData']['output'];
  filename: Scalars['String']['output'];
  focalPoint: FocalPoint;
  format: Scalars['String']['output'];
  height: Scalars['IntType']['output'];
  id: Scalars['UploadId']['output'];
  md5: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  responsiveImage: ResponsiveImage;
  size: Scalars['IntType']['output'];
  smartTags: Array<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  thumbhash?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  video?: Maybe<UploadVideoField>;
  width: Scalars['IntType']['output'];
};


export type ImageFileFieldAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type ImageFileFieldBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: Scalars['Float']['input'];
  quality?: Scalars['Int']['input'];
  size?: Scalars['Int']['input'];
};


export type ImageFileFieldCustomDataArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type ImageFileFieldFocalPointArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type ImageFileFieldResponsiveImageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  imgixParams?: InputMaybe<ImgixParams>;
  locale?: InputMaybe<SiteLocale>;
  sizes?: InputMaybe<Scalars['String']['input']>;
};


export type ImageFileFieldTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type ImageFileFieldUrlArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
};

export type ImageFileFieldNonNullMultiLocaleField = {
  __typename: 'ImageFileFieldNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: ImageFileField;
};

/** Block of type 🎫 Image link (image_link) */
export type ImageLinkRecord = RecordInterface & {
  __typename: 'ImageLinkRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
  optCustomLink?: Maybe<Scalars['String']['output']>;
  optPageLink?: Maybe<PageRecord>;
  title: Scalars['String']['output'];
};


/** Block of type 🎫 Image link (image_link) */
export type ImageLinkRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ImgixParams = {
  /**
   * Aspect Ratio
   *
   * Specifies an aspect ratio to maintain when resizing and cropping the image
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/size/aspect-ratio)
   */
  ar?: InputMaybe<Scalars['String']['input']>;
  /**
   * Automatic
   *
   * Applies automatic enhancements to images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/automatic)
   */
  auto?: InputMaybe<Array<ImgixParamsAuto>>;
  /**
   * Background Color
   *
   * Colors the background of padded and partially-transparent images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/fill/background-color)
   */
  bg?: InputMaybe<Scalars['String']['input']>;
  /**
   * Background Removal
   *
   * Removes background from image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/background-removal)
   */
  bgRemove?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Background Removal Fallback
   *
   * Overrides default fallback behavior for bg-remove failures.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/background-removal-fallback)
   */
  bgRemoveFallback?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Background Removal Foreground Type
   *
   * Specifies the image foreground type for background removal.
   *
   * Depends on: `bg-remove=true`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/background-removal-foreground-type)
   */
  bgRemoveFgType?: InputMaybe<Array<ImgixParamsBgRemoveFgType>>;
  /**
   * Background Removal Semi Transparency
   *
   * Enables background removal while retaining semi-transparent areas.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/background-removal-semi-transparency)
   */
  bgRemoveSemiTransparency?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Background Replacement
   *
   * Replaces background from image using a string based prompt.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/background-replacement)
   */
  bgReplace?: InputMaybe<Scalars['String']['input']>;
  /**
   * Background Replace Fallback
   *
   * Overrides default fallback behavior for bg-replace failures.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/background-replace-fallback)
   */
  bgReplaceFallback?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Background Replacement Negative Prompt
   *
   * Provides a negative text suggestion for background replacement.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/background-replacement-negative-prompt)
   */
  bgReplaceNegPrompt?: InputMaybe<Scalars['String']['input']>;
  /**
   * Blend
   *
   * Specifies the location of the blend image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/blending/blend)
   */
  blend?: InputMaybe<Scalars['String']['input']>;
  /**
   * Blend Align
   *
   * Changes the blend alignment relative to the parent image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/blending/blend-align)
   */
  blendAlign?: InputMaybe<Array<ImgixParamsBlendAlign>>;
  /**
   * Blend Alpha
   *
   * Changes the alpha of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/blending/blend-alpha)
   */
  blendAlpha?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Blend Color
   *
   * Specifies a color to use when applying the blend.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/blending/blend-color)
   */
  blendColor?: InputMaybe<Scalars['String']['input']>;
  /**
   * Blend Crop
   *
   * Specifies the type of crop for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/blending/blend-crop)
   */
  blendCrop?: InputMaybe<Array<ImgixParamsBlendCrop>>;
  /**
   * Blend Fit
   *
   * Specifies the fit mode for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/blending/blend-fit)
   */
  blendFit?: InputMaybe<ImgixParamsBlendFit>;
  /**
   * Blend Height
   *
   * Adjusts the height of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/blending/blend-height)
   */
  blendH?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Blend Mode
   *
   * Sets the blend mode for a blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/blending/blend-mode)
   */
  blendMode?: InputMaybe<ImgixParamsBlendMode>;
  /**
   * Blend Padding
   *
   * Applies padding to the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/blending/blend-padding)
   */
  blendPad?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Blend Size
   *
   * Adjusts the size of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/blending/blend-size)
   */
  blendSize?: InputMaybe<ImgixParamsBlendSize>;
  /**
   * Blend Width
   *
   * Adjusts the width of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/blending/blend-width)
   */
  blendW?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Blend X Position
   *
   * Adjusts the x-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/blending/blend-x-position)
   */
  blendX?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Blend Y Position
   *
   * Adjusts the y-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/blending/blend-y-position)
   */
  blendY?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Gaussian Blur
   *
   * Applies a gaussian blur to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/stylize/gaussian-blur)
   */
  blur?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Border Size & Color
   *
   * Applies a border to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/size)
   */
  border?: InputMaybe<Scalars['String']['input']>;
  /**
   * Border Bottom
   *
   * Sets bottom border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/border-and-padding/border-bottom)
   */
  borderBottom?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Border Left
   *
   * Sets left border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/border-and-padding/border-left)
   */
  borderLeft?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Outer Border Radius
   *
   * Sets the outer radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/border-and-padding/outer-border-radius)
   */
  borderRadius?: InputMaybe<Scalars['String']['input']>;
  /**
   * Inner Border Radius
   *
   * Sets the inner radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/border-and-padding/inner-border-radius)
   */
  borderRadiusInner?: InputMaybe<Scalars['String']['input']>;
  /**
   * Border Right
   *
   * Sets right border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/border-and-padding/border-right)
   */
  borderRight?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Border Top
   *
   * Sets top border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/border-and-padding/border-top)
   */
  borderTop?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Brightness
   *
   * Adjusts the brightness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/adjustment/brightness)
   */
  bri?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Client Hints
   *
   * Sets one or more Client-Hints headers
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/format/client-hints)
   */
  ch?: InputMaybe<Array<ImgixParamsCh>>;
  /**
   * Chroma Subsampling
   *
   * Specifies the output chroma subsampling rate.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/format/chroma-subsampling)
   */
  chromasub?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Color Quantization
   *
   * Limits the number of unique colors in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/format/color-quantization)
   */
  colorquant?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Palette Color Count
   *
   * Specifies how many colors to include in a palette-extraction response.
   *
   * Depends on: `palette`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/color-palette/palette-color-count)
   */
  colors?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Contrast
   *
   * Adjusts the contrast of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/adjustment/contrast)
   */
  con?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Mask Corner Radius
   *
   * Specifies the radius value for a rounded corner mask.
   *
   * Depends on: `mask=corners`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/mask-image/mask-corner-radius)
   */
  cornerRadius?: InputMaybe<Scalars['String']['input']>;
  /**
   * Crop Mode
   *
   * Specifies how to crop an image.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/size/crop-mode)
   */
  crop?: InputMaybe<Array<ImgixParamsCrop>>;
  /**
   * Color Space
   *
   * Specifies the color space of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/format/color-space)
   */
  cs?: InputMaybe<ImgixParamsCs>;
  /**
   * Download
   *
   * Forces a URL to use send-file in its response.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/format/download)
   */
  dl?: InputMaybe<Scalars['String']['input']>;
  /**
   * Dots Per Inch
   *
   * Sets the DPI value in the EXIF header.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/format/dots-per-inch)
   */
  dpi?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Device Pixel Ratio
   *
   * Adjusts the device-pixel ratio of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/device-pixel-ratio)
   */
  dpr?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Duotone
   *
   * Applies a duotone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/stylize/duotone)
   */
  duotone?: InputMaybe<Scalars['String']['input']>;
  /**
   * Duotone Alpha
   *
   * Changes the alpha of the duotone effect atop the source image.
   *
   * Depends on: `duotone`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/stylize/duotone-alpha)
   */
  duotoneAlpha?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Exposure
   *
   * Adjusts the exposure of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/adjustment/exposure)
   */
  exp?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Url Expiration Timestamp
   *
   * A Unix timestamp specifying a UTC time. Requests made to this URL after that time will output a 404 status code.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/expiration)
   */
  expires?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Face Blur
   *
   * Specifies the amount of blur to apply to detected faces. Defaults to 0.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/face-detection/face-blur)
   */
  faceBlur?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Face Pixelation
   *
   * Specifies the pixelation amount of the face.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/face-detection/face-pixelation)
   */
  facePixel?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Face Index
   *
   * Selects a face to crop to.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/face-detection/face-index)
   */
  faceindex?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Face Padding
   *
   * Adjusts padding around a selected face.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/face-detection/face-padding)
   */
  facepad?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Json Face Data
   *
   * Specifies that face data should be included in output when combined with `fm=json`.
   *
   * Depends on: `fm=json`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/face-detection/json-face-data)
   */
  faces?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Fill Mode
   *
   * Determines how to fill in additional space created by the fit setting
   *
   * Depends on: `fit`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/fill/fill-mode)
   */
  fill?: InputMaybe<ImgixParamsFill>;
  /**
   * Fill Color
   *
   * Sets the fill color for images with additional space created by the fit setting
   *
   * Depends on: `fill=solid`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/fill/fill-color)
   */
  fillColor?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Generative Fallback
   *
   * Sets the fallback behavior for generative fill.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/fill/fill-generative-fallback)
   */
  fillGenFallback?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Fill Generative Negative Prompt
   *
   * Provides a negative text suggestion to the generative fill parameter. Used to reduce the probability of a subject, detail, or object appearing in generative output.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/fill/fill-generative-negative-prompt)
   */
  fillGenNegPrompt?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Generative Position
   *
   * Sets the position of the Origin Image in relation to the generative fill.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/fill/fill-generative-position)
   */
  fillGenPos?: InputMaybe<Array<ImgixParamsFillGenPos>>;
  /**
   * Fill Generative Prompt
   *
   * Provides a text suggestion to the generative fill parameter.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/fill/fill-generative-prompt)
   */
  fillGenPrompt?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Generative Seed
   *
   * Sets the generative seed value. Used to generate similar outputs from different prompts.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/fill/fill-generative-seed)
   */
  fillGenSeed?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Fill Gradient Color Space
   *
   * Defines the color space as linear, sRGB, Oklab, HSL, or LCH for gradient color interpolation
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/fill/fill-gradient-color-space)
   */
  fillGradientCs?: InputMaybe<ImgixParamsFillGradientCs>;
  /**
   * Fill Gradient Linear
   *
   * Blends a gradient between two colors, {color1} and {color2}, along a straight path
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/fill/fill-gradient-linear)
   */
  fillGradientLinear?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Gradient Linear Direction
   *
   * The fill-gradient-linear-direction specifies the gradient's direction, flowing towards the bottom, top, right, or left
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/fill/fill-gradient-linear-direction)
   */
  fillGradientLinearDirection?: InputMaybe<Array<ImgixParamsFillGradientLinearDirection>>;
  /**
   * Fill Gradient Radial
   *
   * The fill-gradient-radial parameter creates a circular gradient transitioning from a central color (Color1) to an outer color (Color2)
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/fill/fill-gradient-radial)
   */
  fillGradientRadial?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Gradient Radial Radius
   *
   * Parameter defines the radial gradient's radius as pixels or a percentage (0.0-1.0) of the image's smallest dimension
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/fill/fill-gradient-radial-radius)
   */
  fillGradientRadialRadius?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Gradient Radial X
   *
   * Specifies the location of the radial gradient's center along the x-axis, using either a pixel value or a floating point percentage (ranging from 0.0 to 1.0) of the image's width
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/fill/fill-gradient-radial-x)
   */
  fillGradientRadialX?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Fill Gradient Radial Y
   *
   * Parameter sets the radial gradient's center on the y-axis, using pixels or a 0.0 to 1.0 percentage of the image's height
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/fill/fill-gradient-radial-y)
   */
  fillGradientRadialY?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Fill Gradient Type
   *
   * Specifies if a gradient is radial (circular) or linear (straight)
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/fill/fill-gradient-type)
   */
  fillGradientType?: InputMaybe<ImgixParamsFillGradientType>;
  /**
   * Resize Fit Mode
   *
   * Specifies how to map the source image to the output image dimensions.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/size/resize-fit-mode)
   */
  fit?: InputMaybe<ImgixParamsFit>;
  /**
   * Flip Axis
   *
   * Flips an image on a specified axis.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/rotation/flip-axis)
   */
  flip?: InputMaybe<ImgixParamsFlip>;
  /**
   * Output Format
   *
   * Changes the format of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/format/output-format)
   */
  fm?: InputMaybe<ImgixParamsFm>;
  /**
   * Focal Point Debug
   *
   * Displays crosshairs identifying the location of the set focal point
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/focal-point-crop/focal-point-debug)
   */
  fpDebug?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Focal Point X Position
   *
   * Sets the relative horizontal value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/focal-point-crop/focal-point-x-position)
   */
  fpX?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Focal Point Y Position
   *
   * Sets the relative vertical value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/focal-point-crop/focal-point-y-position)
   */
  fpY?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Focal Point Zoom
   *
   * Sets the relative zoom value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/focal-point-crop/focal-point-zoom)
   */
  fpZ?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Frames Per Second
   *
   * Specifies the framerate of the generated image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/animation/frames-per-second)
   */
  fps?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Frame Selection
   *
   * Specifies the frame of an animated image to use.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/animation/frame-selection)
   */
  frame?: InputMaybe<Scalars['String']['input']>;
  /**
   * Gamma
   *
   * Adjusts the gamma of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/adjustment/gamma)
   */
  gam?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Animated Gif Quality
   *
   * Specifies the quality of the animated gif. The higher the value, the better more compression is applied.
   *
   * Depends on: `fm=gif`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/animation/animated-gif-quality)
   */
  gifQ?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Grid Colors
   *
   * Sets grid colors for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/fill/grid-colors)
   */
  gridColors?: InputMaybe<Scalars['String']['input']>;
  /**
   * Grid Size
   *
   * Sets grid size for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/fill/grid-size)
   */
  gridSize?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Image Height
   *
   * Adjusts the height of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/size/image-height)
   */
  h?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Highlight
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/adjustment/highlight)
   */
  high?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Halftone
   *
   * Applies a half-tone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/stylize/halftone)
   */
  htn?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Hue Shift
   *
   * Adjusts the hue of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/adjustment/hue-shift)
   */
  hue?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Frame Interval
   *
   * Displays every Nth frame starting with the first frame.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/animation/frame-interval)
   */
  interval?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Invert
   *
   * Inverts the colors on the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/adjustment/invert)
   */
  invert?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Iptc Passthrough
   *
   * Determine if IPTC data should be passed for JPEG images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/format/iptc-passthrough)
   */
  iptc?: InputMaybe<ImgixParamsIptc>;
  /**
   * Jpg Progressive
   *
   * Specifies whether or not a jpg/jpeg uses progressive (true) or baseline (false)
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/format/jpg-progressive)
   */
  jpgProgressive?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Animation Loop Count
   *
   * Specifies the number of times an animated image should repeat. A value of 0 means infinite looping.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/animation)
   */
  loop?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Lossless Compression
   *
   * Specifies that the output image should be a lossless variant.
   *
   * Depends on: `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/format/lossless-compression)
   */
  lossless?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * License Plate Blur
   *
   * Specifies the amount of blur to apply to detected license plates. Defaults to 0.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/license-plate-detection/license-plate-blur)
   */
  lpBlur?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Watermark Image Url
   *
   * Specifies the location of the watermark image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/watermark/watermark-image-url)
   */
  mark?: InputMaybe<Scalars['String']['input']>;
  /**
   * Watermark Alignment Mode
   *
   * Changes the watermark alignment relative to the parent image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/watermark/watermark-alignment-mode)
   */
  markAlign?: InputMaybe<Array<ImgixParamsMarkAlign>>;
  /**
   * Watermark Alpha
   *
   * Changes the alpha of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/watermark/watermark-alpha)
   */
  markAlpha?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Watermark Base Url
   *
   * Changes base URL of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/watermark/watermark-base-url)
   */
  markBase?: InputMaybe<Scalars['String']['input']>;
  /**
   * Watermark Fit Mode
   *
   * Specifies the fit mode for watermark images.
   *
   * Depends on: `mark`, `markw`, `markh`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/watermark/watermark-fit-mode)
   */
  markFit?: InputMaybe<ImgixParamsMarkFit>;
  /**
   * Watermark Height
   *
   * Adjusts the height of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/watermark/watermark-height)
   */
  markH?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Watermark If Minimum Height
   *
   * Displays the watermark if rendered base image pixel height is equal to or larger than the supplied value
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/watermark/watermark-if-minimum-height)
   */
  markIfMinHeight?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Watermark If Minimum Width
   *
   * Displays the watermark if rendered base image pixel width is equal to or larger than the supplied value
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/watermark/watermark-if-minimum-width)
   */
  markIfMinWidth?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Watermark Padding
   *
   * Applies padding to the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/watermark/watermark-padding)
   */
  markPad?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Watermark Rotation
   *
   * Rotates a watermark or tiled watermarks by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/watermark/watermark-rotation)
   */
  markRot?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Watermark Scale
   *
   * Adjusts the scale of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/watermark/watermark-scale)
   */
  markScale?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Watermark Tile
   *
   * Adds tiled watermark.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/watermark/watermark-tile)
   */
  markTile?: InputMaybe<ImgixParamsMarkTile>;
  /**
   * Watermark Width
   *
   * Adjusts the width of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/watermark/watermark-width)
   */
  markW?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Watermark X Position
   *
   * Adjusts the x-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/watermark/watermark-x-position)
   */
  markX?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Watermark Y Position
   *
   * Adjusts the y-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/watermark/watermark-y-position)
   */
  markY?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Mask Type
   *
   * Defines the type of mask and specifies the URL if that type is selected.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/mask-image/mask-type)
   */
  mask?: InputMaybe<Scalars['String']['input']>;
  /**
   * Mask Background Color
   *
   * Colors the background of the transparent mask area of images
   *
   * Depends on: `mask`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/mask-image/mask-background-color)
   */
  maskBg?: InputMaybe<Scalars['String']['input']>;
  /**
   * Maximum Height
   *
   * Specifies the maximum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/size/maximum-height)
   */
  maxH?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Maximum Width
   *
   * Specifies the maximum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/size/maximum-width)
   */
  maxW?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Minimum Height
   *
   * Specifies the minimum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/size/minimum-height)
   */
  minH?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Minimum Width
   *
   * Specifies the minimum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/size/minimum-width)
   */
  minW?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Monochrome
   *
   * Applies a monochrome effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/stylize/monochrome)
   */
  monochrome?: InputMaybe<Scalars['String']['input']>;
  /**
   * Noise Reduction Bound
   *
   * Reduces the noise in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/noise-reduction/noise-reduction-bound)
   */
  nr?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Noise Reduction Sharpen
   *
   * Provides a threshold by which to sharpen an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/noise-reduction/noise-reduction-sharpen)
   */
  nrs?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Object Removal Negative Prompt
   *
   * Provides a negative text suggestion to object-removal-prompt. Used to reduce the probability of a subject, detail, or object appearing in generative output.
   *
   * Depends on: `object-removal-rect`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/object-manipulation/object-removal-negative-prompt)
   */
  objectRemovalNegativePrompt?: InputMaybe<Scalars['String']['input']>;
  /**
   * Object Removal Prompt
   *
   * Suggest auto generative fill for the object-removal-rect parameter
   *
   * Depends on: `object-removal-rect`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/object-manipulation/object-removal-prompt)
   */
  objectRemovalPrompt?: InputMaybe<Scalars['String']['input']>;
  /**
   * Object Removal
   *
   * Using a specified rectangle, an object is removed from the image
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/object-manipulation/object-removal)
   */
  objectRemovalRect?: InputMaybe<Scalars['String']['input']>;
  /**
   * Object Removal Seed
   *
   * Sets the generative seed value for object-removal. Used to generate new outputs from the same prompt
   *
   * Depends on: `object-removal-rect`, `object-removal-prompt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/object-manipulation/object-removal-seed)
   */
  objectRemovalSeed?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Orientation
   *
   * Changes the image orientation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/rotation/orientation)
   */
  orient?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Padding
   *
   * Pads an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/border-and-padding/padding)
   */
  pad?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Padding Bottom
   *
   * Sets bottom padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/border-and-padding/padding-bottom)
   */
  padBottom?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Padding Left
   *
   * Sets left padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/border-and-padding/padding-left)
   */
  padLeft?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Padding Right
   *
   * Sets right padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/border-and-padding/padding-right)
   */
  padRight?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Padding Top
   *
   * Sets top padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/border-and-padding/padding-top)
   */
  padTop?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Pdf Page Number
   *
   * Selects a page from a PDF for display.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/pdf/pdf-page-number)
   */
  page?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Color Palette Extraction
   *
   * Specifies an output format for palette-extraction.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/color-palette/color-palette-extraction)
   */
  palette?: InputMaybe<ImgixParamsPalette>;
  /**
   * Pdf Annotation
   *
   * Enables or disables PDF annotation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/pdf/pdf-annotation)
   */
  pdfAnnotation?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Css Prefix
   *
   * Specifies a CSS prefix for all classes in palette-extraction.
   *
   * Depends on: `palette=css`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/color-palette/css-prefix)
   */
  prefix?: InputMaybe<Scalars['String']['input']>;
  /**
   * Pixellate
   *
   * Applies a pixelation effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/stylize/pixellate)
   */
  px?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Output Quality
   *
   * Adjusts the quality of an output image.
   *
   * Depends on: `fm=avif`, `fm=jpg`, `fm=pjpg`, `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/format/output-quality)
   */
  q?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Rasterize Bypass
   *
   * Bypasses all rendering parameters (including default parameters) and serves the original image. Works for svg+xml,x-eps,pdf, and vnd.adobe.illustrator.
   */
  rasterizeBypass?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Source Rectangle Region
   *
   * Crops an image to a specified rectangle.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/size/source-rectangle-region)
   */
  rect?: InputMaybe<Scalars['String']['input']>;
  /**
   * Reverse
   *
   * Reverses the frame order on the source animation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/animation/reverse)
   */
  reverse?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Rotation
   *
   * Rotates an image by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/rotation/rotation)
   */
  rot?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Rotation Type
   *
   * Changes the rotation type.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/rotation/rotation-type)
   */
  rotType?: InputMaybe<ImgixParamsRotType>;
  /**
   * Saturation
   *
   * Adjusts the saturation of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/adjustment/saturation)
   */
  sat?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Sepia Tone
   *
   * Applies a sepia effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/stylize/sepia-tone)
   */
  sepia?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Shadow
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/adjustment/shadow)
   */
  shad?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Sharpen
   *
   * Adjusts the sharpness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/adjustment/sharpen)
   */
  sharp?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Frame Skip
   *
   * Skips every Nth frame starting with the first frame.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/animation/frame-skip)
   */
  skip?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Bypasses any [DatoCMS Automatic Image Optimization](https://www.datocms.com/docs/cdn-settings/advanced-asset-settings) that might be set up for the project.
   *
   * Exercise caution when using this parameter, as it could significantly increase your bandwidth costs.
   */
  skipDefaultOptimizations?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Sanitize Svg
   *
   * Specifies whether to sanitize an SVG.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/format/sanitize-svg)
   */
  svgSanitize?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Transparency
   *
   * Adds checkerboard behind images which support transparency.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/fill/transparency)
   */
  transparency?: InputMaybe<ImgixParamsTransparency>;
  /**
   * Trim Image
   *
   * Trims the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/trim/trim-image)
   */
  trim?: InputMaybe<ImgixParamsTrim>;
  /**
   * Trim Alpha
   *
   * Specifies a trim alpha on a trim operation.
   *
   * Depends on: `trim=alpha`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/trim/trim-alpha)
   */
  trimAlpha?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Trim Color
   *
   * Specifies a trim color on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/trim/trim-color)
   */
  trimColor?: InputMaybe<Scalars['String']['input']>;
  /**
   * Trim Mean Difference
   *
   * Specifies the mean difference on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/trim/trim-mean-difference)
   */
  trimMd?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Trim Padding
   *
   * Pads the area of the source image before trimming.
   *
   * Depends on: `trim`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/trim/trim-padding)
   */
  trimPad?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Trim Standard Deviation
   *
   * Specifies the standard deviation on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/trim/trim-standard-deviation)
   */
  trimSd?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Trim Tolerance
   *
   * Specifies the tolerance on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/trim/trim-tolerance)
   */
  trimTol?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Text String
   *
   * Sets the text string to render.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/text/text-string)
   */
  txt?: InputMaybe<Scalars['String']['input']>;
  /**
   * Text Align
   *
   * Sets the vertical and horizontal alignment of rendered text relative to the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/text/text-align)
   */
  txtAlign?: InputMaybe<Array<ImgixParamsTxtAlign>>;
  /**
   * Text Clipping Mode
   *
   * Sets the clipping properties of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/text/text-clipping-mode)
   */
  txtClip?: InputMaybe<Array<ImgixParamsTxtClip>>;
  /**
   * Text Color
   *
   * Specifies the color of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/text/text-color)
   */
  txtColor?: InputMaybe<Scalars['String']['input']>;
  /**
   * Text Fit Mode
   *
   * Specifies the fit approach for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/text/text-fit-mode)
   */
  txtFit?: InputMaybe<ImgixParamsTxtFit>;
  /**
   * Text Font
   *
   * Selects a font for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/text/text-font)
   */
  txtFont?: InputMaybe<Scalars['String']['input']>;
  /**
   * Text Leading
   *
   * Sets the leading (line spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/typesetting-endpoint/text-leading)
   */
  txtLead?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Outline
   *
   * Outlines the rendered text with a specified color.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/text/text-outline)
   */
  txtLine?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Outline Color
   *
   * Specifies a text outline color.
   *
   * Depends on: `txt`, `txtline`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/text/text-outline-color)
   */
  txtLineColor?: InputMaybe<Scalars['String']['input']>;
  /**
   * Text Padding
   *
   * Specifies the padding (in device-independent pixels) between a textbox and the edges of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/text/text-padding)
   */
  txtPad?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Shadow
   *
   * Applies a shadow to rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/text/text-shadow)
   */
  txtShad?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Text Font Size
   *
   * Sets the font size of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/text/text-font-size)
   */
  txtSize?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Tracking
   *
   * Sets the tracking (letter spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/typesetting-endpoint/text-tracking)
   */
  txtTrack?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Width
   *
   * Sets the width of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/text/text-width)
   */
  txtWidth?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text X Position
   *
   * Sets the horizontal (x) position of the text in pixels relative to the left edge of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/text/text-x-position)
   */
  txtX?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Y Position
   *
   * Sets the vertical (y) position of the text in pixels relative to the top edge of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/text/text-y-position)
   */
  txtY?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Super Resolution
   *
   * Uses generative AI fill to upscale low resolution images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/super-resolution)
   */
  upscale?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Super Resolution Fallback
   *
   * Overrides default fallback behavior for super resolution failures
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/super-resolution)
   */
  upscaleFallback?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Unsharp Mask
   *
   * Sharpens the source image using an unsharp mask.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/adjustment/unsharp-mask)
   */
  usm?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Unsharp Mask Radius
   *
   * Specifies the radius for an unsharp mask operation.
   *
   * Depends on: `usm`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/adjustment/unsharp-mask-radius)
   */
  usmrad?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Vibrance
   *
   * Adjusts the vibrance of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/adjustment/vibrance)
   */
  vib?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Image Width
   *
   * Adjusts the width of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/size/image-width)
   */
  w?: InputMaybe<Scalars['FloatType']['input']>;
};

export enum ImgixParamsAuto {
  Compress = 'compress',
  Enhance = 'enhance',
  Format = 'format',
  Redeye = 'redeye'
}

export enum ImgixParamsBgRemoveFgType {
  Auto = 'auto',
  Car = 'car'
}

export enum ImgixParamsBlendAlign {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsBlendCrop {
  Bottom = 'bottom',
  Faces = 'faces',
  Left = 'left',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsBlendFit {
  Clamp = 'clamp',
  Clip = 'clip',
  Crop = 'crop',
  Max = 'max',
  Scale = 'scale'
}

export enum ImgixParamsBlendMode {
  Burn = 'burn',
  Color = 'color',
  Darken = 'darken',
  Difference = 'difference',
  Dodge = 'dodge',
  Exclusion = 'exclusion',
  Hardlight = 'hardlight',
  Hue = 'hue',
  Lighten = 'lighten',
  Luminosity = 'luminosity',
  Multiply = 'multiply',
  Normal = 'normal',
  Overlay = 'overlay',
  Saturation = 'saturation',
  Screen = 'screen',
  Softlight = 'softlight'
}

export enum ImgixParamsBlendSize {
  Inherit = 'inherit'
}

export enum ImgixParamsCh {
  Dpr = 'dpr',
  SaveData = 'saveData',
  Width = 'width'
}

export enum ImgixParamsCrop {
  Bottom = 'bottom',
  Edges = 'edges',
  Entropy = 'entropy',
  Faces = 'faces',
  Focalpoint = 'focalpoint',
  Left = 'left',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsCs {
  Adobergb1998 = 'adobergb1998',
  Origin = 'origin',
  Srgb = 'srgb',
  Strip = 'strip',
  Tinysrgb = 'tinysrgb'
}

export enum ImgixParamsFill {
  Blur = 'blur',
  Gen = 'gen',
  Generative = 'generative',
  Gradient = 'gradient',
  Solid = 'solid'
}

export enum ImgixParamsFillGenPos {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsFillGradientCs {
  Hsl = 'hsl',
  Lch = 'lch',
  Linear = 'linear',
  Oklab = 'oklab',
  Srgb = 'srgb'
}

export enum ImgixParamsFillGradientLinearDirection {
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsFillGradientType {
  Linear = 'linear',
  Radial = 'radial'
}

export enum ImgixParamsFit {
  Clamp = 'clamp',
  Clip = 'clip',
  Crop = 'crop',
  Facearea = 'facearea',
  Fill = 'fill',
  Fillmax = 'fillmax',
  Max = 'max',
  Min = 'min',
  Scale = 'scale'
}

export enum ImgixParamsFlip {
  H = 'h',
  Hv = 'hv',
  V = 'v'
}

export enum ImgixParamsFm {
  Avif = 'avif',
  Blurhash = 'blurhash',
  Gif = 'gif',
  Jp2 = 'jp2',
  Jpg = 'jpg',
  Json = 'json',
  Jxr = 'jxr',
  Mp4 = 'mp4',
  Pjpg = 'pjpg',
  Png = 'png',
  Png8 = 'png8',
  Png32 = 'png32',
  Webm = 'webm',
  Webp = 'webp'
}

export enum ImgixParamsIptc {
  Allow = 'allow',
  Block = 'block'
}

export enum ImgixParamsMarkAlign {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsMarkFit {
  Clip = 'clip',
  Crop = 'crop',
  Fill = 'fill',
  Max = 'max',
  Scale = 'scale'
}

export enum ImgixParamsMarkTile {
  Grid = 'grid'
}

export enum ImgixParamsPalette {
  Css = 'css',
  Json = 'json'
}

export enum ImgixParamsRotType {
  Pivot = 'pivot',
  Straighten = 'straighten'
}

export enum ImgixParamsTransparency {
  Grid = 'grid'
}

export enum ImgixParamsTrim {
  Alpha = 'alpha',
  Auto = 'auto',
  Color = 'color'
}

export enum ImgixParamsTxtAlign {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsTxtClip {
  Ellipsis = 'ellipsis',
  End = 'end',
  Middle = 'middle',
  Start = 'start'
}

export enum ImgixParamsTxtFit {
  Max = 'max'
}

/** Specifies how to filter by usage */
export type InUseFilter = {
  /** Search uploads that are currently used by some record or not */
  eq?: InputMaybe<Scalars['BooleanType']['input']>;
};

/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecord = RecordInterface & {
  __typename: 'InvoicesPageRecord';
  _allOverviewActionDownloadLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewActionSearchLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewFilterTextWithCountLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewFilterTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewHeaderInvoiceDateLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewHeaderInvoiceNumberLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewHeaderInvoiceStatusLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewHeaderTotalLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewLabelFromLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewLabelInvoiceNumberLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewLabelStatusLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewLabelToLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewMessageNoItemsLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  overviewActionDownload: Scalars['String']['output'];
  overviewActionSearch: Scalars['String']['output'];
  overviewFilterTextWithCount: Scalars['String']['output'];
  overviewFilterTitle: Scalars['String']['output'];
  overviewHeaderInvoiceDate: Scalars['String']['output'];
  overviewHeaderInvoiceNumber: Scalars['String']['output'];
  overviewHeaderInvoiceStatus: Scalars['String']['output'];
  overviewHeaderTotal: Scalars['String']['output'];
  overviewLabelFrom: Scalars['String']['output'];
  overviewLabelInvoiceNumber: Scalars['String']['output'];
  overviewLabelStatus: Scalars['String']['output'];
  overviewLabelTo: Scalars['String']['output'];
  overviewMessageNoItems: Scalars['String']['output'];
  overviewTitle: Scalars['String']['output'];
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecord_AllOverviewActionDownloadLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecord_AllOverviewActionSearchLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecord_AllOverviewFilterTextWithCountLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecord_AllOverviewFilterTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecord_AllOverviewHeaderInvoiceDateLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecord_AllOverviewHeaderInvoiceNumberLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecord_AllOverviewHeaderInvoiceStatusLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecord_AllOverviewHeaderTotalLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecord_AllOverviewLabelFromLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecord_AllOverviewLabelInvoiceNumberLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecord_AllOverviewLabelStatusLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecord_AllOverviewLabelToLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecord_AllOverviewMessageNoItemsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecord_AllOverviewTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecordOverviewActionDownloadArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecordOverviewActionSearchArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecordOverviewFilterTextWithCountArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecordOverviewFilterTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecordOverviewHeaderInvoiceDateArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecordOverviewHeaderInvoiceNumberArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecordOverviewHeaderInvoiceStatusArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecordOverviewHeaderTotalArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecordOverviewLabelFromArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecordOverviewLabelInvoiceNumberArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecordOverviewLabelStatusArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecordOverviewLabelToArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecordOverviewMessageNoItemsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧾 Invoices (invoices_page) */
export type InvoicesPageRecordOverviewTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by ID */
export type ItemIdFilter = {
  /** Search the record with the specified ID */
  eq?: InputMaybe<Scalars['ItemId']['input']>;
  /** Search records with the specified IDs */
  in?: InputMaybe<Array<InputMaybe<Scalars['ItemId']['input']>>>;
  /** Exclude the record with the specified ID */
  neq?: InputMaybe<Scalars['ItemId']['input']>;
  /** Search records that do not have the specified IDs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ItemId']['input']>>>;
};

export enum ItemStatus {
  Draft = 'draft',
  Published = 'published',
  Updated = 'updated'
}

export type LatLonField = {
  __typename: 'LatLonField';
  latitude: Scalars['FloatType']['output'];
  longitude: Scalars['FloatType']['output'];
};

/** Specifies how to filter Geolocation fields */
export type LatLonFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records within the specified radius in meters */
  near?: InputMaybe<LatLonNearFilter>;
};

export type LatLonNearFilter = {
  latitude: Scalars['FloatType']['input'];
  longitude: Scalars['FloatType']['input'];
  radius: Scalars['FloatType']['input'];
};

/** Block of type 💖 Latest Trends & Inspiration section (latest_trends_section) */
export type LatestTrendsSectionRecord = RecordInterface & {
  __typename: 'LatestTrendsSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  itemCount: Scalars['IntType']['output'];
  linkPage: PageRecord;
  linkText: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Block of type 💖 Latest Trends & Inspiration section (latest_trends_section) */
export type LatestTrendsSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type 🧩 Layout (layout) */
export type LayoutRecord = RecordInterface & {
  __typename: 'LayoutRecord';
  _allAccountLinkTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allChangeCustomerLinkTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDashboardButtonTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allFooterColumnsLocales?: Maybe<Array<FooterColumnRecordListListNonNullMultiLocaleField>>;
  _allFooterLinksLocales?: Maybe<Array<LegalPageRecordListListNonNullMultiLocaleField>>;
  _allFooterSubtitleLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allHeaderButtonLinkLocales?: Maybe<Array<PageRecordNonNullMultiLocaleField>>;
  _allHeaderButtonTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allInvoicesLinkTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allLoginButtonTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allMainNavigationLocales?: Maybe<Array<PageRecordListListNonNullMultiLocaleField>>;
  _allNewsletterSubscriptionFormLocales?: Maybe<Array<NewsletterSubscriptionRecordNonNullMultiLocaleField>>;
  _allOrdersLinkTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allParentPageEventsLocales?: Maybe<Array<PageRecordMultiLocaleField>>;
  _allParentPageNewsLocales?: Maybe<Array<PageRecordMultiLocaleField>>;
  _allParentPageStoresLocales?: Maybe<Array<PageRecordMultiLocaleField>>;
  _allParentPageTrendsLocales?: Maybe<Array<PageRecordMultiLocaleField>>;
  _allRegistrationButtonTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTemplatesLinkTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTopNavigationLocales?: Maybe<Array<PageRecordListListNonNullMultiLocaleField>>;
  _allUspsLocales?: Maybe<Array<UspRecordListListNonNullMultiLocaleField>>;
  _allWebshopButtonTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  accountLinkText: Scalars['String']['output'];
  changeCustomerLinkText: Scalars['String']['output'];
  dashboardButtonText: Scalars['String']['output'];
  footerColumns: Array<FooterColumnRecord>;
  footerLinks: Array<LegalPageRecord>;
  footerSubtitle?: Maybe<Scalars['String']['output']>;
  headerButtonLink: PageRecord;
  headerButtonText: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  invoicesLinkText: Scalars['String']['output'];
  loginButtonText: Scalars['String']['output'];
  mainNavigation: Array<PageRecord>;
  newsletterSubscriptionForm: NewsletterSubscriptionRecord;
  ordersLinkText: Scalars['String']['output'];
  parentPageEvents?: Maybe<PageRecord>;
  parentPageNews?: Maybe<PageRecord>;
  parentPageStores?: Maybe<PageRecord>;
  parentPageTrends?: Maybe<PageRecord>;
  registrationButtonText: Scalars['String']['output'];
  socialMediaLinks: Array<SocialMediaIconRecord>;
  templatesLinkText: Scalars['String']['output'];
  topNavigation: Array<PageRecord>;
  usps: Array<UspRecord>;
  webshopButtonText: Scalars['String']['output'];
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllAccountLinkTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllChangeCustomerLinkTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllDashboardButtonTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllFooterColumnsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllFooterLinksLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllFooterSubtitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllHeaderButtonLinkLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllHeaderButtonTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllInvoicesLinkTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllLoginButtonTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllMainNavigationLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllNewsletterSubscriptionFormLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllOrdersLinkTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllParentPageEventsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllParentPageNewsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllParentPageStoresLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllParentPageTrendsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllRegistrationButtonTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllTemplatesLinkTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllTopNavigationLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllUspsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_AllWebshopButtonTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordAccountLinkTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordChangeCustomerLinkTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordDashboardButtonTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordFooterColumnsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordFooterLinksArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordFooterSubtitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordHeaderButtonLinkArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordHeaderButtonTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordInvoicesLinkTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordLoginButtonTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordMainNavigationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordNewsletterSubscriptionFormArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordOrdersLinkTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordParentPageEventsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordParentPageNewsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordParentPageStoresArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordParentPageTrendsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordRegistrationButtonTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordTemplatesLinkTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordTopNavigationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordUspsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🧩 Layout (layout) */
export type LayoutRecordWebshopButtonTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type LegalPageModelContentField = {
  __typename: 'LegalPageModelContentField';
  blocks: Array<Scalars['String']['output']>;
  links: Array<Scalars['String']['output']>;
  value: Scalars['JsonField']['output'];
};

export type LegalPageModelContentFieldNonNullMultiLocaleField = {
  __typename: 'LegalPageModelContentFieldNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: LegalPageModelContentField;
};

export type LegalPageModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<LegalPageModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<LegalPageModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  content?: InputMaybe<StructuredTextFilter>;
  id?: InputMaybe<ItemIdFilter>;
  slug?: InputMaybe<SlugFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum LegalPageModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Record of type 🏛️ Legal Page (legal_page) */
export type LegalPageRecord = RecordInterface & {
  __typename: 'LegalPageRecord';
  _allContentLocales?: Maybe<Array<LegalPageModelContentFieldNonNullMultiLocaleField>>;
  _allSlugLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  content: LegalPageModelContentField;
  id: Scalars['ItemId']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Record of type 🏛️ Legal Page (legal_page) */
export type LegalPageRecord_AllContentLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏛️ Legal Page (legal_page) */
export type LegalPageRecord_AllSlugLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏛️ Legal Page (legal_page) */
export type LegalPageRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏛️ Legal Page (legal_page) */
export type LegalPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏛️ Legal Page (legal_page) */
export type LegalPageRecordContentArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏛️ Legal Page (legal_page) */
export type LegalPageRecordSlugArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏛️ Legal Page (legal_page) */
export type LegalPageRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type LegalPageRecordListListNonNullMultiLocaleField = {
  __typename: 'LegalPageRecordListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<LegalPageRecord>;
};

/** Specifies how to filter Single-link fields */
export type LinkFilter = {
  /** Search for records with an exact match. The specified value must be a Record ID */
  eq?: InputMaybe<Scalars['ItemId']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records linked to one of the specified records */
  in?: InputMaybe<Array<InputMaybe<Scalars['ItemId']['input']>>>;
  /** Exclude records with an exact match. The specified value must be a Record ID */
  neq?: InputMaybe<Scalars['ItemId']['input']>;
  /** Filter records not linked to one of the specified records */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ItemId']['input']>>>;
};

/** Specifies how to filter Multiple-links fields */
export type LinksFilter = {
  /** Filter records linked to all of the specified records. The specified values must be Record IDs */
  allIn?: InputMaybe<Array<InputMaybe<Scalars['ItemId']['input']>>>;
  /** Filter records linked to at least one of the specified records. The specified values must be Record IDs */
  anyIn?: InputMaybe<Array<InputMaybe<Scalars['ItemId']['input']>>>;
  /** Search for records with an exact match. The specified values must be Record IDs */
  eq?: InputMaybe<Array<InputMaybe<Scalars['ItemId']['input']>>>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records not linked to any of the specified records. The specified values must be Record IDs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ItemId']['input']>>>;
};

/** Specifies how to filter by locale */
export type LocalesFilter = {
  /** Filter records that are localized in all the specified locales */
  allIn?: InputMaybe<Array<SiteLocale>>;
  /** Filter records that are localized in at least one of the specified locales */
  anyIn?: InputMaybe<Array<SiteLocale>>;
  /** Filter records that are not localized in any of the specified locales */
  notIn?: InputMaybe<Array<SiteLocale>>;
};

export type MagazineModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<MagazineModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<MagazineModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  image?: InputMaybe<FileFilter>;
  linkText?: InputMaybe<StringFilter>;
  optExtraTitle?: InputMaybe<StringFilter>;
  optText?: InputMaybe<TextFilter>;
  title?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export enum MagazineModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LinkTextAsc = 'linkText_ASC',
  LinkTextDesc = 'linkText_DESC',
  OptExtraTitleAsc = 'optExtraTitle_ASC',
  OptExtraTitleDesc = 'optExtraTitle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

/** Record of type 🌿 Magazine (magazine) */
export type MagazineRecord = RecordInterface & {
  __typename: 'MagazineRecord';
  _allImageLocales?: Maybe<Array<ImageFileFieldNonNullMultiLocaleField>>;
  _allLinkTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOptExtraTitleLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allOptTextLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allUrlLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
  linkText: Scalars['String']['output'];
  optExtraTitle?: Maybe<Scalars['String']['output']>;
  optText?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};


/** Record of type 🌿 Magazine (magazine) */
export type MagazineRecord_AllImageLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🌿 Magazine (magazine) */
export type MagazineRecord_AllLinkTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🌿 Magazine (magazine) */
export type MagazineRecord_AllOptExtraTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🌿 Magazine (magazine) */
export type MagazineRecord_AllOptTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🌿 Magazine (magazine) */
export type MagazineRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🌿 Magazine (magazine) */
export type MagazineRecord_AllUrlLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🌿 Magazine (magazine) */
export type MagazineRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🌿 Magazine (magazine) */
export type MagazineRecordImageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🌿 Magazine (magazine) */
export type MagazineRecordLinkTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🌿 Magazine (magazine) */
export type MagazineRecordOptExtraTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🌿 Magazine (magazine) */
export type MagazineRecordOptTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🌿 Magazine (magazine) */
export type MagazineRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🌿 Magazine (magazine) */
export type MagazineRecordUrlArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type 🌿 Magazine section (magazine_section) */
export type MagazineSectionRecord = RecordInterface & {
  __typename: 'MagazineSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  item: MagazineRecord;
};


/** Block of type 🌿 Magazine section (magazine_section) */
export type MagazineSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export enum MuxThumbnailFormatType {
  Gif = 'gif',
  Jpg = 'jpg',
  Png = 'png'
}

export type NewsModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<NewsModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NewsModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  seoMeta?: InputMaybe<SeoFilter>;
  slug?: InputMaybe<SlugFilter>;
  teaserImage?: InputMaybe<FileFilter>;
  teaserText?: InputMaybe<TextFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum NewsModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type NewsModelSectionsField = ContentSectionRecord | HeaderSectionRecord | NewsletterSubscriptionSectionRecord | SplitSectionRecord | UpcomingEventsSectionRecord;

export type NewsModelSectionsFieldListListNonNullMultiLocaleField = {
  __typename: 'NewsModelSectionsFieldListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<NewsModelSectionsField>;
};

/** Record of type 🗞️ News (news) */
export type NewsRecord = RecordInterface & {
  __typename: 'NewsRecord';
  _allSectionsLocales?: Maybe<Array<NewsModelSectionsFieldListListNonNullMultiLocaleField>>;
  _allSeoMetaLocales?: Maybe<Array<SeoFieldMultiLocaleField>>;
  _allSlugLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTeaserTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  sections: Array<NewsModelSectionsField>;
  seoMeta?: Maybe<SeoField>;
  slug: Scalars['String']['output'];
  teaserImage: ImageFileField;
  teaserText: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Record of type 🗞️ News (news) */
export type NewsRecord_AllSectionsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🗞️ News (news) */
export type NewsRecord_AllSeoMetaLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🗞️ News (news) */
export type NewsRecord_AllSlugLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🗞️ News (news) */
export type NewsRecord_AllTeaserTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🗞️ News (news) */
export type NewsRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🗞️ News (news) */
export type NewsRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🗞️ News (news) */
export type NewsRecordSectionsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🗞️ News (news) */
export type NewsRecordSeoMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🗞️ News (news) */
export type NewsRecordSlugArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🗞️ News (news) */
export type NewsRecordTeaserTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🗞️ News (news) */
export type NewsRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type NewsletterSubscriptionModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<NewsletterSubscriptionModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NewsletterSubscriptionModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  buttonLabel?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  inputPlaceholder?: InputMaybe<StringFilter>;
  internalName?: InputMaybe<StringFilter>;
  optImage?: InputMaybe<FileFilter>;
  remoteFormUrl?: InputMaybe<StringFilter>;
  text?: InputMaybe<TextFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum NewsletterSubscriptionModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  ButtonLabelAsc = 'buttonLabel_ASC',
  ButtonLabelDesc = 'buttonLabel_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InputPlaceholderAsc = 'inputPlaceholder_ASC',
  InputPlaceholderDesc = 'inputPlaceholder_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  RemoteFormUrlAsc = 'remoteFormUrl_ASC',
  RemoteFormUrlDesc = 'remoteFormUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Record of type 📫 Newsletter Subscription (newsletter_subscription) */
export type NewsletterSubscriptionRecord = RecordInterface & {
  __typename: 'NewsletterSubscriptionRecord';
  _allButtonLabelLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allInputPlaceholderLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allRemoteFormUrlLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  buttonLabel: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  inputPlaceholder: Scalars['String']['output'];
  internalName: Scalars['String']['output'];
  optImage?: Maybe<ImageFileField>;
  remoteFormUrl: Scalars['String']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Record of type 📫 Newsletter Subscription (newsletter_subscription) */
export type NewsletterSubscriptionRecord_AllButtonLabelLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📫 Newsletter Subscription (newsletter_subscription) */
export type NewsletterSubscriptionRecord_AllInputPlaceholderLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📫 Newsletter Subscription (newsletter_subscription) */
export type NewsletterSubscriptionRecord_AllRemoteFormUrlLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📫 Newsletter Subscription (newsletter_subscription) */
export type NewsletterSubscriptionRecord_AllTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 📫 Newsletter Subscription (newsletter_subscription) */
export type NewsletterSubscriptionRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📫 Newsletter Subscription (newsletter_subscription) */
export type NewsletterSubscriptionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📫 Newsletter Subscription (newsletter_subscription) */
export type NewsletterSubscriptionRecordButtonLabelArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📫 Newsletter Subscription (newsletter_subscription) */
export type NewsletterSubscriptionRecordInputPlaceholderArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📫 Newsletter Subscription (newsletter_subscription) */
export type NewsletterSubscriptionRecordRemoteFormUrlArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📫 Newsletter Subscription (newsletter_subscription) */
export type NewsletterSubscriptionRecordTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 📫 Newsletter Subscription (newsletter_subscription) */
export type NewsletterSubscriptionRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type NewsletterSubscriptionRecordNonNullMultiLocaleField = {
  __typename: 'NewsletterSubscriptionRecordNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: NewsletterSubscriptionRecord;
};

/** Block of type 📧 Newsletter Subscription section (newsletter_subscription_section) */
export type NewsletterSubscriptionSectionRecord = RecordInterface & {
  __typename: 'NewsletterSubscriptionSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  form: NewsletterSubscriptionRecord;
  id: Scalars['ItemId']['output'];
};


/** Block of type 📧 Newsletter Subscription section (newsletter_subscription_section) */
export type NewsletterSubscriptionSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord = RecordInterface & {
  __typename: 'OrdersPageRecord';
  _allDetailLabelBillingAddressLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailLabelDeliveryAddressLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailLabelDeliveryDateLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailLabelInclVatLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailLabelOrderDateLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailLabelOrderStatusLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailLabelPaymentMethodLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailLabelReferenceLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailLabelRemarksLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailLabelSubtotalLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailLabelTotalLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailLabelVatLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewActionSearchLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewActionViewLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewFilterTextWithCountLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewFilterTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewHeaderOrderDateLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewHeaderOrderNumberLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewHeaderOrderStatusLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewHeaderReferenceLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewHeaderTotalLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewLabelFromLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewLabelOrderNumberLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewLabelStatusLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewLabelToLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewMessageNoItemsLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  detailLabelBillingAddress: Scalars['String']['output'];
  detailLabelDeliveryAddress: Scalars['String']['output'];
  detailLabelDeliveryDate: Scalars['String']['output'];
  detailLabelInclVat: Scalars['String']['output'];
  detailLabelOrderDate: Scalars['String']['output'];
  detailLabelOrderStatus: Scalars['String']['output'];
  detailLabelPaymentMethod: Scalars['String']['output'];
  detailLabelReference: Scalars['String']['output'];
  detailLabelRemarks: Scalars['String']['output'];
  detailLabelSubtotal: Scalars['String']['output'];
  detailLabelTotal: Scalars['String']['output'];
  detailLabelVat: Scalars['String']['output'];
  detailTitle: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  overviewActionSearch: Scalars['String']['output'];
  overviewActionView: Scalars['String']['output'];
  overviewFilterTextWithCount: Scalars['String']['output'];
  overviewFilterTitle: Scalars['String']['output'];
  overviewHeaderOrderDate: Scalars['String']['output'];
  overviewHeaderOrderNumber: Scalars['String']['output'];
  overviewHeaderOrderStatus: Scalars['String']['output'];
  overviewHeaderReference: Scalars['String']['output'];
  overviewHeaderTotal: Scalars['String']['output'];
  overviewLabelFrom: Scalars['String']['output'];
  overviewLabelOrderNumber: Scalars['String']['output'];
  overviewLabelStatus: Scalars['String']['output'];
  overviewLabelTo: Scalars['String']['output'];
  overviewMessageNoItems: Scalars['String']['output'];
  overviewTitle: Scalars['String']['output'];
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllDetailLabelBillingAddressLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllDetailLabelDeliveryAddressLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllDetailLabelDeliveryDateLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllDetailLabelInclVatLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllDetailLabelOrderDateLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllDetailLabelOrderStatusLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllDetailLabelPaymentMethodLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllDetailLabelReferenceLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllDetailLabelRemarksLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllDetailLabelSubtotalLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllDetailLabelTotalLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllDetailLabelVatLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllDetailTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllOverviewActionSearchLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllOverviewActionViewLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllOverviewFilterTextWithCountLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllOverviewFilterTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllOverviewHeaderOrderDateLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllOverviewHeaderOrderNumberLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllOverviewHeaderOrderStatusLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllOverviewHeaderReferenceLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllOverviewHeaderTotalLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllOverviewLabelFromLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllOverviewLabelOrderNumberLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllOverviewLabelStatusLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllOverviewLabelToLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllOverviewMessageNoItemsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_AllOverviewTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordDetailLabelBillingAddressArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordDetailLabelDeliveryAddressArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordDetailLabelDeliveryDateArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordDetailLabelInclVatArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordDetailLabelOrderDateArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordDetailLabelOrderStatusArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordDetailLabelPaymentMethodArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordDetailLabelReferenceArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordDetailLabelRemarksArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordDetailLabelSubtotalArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordDetailLabelTotalArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordDetailLabelVatArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordDetailTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordOverviewActionSearchArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordOverviewActionViewArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordOverviewFilterTextWithCountArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordOverviewFilterTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordOverviewHeaderOrderDateArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordOverviewHeaderOrderNumberArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordOverviewHeaderOrderStatusArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordOverviewHeaderReferenceArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordOverviewHeaderTotalArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordOverviewLabelFromArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordOverviewLabelOrderNumberArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordOverviewLabelStatusArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordOverviewLabelToArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordOverviewMessageNoItemsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🛒 Orders (orders_page) */
export type OrdersPageRecordOverviewTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by image orientation */
export type OrientationFilter = {
  /** Search uploads with the specified orientation */
  eq?: InputMaybe<UploadOrientation>;
  /** Exclude uploads with the specified orientation */
  neq?: InputMaybe<UploadOrientation>;
};

/** Block of type 🤝 Page Link (page_link) */
export type PageLinkRecord = RecordInterface & {
  __typename: 'PageLinkRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  pageLink: PageRecord;
  text: Scalars['String']['output'];
};


/** Block of type 🤝 Page Link (page_link) */
export type PageLinkRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type PageModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<PageModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<PageModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  parentPage?: InputMaybe<LinkFilter>;
  seoMeta?: InputMaybe<SeoFilter>;
  slug?: InputMaybe<SlugFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum PageModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type PageModelSectionsField = AllEventsSectionRecord | AllNewsSectionRecord | AllStoresSectionRecord | AllTrendsSectionRecord | BrandsSectionRecord | CallToActionSectionRecord | ContentGridSectionRecord | ContentSectionRecord | CustomerCtaSectionRecord | FaqSectionRecord | FormSectionRecord | HeaderSectionRecord | HomepageHeroSectionRecord | LatestTrendsSectionRecord | MagazineSectionRecord | NewsletterSubscriptionSectionRecord | ProductGroupDetailSectionRecord | ProductGroupOverviewSectionRecord | SearchSectionRecord | SplitSectionRecord | StepsSectionRecord | TestimonialsSectionRecord | UpcomingEventsSectionRecord | UspSectionRecord;

export type PageModelSectionsFieldListListNonNullMultiLocaleField = {
  __typename: 'PageModelSectionsFieldListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<PageModelSectionsField>;
};

/** Record of type 🏡 Page (page) */
export type PageRecord = RecordInterface & {
  __typename: 'PageRecord';
  _allSectionsLocales?: Maybe<Array<PageModelSectionsFieldListListNonNullMultiLocaleField>>;
  _allSeoMetaLocales?: Maybe<Array<SeoFieldMultiLocaleField>>;
  _allSlugLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  parentPage?: Maybe<PageRecord>;
  sections: Array<PageModelSectionsField>;
  seoMeta?: Maybe<SeoField>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Record of type 🏡 Page (page) */
export type PageRecord_AllSectionsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Page (page) */
export type PageRecord_AllSeoMetaLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Page (page) */
export type PageRecord_AllSlugLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Page (page) */
export type PageRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏡 Page (page) */
export type PageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Page (page) */
export type PageRecordSectionsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Page (page) */
export type PageRecordSeoMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Page (page) */
export type PageRecordSlugArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏡 Page (page) */
export type PageRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type PageRecordListListNonNullMultiLocaleField = {
  __typename: 'PageRecordListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<PageRecord>;
};

export type PageRecordMultiLocaleField = {
  __typename: 'PageRecordMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value?: Maybe<PageRecord>;
};

export type PageRecordNonNullMultiLocaleField = {
  __typename: 'PageRecordNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: PageRecord;
};

/** Specifies how to filter by position (sorted and tree-like collections) */
export type PositionFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['IntType']['input']>;
  /** Filter records with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars['IntType']['input']>;
  /** Filter records with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars['IntType']['input']>;
  /** Filter records with a value that's less than the one specified */
  lt?: InputMaybe<Scalars['IntType']['input']>;
  /** Filter records with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars['IntType']['input']>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars['IntType']['input']>;
};

/** Block of type 🍴 Product Group Detail section (product_group_detail_section) */
export type ProductGroupDetailSectionRecord = RecordInterface & {
  __typename: 'ProductGroupDetailSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  footerImage?: Maybe<ImageFileField>;
  groups: Array<ProductGroupRecord>;
  id: Scalars['ItemId']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Block of type 🍴 Product Group Detail section (product_group_detail_section) */
export type ProductGroupDetailSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 🍴 Product Group Detail section (product_group_detail_section) */
export type ProductGroupDetailSectionRecordTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductGroupModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<ProductGroupModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ProductGroupModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  icon?: InputMaybe<FileFilter>;
  id?: InputMaybe<ItemIdFilter>;
  name?: InputMaybe<StringFilter>;
};

export enum ProductGroupModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

/** Block of type 🍴 Product Group Overview section (product_group_overview_section) */
export type ProductGroupOverviewSectionRecord = RecordInterface & {
  __typename: 'ProductGroupOverviewSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  footerImage?: Maybe<ImageFileField>;
  footerImageLeft?: Maybe<ImageFileField>;
  footerImageRight?: Maybe<ImageFileField>;
  groups: Array<ProductGroupRecord>;
  id: Scalars['ItemId']['output'];
  pageLink: Array<PageLinkRecord>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Block of type 🍴 Product Group Overview section (product_group_overview_section) */
export type ProductGroupOverviewSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 🍴 Product Group Overview section (product_group_overview_section) */
export type ProductGroupOverviewSectionRecordTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Record of type 🍴Product Group (product_group) */
export type ProductGroupRecord = RecordInterface & {
  __typename: 'ProductGroupRecord';
  _allNameLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  icon: FileField;
  id: Scalars['ItemId']['output'];
  name: Scalars['String']['output'];
};


/** Record of type 🍴Product Group (product_group) */
export type ProductGroupRecord_AllNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🍴Product Group (product_group) */
export type ProductGroupRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🍴Product Group (product_group) */
export type ProductGroupRecordNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by publication datetime */
export type PublishedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

/** The query root for this schema */
export type Query = {
  __typename: 'Query';
  /** Returns meta information regarding a record collection */
  _allAlertsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allBrandsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allCustomerSegmentsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allEventsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allFaqsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allLegalPagesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allMagazinesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allNewsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allNewsletterSubscriptionsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allPagesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allProductGroupsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allStoresMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allTestimonialsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allTranslationsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allTrendsInspirationTagsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allTrendsInspirationsMeta: CollectionMetadata;
  /** Returns meta information regarding an assets collection */
  _allUploadsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allUspsMeta: CollectionMetadata;
  /** Returns the single instance record */
  _site: Site;
  /** Returns the single instance record */
  accountPage?: Maybe<AccountPageRecord>;
  /** Returns a specific record */
  alert?: Maybe<AlertRecord>;
  /** Returns a collection of records */
  allAlerts: Array<AlertRecord>;
  /** Returns a collection of records */
  allBrands: Array<BrandRecord>;
  /** Returns a collection of records */
  allCustomerSegments: Array<CustomerSegmentRecord>;
  /** Returns a collection of records */
  allEvents: Array<EventRecord>;
  /** Returns a collection of records */
  allFaqs: Array<FaqRecord>;
  /** Returns a collection of records */
  allLegalPages: Array<LegalPageRecord>;
  /** Returns a collection of records */
  allMagazines: Array<MagazineRecord>;
  /** Returns a collection of records */
  allNews: Array<NewsRecord>;
  /** Returns a collection of records */
  allNewsletterSubscriptions: Array<NewsletterSubscriptionRecord>;
  /** Returns a collection of records */
  allPages: Array<PageRecord>;
  /** Returns a collection of records */
  allProductGroups: Array<ProductGroupRecord>;
  /** Returns a collection of records */
  allStores: Array<StoreRecord>;
  /** Returns a collection of records */
  allTestimonials: Array<TestimonialRecord>;
  /** Returns a collection of records */
  allTranslations: Array<TranslationRecord>;
  /** Returns a collection of records */
  allTrendsInspirationTags: Array<TrendsInspirationTagRecord>;
  /** Returns a collection of records */
  allTrendsInspirations: Array<TrendsInspirationRecord>;
  /** Returns a collection of assets */
  allUploads: Array<FileField>;
  /** Returns a collection of records */
  allUsps: Array<UspRecord>;
  /** Returns a specific record */
  brand?: Maybe<BrandRecord>;
  /** Returns the single instance record */
  catalogPage?: Maybe<CatalogPageRecord>;
  /** Returns a specific record */
  customerSegment?: Maybe<CustomerSegmentRecord>;
  /** Returns a specific record */
  event?: Maybe<EventRecord>;
  /** Returns a specific record */
  faq?: Maybe<FaqRecord>;
  /** Returns the single instance record */
  invoicesPage?: Maybe<InvoicesPageRecord>;
  /** Returns the single instance record */
  layout?: Maybe<LayoutRecord>;
  /** Returns a specific record */
  legalPage?: Maybe<LegalPageRecord>;
  /** Returns a specific record */
  magazine?: Maybe<MagazineRecord>;
  /** Returns a specific record */
  news?: Maybe<NewsRecord>;
  /** Returns a specific record */
  newsletterSubscription?: Maybe<NewsletterSubscriptionRecord>;
  /** Returns the single instance record */
  ordersPage?: Maybe<OrdersPageRecord>;
  /** Returns a specific record */
  page?: Maybe<PageRecord>;
  /** Returns a specific record */
  productGroup?: Maybe<ProductGroupRecord>;
  /** Returns a specific record */
  store?: Maybe<StoreRecord>;
  /** Returns the single instance record */
  templatesPage?: Maybe<TemplatesPageRecord>;
  /** Returns a specific record */
  testimonial?: Maybe<TestimonialRecord>;
  /** Returns a specific record */
  translation?: Maybe<TranslationRecord>;
  /** Returns a specific record */
  trendsInspiration?: Maybe<TrendsInspirationRecord>;
  /** Returns a specific record */
  trendsInspirationTag?: Maybe<TrendsInspirationTagRecord>;
  /** Returns a specific asset */
  upload?: Maybe<FileField>;
  /** Returns a specific record */
  usp?: Maybe<UspRecord>;
};


/** The query root for this schema */
export type Query_AllAlertsMetaArgs = {
  filter?: InputMaybe<AlertModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllBrandsMetaArgs = {
  filter?: InputMaybe<BrandModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllCustomerSegmentsMetaArgs = {
  filter?: InputMaybe<CustomerSegmentModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllEventsMetaArgs = {
  filter?: InputMaybe<EventModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllFaqsMetaArgs = {
  filter?: InputMaybe<FaqModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllLegalPagesMetaArgs = {
  filter?: InputMaybe<LegalPageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllMagazinesMetaArgs = {
  filter?: InputMaybe<MagazineModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllNewsMetaArgs = {
  filter?: InputMaybe<NewsModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllNewsletterSubscriptionsMetaArgs = {
  filter?: InputMaybe<NewsletterSubscriptionModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllPagesMetaArgs = {
  filter?: InputMaybe<PageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllProductGroupsMetaArgs = {
  filter?: InputMaybe<ProductGroupModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllStoresMetaArgs = {
  filter?: InputMaybe<StoreModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllTestimonialsMetaArgs = {
  filter?: InputMaybe<TestimonialModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllTranslationsMetaArgs = {
  filter?: InputMaybe<TranslationModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllTrendsInspirationTagsMetaArgs = {
  filter?: InputMaybe<TrendsInspirationTagModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllTrendsInspirationsMetaArgs = {
  filter?: InputMaybe<TrendsInspirationModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllUploadsMetaArgs = {
  filter?: InputMaybe<UploadFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_AllUspsMetaArgs = {
  filter?: InputMaybe<UspModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type Query_SiteArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type QueryAccountPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type QueryAlertArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<AlertModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<AlertModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllAlertsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<AlertModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<AlertModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllBrandsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<BrandModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BrandModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllCustomerSegmentsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CustomerSegmentModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CustomerSegmentModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllEventsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<EventModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<EventModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllFaqsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<FaqModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<FaqModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllLegalPagesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<LegalPageModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<LegalPageModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllMagazinesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<MagazineModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<MagazineModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllNewsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<NewsModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<NewsModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllNewsletterSubscriptionsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<NewsletterSubscriptionModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<NewsletterSubscriptionModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllPagesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<PageModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PageModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllProductGroupsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ProductGroupModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductGroupModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllStoresArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<StoreModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<StoreModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllTestimonialsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TestimonialModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TestimonialModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllTranslationsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TranslationModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TranslationModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllTrendsInspirationTagsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TrendsInspirationTagModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TrendsInspirationTagModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllTrendsInspirationsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TrendsInspirationModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TrendsInspirationModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllUploadsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<UploadFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UploadOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryAllUspsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<UspModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UspModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};


/** The query root for this schema */
export type QueryBrandArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<BrandModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BrandModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryCatalogPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type QueryCustomerSegmentArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CustomerSegmentModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CustomerSegmentModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryEventArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<EventModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<EventModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryFaqArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<FaqModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<FaqModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryInvoicesPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type QueryLayoutArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type QueryLegalPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<LegalPageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<LegalPageModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryMagazineArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<MagazineModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<MagazineModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryNewsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<NewsModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<NewsModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryNewsletterSubscriptionArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<NewsletterSubscriptionModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<NewsletterSubscriptionModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryOrdersPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type QueryPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<PageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<PageModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryProductGroupArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ProductGroupModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ProductGroupModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryStoreArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<StoreModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<StoreModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryTemplatesPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** The query root for this schema */
export type QueryTestimonialArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TestimonialModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TestimonialModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryTranslationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TranslationModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TranslationModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryTrendsInspirationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TrendsInspirationModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TrendsInspirationModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryTrendsInspirationTagArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TrendsInspirationTagModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TrendsInspirationTagModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryUploadArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<UploadFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UploadOrderBy>>>;
};


/** The query root for this schema */
export type QueryUspArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<UspModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UspModelOrderBy>>>;
};

export type RecordInterface = {
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
};


export type RecordInterface_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by upload type */
export type ResolutionFilter = {
  /** Search uploads with the specified resolution */
  eq?: InputMaybe<ResolutionType>;
  /** Search uploads with the specified resolutions */
  in?: InputMaybe<Array<InputMaybe<ResolutionType>>>;
  /** Exclude uploads with the specified resolution */
  neq?: InputMaybe<ResolutionType>;
  /** Search uploads without the specified resolutions */
  notIn?: InputMaybe<Array<InputMaybe<ResolutionType>>>;
};

export enum ResolutionType {
  Icon = 'icon',
  Large = 'large',
  Medium = 'medium',
  Small = 'small'
}

export type ResponsiveImage = {
  __typename: 'ResponsiveImage';
  alt?: Maybe<Scalars['String']['output']>;
  aspectRatio: Scalars['FloatType']['output'];
  base64?: Maybe<Scalars['String']['output']>;
  bgColor?: Maybe<Scalars['String']['output']>;
  height: Scalars['IntType']['output'];
  sizes: Scalars['String']['output'];
  src: Scalars['String']['output'];
  srcSet: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  webpSrcSet: Scalars['String']['output'];
  width: Scalars['IntType']['output'];
};

/** Block of type 🔍 Search section (search_section) */
export type SearchSectionRecord = RecordInterface & {
  __typename: 'SearchSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  optIntro?: Maybe<Scalars['String']['output']>;
  segments: Array<CustomerSegmentRecord>;
  title: Scalars['String']['output'];
};


/** Block of type 🔍 Search section (search_section) */
export type SearchSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 🔍 Search section (search_section) */
export type SearchSectionRecordOptIntroArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SeoField = {
  __typename: 'SeoField';
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<FileField>;
  noIndex?: Maybe<Scalars['BooleanType']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  twitterCard?: Maybe<Scalars['String']['output']>;
};

export type SeoFieldMultiLocaleField = {
  __typename: 'SeoFieldMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value?: Maybe<SeoField>;
};

/** Specifies how to filter SEO meta tags fields */
export type SeoFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
};

export type Site = {
  __typename: 'Site';
  favicon?: Maybe<FileField>;
  faviconMetaTags: Array<Tag>;
  globalSeo?: Maybe<GlobalSeoField>;
  locales: Array<SiteLocale>;
  noIndex?: Maybe<Scalars['BooleanType']['output']>;
};


export type SiteFaviconMetaTagsArgs = {
  variants?: InputMaybe<Array<InputMaybe<FaviconType>>>;
};


export type SiteGlobalSeoArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export enum SiteLocale {
  FrBe = 'fr_BE',
  NlBe = 'nl_BE'
}

/** Specifies how to filter Slug fields */
export type SlugFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Filter records that have one of the specified slugs */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Filter records that do have one of the specified slugs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Block of type 🌍 Social Media Icon (social_media_icon) */
export type SocialMediaIconRecord = RecordInterface & {
  __typename: 'SocialMediaIconRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  iconName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ItemId']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};


/** Block of type 🌍 Social Media Icon (social_media_icon) */
export type SocialMediaIconRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type SplitItemModelOptLinkItemField = CustomLinkRecord | PageLinkRecord;

/** Block of type 📝 Split item  (split_item) */
export type SplitItemRecord = RecordInterface & {
  __typename: 'SplitItemRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image: ImageFileField;
  imagePosition: Scalars['BooleanType']['output'];
  optLinkItem?: Maybe<SplitItemModelOptLinkItemField>;
  text: Scalars['String']['output'];
};


/** Block of type 📝 Split item  (split_item) */
export type SplitItemRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 📝 Split item  (split_item) */
export type SplitItemRecordTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Block of type 📰 Split section (split_section) */
export type SplitSectionRecord = RecordInterface & {
  __typename: 'SplitSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  items: Array<SplitItemRecord>;
};


/** Block of type 📰 Split section (split_section) */
export type SplitSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by status */
export type StatusFilter = {
  /** Search the record with the specified status */
  eq?: InputMaybe<ItemStatus>;
  /** Search records with the specified statuses */
  in?: InputMaybe<Array<InputMaybe<ItemStatus>>>;
  /** Exclude the record with the specified status */
  neq?: InputMaybe<ItemStatus>;
  /** Search records without the specified statuses */
  notIn?: InputMaybe<Array<InputMaybe<ItemStatus>>>;
};

/** Block of type 👣 Steps card (steps_card) */
export type StepsCardRecord = RecordInterface & {
  __typename: 'StepsCardRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Block of type 👣 Steps card (steps_card) */
export type StepsCardRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 👣 Steps card (steps_card) */
export type StepsCardRecordTextArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Block of type 👣 Steps section (steps_section) */
export type StepsSectionRecord = RecordInterface & {
  __typename: 'StepsSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  steps: Array<StepsCardRecord>;
  title: Scalars['String']['output'];
};


/** Block of type 👣 Steps section (steps_section) */
export type StepsSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type StoreModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<StoreModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<StoreModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  address?: InputMaybe<TextFilter>;
  id?: InputMaybe<ItemIdFilter>;
  location?: InputMaybe<LatLonFilter>;
  name?: InputMaybe<StringFilter>;
  optEmailAddress?: InputMaybe<StringFilter>;
  optFaxNumber?: InputMaybe<StringFilter>;
  optPhoneNumber?: InputMaybe<StringFilter>;
  position?: InputMaybe<PositionFilter>;
  seoMeta?: InputMaybe<SeoFilter>;
  slug?: InputMaybe<SlugFilter>;
  teaserImage?: InputMaybe<FileFilter>;
  teaserLinkText?: InputMaybe<StringFilter>;
};

export enum StoreModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OptEmailAddressAsc = 'optEmailAddress_ASC',
  OptEmailAddressDesc = 'optEmailAddress_DESC',
  OptFaxNumberAsc = 'optFaxNumber_ASC',
  OptFaxNumberDesc = 'optFaxNumber_DESC',
  OptPhoneNumberAsc = 'optPhoneNumber_ASC',
  OptPhoneNumberDesc = 'optPhoneNumber_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  TeaserLinkTextAsc = 'teaserLinkText_ASC',
  TeaserLinkTextDesc = 'teaserLinkText_DESC'
}

export type StoreModelSectionsField = HeaderSectionRecord | MagazineSectionRecord | NewsletterSubscriptionSectionRecord;

export type StoreModelSectionsFieldListListNonNullMultiLocaleField = {
  __typename: 'StoreModelSectionsFieldListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<StoreModelSectionsField>;
};

/** Record of type 🏢 Store (store) */
export type StoreRecord = RecordInterface & {
  __typename: 'StoreRecord';
  _allAddressLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allNameLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allSectionsLocales?: Maybe<Array<StoreModelSectionsFieldListListNonNullMultiLocaleField>>;
  _allSeoMetaLocales?: Maybe<Array<SeoFieldMultiLocaleField>>;
  _allSlugLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTeaserLinkTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  address: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  location?: Maybe<LatLonField>;
  name: Scalars['String']['output'];
  openingHours: Array<HourRecord>;
  optEmailAddress?: Maybe<Scalars['String']['output']>;
  optFaxNumber?: Maybe<Scalars['String']['output']>;
  optPhoneNumber?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['IntType']['output']>;
  sections: Array<StoreModelSectionsField>;
  seoMeta?: Maybe<SeoField>;
  slug: Scalars['String']['output'];
  teaserImage: ImageFileField;
  teaserLinkText: Scalars['String']['output'];
};


/** Record of type 🏢 Store (store) */
export type StoreRecord_AllAddressLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🏢 Store (store) */
export type StoreRecord_AllNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏢 Store (store) */
export type StoreRecord_AllSectionsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏢 Store (store) */
export type StoreRecord_AllSeoMetaLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏢 Store (store) */
export type StoreRecord_AllSlugLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏢 Store (store) */
export type StoreRecord_AllTeaserLinkTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏢 Store (store) */
export type StoreRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏢 Store (store) */
export type StoreRecordAddressArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 🏢 Store (store) */
export type StoreRecordNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏢 Store (store) */
export type StoreRecordSectionsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏢 Store (store) */
export type StoreRecordSeoMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏢 Store (store) */
export type StoreRecordSlugArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏢 Store (store) */
export type StoreRecordTeaserLinkTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter Single-line string fields */
export type StringFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records that equal one of the specified values */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter records with the specified field set as blank (null or empty string) */
  isBlank?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Filter records that do not equal one of the specified values */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type StringMatchesFilter = {
  caseSensitive?: InputMaybe<Scalars['BooleanType']['input']>;
  pattern: Scalars['String']['input'];
  regexp?: InputMaybe<Scalars['BooleanType']['input']>;
};

export type StringMultiLocaleField = {
  __typename: 'StringMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value?: Maybe<Scalars['String']['output']>;
};

export type StringNonNullMultiLocaleField = {
  __typename: 'StringNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Scalars['String']['output'];
};

/** Specifies how to filter Structured Text fields values */
export type StructuredTextFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with the specified field set as blank (null or single empty paragraph) */
  isBlank?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type Tag = {
  __typename: 'Tag';
  attributes?: Maybe<Scalars['MetaTagAttributes']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  tag: Scalars['String']['output'];
};

/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord = RecordInterface & {
  __typename: 'TemplatesPageRecord';
  _allDetailActionAddToBasketLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailActionAddToTemplateLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailActionSaveLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailDeleteTemplateLineActionCancelLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailDeleteTemplateLineActionConfirmationLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailDeleteTemplateLineTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailDeleteTemplateLineTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailFilterTextWithCountLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailFilterTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailLabelResultsLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailLabelSearchLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailLabelSortLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailMessageNoResultsLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailPlaceholderBrandLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailPlaceholderPackageTypeLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailPlaceholderPreservationLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailPlaceholderSearchLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allDetailSectionsLocales?: Maybe<Array<HintSectionRecordListListNonNullMultiLocaleField>>;
  _allDetailTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewActionCreateTemplateLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewActionUpdateTemplateLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewActionViewLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewCreateTemplateActionCancelLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewCreateTemplateActionConfirmationLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewCreateTemplatePlaceholderLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewCreateTemplateTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewCreateTemplateTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewDeleteTemplateActionCancelLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewDeleteTemplateActionConfirmationLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewDeleteTemplateTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewDeleteTemplateTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewHeaderCreatedAtLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewHeaderNameLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewMessageNoItemsLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewSectionsLocales?: Maybe<Array<HintSectionRecordListListNonNullMultiLocaleField>>;
  _allOverviewTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewUpdateTemplateActionCancelLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewUpdateTemplateActionConfirmationLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewUpdateTemplatePlaceholderLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewUpdateTemplateTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allOverviewUpdateTemplateTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  detailActionAddToBasket: Scalars['String']['output'];
  detailActionAddToTemplate: Scalars['String']['output'];
  detailActionSave: Scalars['String']['output'];
  detailDeleteTemplateLineActionCancel: Scalars['String']['output'];
  detailDeleteTemplateLineActionConfirmation: Scalars['String']['output'];
  detailDeleteTemplateLineText: Scalars['String']['output'];
  detailDeleteTemplateLineTitle: Scalars['String']['output'];
  detailFilterTextWithCount: Scalars['String']['output'];
  detailFilterTitle: Scalars['String']['output'];
  detailLabelResults: Scalars['String']['output'];
  detailLabelSearch: Scalars['String']['output'];
  detailLabelSort: Scalars['String']['output'];
  detailMessageNoResults: Scalars['String']['output'];
  detailPlaceholderBrand: Scalars['String']['output'];
  detailPlaceholderPackageType: Scalars['String']['output'];
  detailPlaceholderPreservation: Scalars['String']['output'];
  detailPlaceholderSearch: Scalars['String']['output'];
  detailSections: Array<HintSectionRecord>;
  detailTitle: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  overviewActionCreateTemplate: Scalars['String']['output'];
  overviewActionUpdateTemplate: Scalars['String']['output'];
  overviewActionView: Scalars['String']['output'];
  overviewCreateTemplateActionCancel: Scalars['String']['output'];
  overviewCreateTemplateActionConfirmation: Scalars['String']['output'];
  overviewCreateTemplatePlaceholder: Scalars['String']['output'];
  overviewCreateTemplateText: Scalars['String']['output'];
  overviewCreateTemplateTitle: Scalars['String']['output'];
  overviewDeleteTemplateActionCancel: Scalars['String']['output'];
  overviewDeleteTemplateActionConfirmation: Scalars['String']['output'];
  overviewDeleteTemplateText: Scalars['String']['output'];
  overviewDeleteTemplateTitle: Scalars['String']['output'];
  overviewHeaderCreatedAt: Scalars['String']['output'];
  overviewHeaderName: Scalars['String']['output'];
  overviewMessageNoItems: Scalars['String']['output'];
  overviewSections: Array<HintSectionRecord>;
  overviewTitle: Scalars['String']['output'];
  overviewUpdateTemplateActionCancel: Scalars['String']['output'];
  overviewUpdateTemplateActionConfirmation: Scalars['String']['output'];
  overviewUpdateTemplatePlaceholder: Scalars['String']['output'];
  overviewUpdateTemplateText: Scalars['String']['output'];
  overviewUpdateTemplateTitle: Scalars['String']['output'];
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllDetailActionAddToBasketLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllDetailActionAddToTemplateLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllDetailActionSaveLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllDetailDeleteTemplateLineActionCancelLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllDetailDeleteTemplateLineActionConfirmationLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllDetailDeleteTemplateLineTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllDetailDeleteTemplateLineTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllDetailFilterTextWithCountLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllDetailFilterTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllDetailLabelResultsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllDetailLabelSearchLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllDetailLabelSortLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllDetailMessageNoResultsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllDetailPlaceholderBrandLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllDetailPlaceholderPackageTypeLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllDetailPlaceholderPreservationLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllDetailPlaceholderSearchLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllDetailSectionsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllDetailTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewActionCreateTemplateLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewActionUpdateTemplateLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewActionViewLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewCreateTemplateActionCancelLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewCreateTemplateActionConfirmationLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewCreateTemplatePlaceholderLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewCreateTemplateTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewCreateTemplateTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewDeleteTemplateActionCancelLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewDeleteTemplateActionConfirmationLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewDeleteTemplateTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewDeleteTemplateTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewHeaderCreatedAtLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewHeaderNameLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewMessageNoItemsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewSectionsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewUpdateTemplateActionCancelLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewUpdateTemplateActionConfirmationLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewUpdateTemplatePlaceholderLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewUpdateTemplateTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_AllOverviewUpdateTemplateTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordDetailActionAddToBasketArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordDetailActionAddToTemplateArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordDetailActionSaveArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordDetailDeleteTemplateLineActionCancelArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordDetailDeleteTemplateLineActionConfirmationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordDetailDeleteTemplateLineTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordDetailDeleteTemplateLineTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordDetailFilterTextWithCountArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordDetailFilterTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordDetailLabelResultsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordDetailLabelSearchArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordDetailLabelSortArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordDetailMessageNoResultsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordDetailPlaceholderBrandArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordDetailPlaceholderPackageTypeArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordDetailPlaceholderPreservationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordDetailPlaceholderSearchArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordDetailSectionsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordDetailTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewActionCreateTemplateArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewActionUpdateTemplateArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewActionViewArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewCreateTemplateActionCancelArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewCreateTemplateActionConfirmationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewCreateTemplatePlaceholderArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewCreateTemplateTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewCreateTemplateTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewDeleteTemplateActionCancelArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewDeleteTemplateActionConfirmationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewDeleteTemplateTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewDeleteTemplateTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewHeaderCreatedAtArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewHeaderNameArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewMessageNoItemsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewSectionsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewUpdateTemplateActionCancelArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewUpdateTemplateActionConfirmationArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewUpdateTemplatePlaceholderArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewUpdateTemplateTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 📝 Templates (templates_page) */
export type TemplatesPageRecordOverviewUpdateTemplateTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type TestimonialModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<TestimonialModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TestimonialModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  personImage?: InputMaybe<FileFilter>;
  personName?: InputMaybe<StringFilter>;
  personTitle?: InputMaybe<StringFilter>;
  quote?: InputMaybe<TextFilter>;
};

export enum TestimonialModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PersonNameAsc = 'personName_ASC',
  PersonNameDesc = 'personName_DESC',
  PersonTitleAsc = 'personTitle_ASC',
  PersonTitleDesc = 'personTitle_DESC'
}

/** Record of type 💬 Testimonial (testimonial) */
export type TestimonialRecord = RecordInterface & {
  __typename: 'TestimonialRecord';
  _allPersonTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allQuoteLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  personImage?: Maybe<ImageFileField>;
  personName: Scalars['String']['output'];
  personTitle: Scalars['String']['output'];
  quote: Scalars['String']['output'];
};


/** Record of type 💬 Testimonial (testimonial) */
export type TestimonialRecord_AllPersonTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 💬 Testimonial (testimonial) */
export type TestimonialRecord_AllQuoteLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 💬 Testimonial (testimonial) */
export type TestimonialRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 💬 Testimonial (testimonial) */
export type TestimonialRecordPersonTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 💬 Testimonial (testimonial) */
export type TestimonialRecordQuoteArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Block of type 💬 Testimonials section (testimonials_section) */
export type TestimonialsSectionRecord = RecordInterface & {
  __typename: 'TestimonialsSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  items: Array<TestimonialRecord>;
  optIntro?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};


/** Block of type 💬 Testimonials section (testimonials_section) */
export type TestimonialsSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Block of type 💬 Testimonials section (testimonials_section) */
export type TestimonialsSectionRecordOptIntroArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Specifies how to filter text fields */
export type TextFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with the specified field set as blank (null or empty string) */
  isBlank?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type TranslationModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<TranslationModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TranslationModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  comment?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  name?: InputMaybe<StringFilter>;
  value?: InputMaybe<StringFilter>;
};

export enum TranslationModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC'
}

/** Record of type 🌍 Translation (translation) */
export type TranslationRecord = RecordInterface & {
  __typename: 'TranslationRecord';
  _allValueLocales?: Maybe<Array<StringMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ItemId']['output'];
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};


/** Record of type 🌍 Translation (translation) */
export type TranslationRecord_AllValueLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🌍 Translation (translation) */
export type TranslationRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🌍 Translation (translation) */
export type TranslationRecordValueArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type TrendsInspirationModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<TrendsInspirationModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TrendsInspirationModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  seoMeta?: InputMaybe<SeoFilter>;
  slug?: InputMaybe<SlugFilter>;
  tags?: InputMaybe<LinksFilter>;
  teaserImage?: InputMaybe<FileFilter>;
  teaserText?: InputMaybe<TextFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum TrendsInspirationModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type TrendsInspirationModelSectionsField = ContentSectionRecord | HeaderSectionRecord | LatestTrendsSectionRecord | MagazineSectionRecord | NewsletterSubscriptionSectionRecord | SplitSectionRecord | UpcomingEventsSectionRecord;

export type TrendsInspirationModelSectionsFieldListListNonNullMultiLocaleField = {
  __typename: 'TrendsInspirationModelSectionsFieldListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<TrendsInspirationModelSectionsField>;
};

/** Record of type 💖 Trends & Inspiration (trends_inspiration) */
export type TrendsInspirationRecord = RecordInterface & {
  __typename: 'TrendsInspirationRecord';
  _allSectionsLocales?: Maybe<Array<TrendsInspirationModelSectionsFieldListListNonNullMultiLocaleField>>;
  _allSeoMetaLocales?: Maybe<Array<SeoFieldMultiLocaleField>>;
  _allSlugLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTagsLocales?: Maybe<Array<TrendsInspirationTagRecordListListNonNullMultiLocaleField>>;
  _allTeaserTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  sections: Array<TrendsInspirationModelSectionsField>;
  seoMeta?: Maybe<SeoField>;
  slug: Scalars['String']['output'];
  tags: Array<TrendsInspirationTagRecord>;
  teaserImage: ImageFileField;
  teaserText: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Record of type 💖 Trends & Inspiration (trends_inspiration) */
export type TrendsInspirationRecord_AllSectionsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 💖 Trends & Inspiration (trends_inspiration) */
export type TrendsInspirationRecord_AllSeoMetaLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 💖 Trends & Inspiration (trends_inspiration) */
export type TrendsInspirationRecord_AllSlugLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 💖 Trends & Inspiration (trends_inspiration) */
export type TrendsInspirationRecord_AllTagsLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 💖 Trends & Inspiration (trends_inspiration) */
export type TrendsInspirationRecord_AllTeaserTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 💖 Trends & Inspiration (trends_inspiration) */
export type TrendsInspirationRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 💖 Trends & Inspiration (trends_inspiration) */
export type TrendsInspirationRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 💖 Trends & Inspiration (trends_inspiration) */
export type TrendsInspirationRecordSectionsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 💖 Trends & Inspiration (trends_inspiration) */
export type TrendsInspirationRecordSeoMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 💖 Trends & Inspiration (trends_inspiration) */
export type TrendsInspirationRecordSlugArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 💖 Trends & Inspiration (trends_inspiration) */
export type TrendsInspirationRecordTagsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 💖 Trends & Inspiration (trends_inspiration) */
export type TrendsInspirationRecordTeaserTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type 💖 Trends & Inspiration (trends_inspiration) */
export type TrendsInspirationRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type TrendsInspirationTagModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<TrendsInspirationTagModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TrendsInspirationTagModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  slug?: InputMaybe<SlugFilter>;
  tag?: InputMaybe<StringFilter>;
};

export enum TrendsInspirationTagModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TagAsc = 'tag_ASC',
  TagDesc = 'tag_DESC'
}

/** Record of type 🏷️ Trends & Inspiration Tag (trends_inspiration_tag) */
export type TrendsInspirationTagRecord = RecordInterface & {
  __typename: 'TrendsInspirationTagRecord';
  _allSlugLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTagLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  slug: Scalars['String']['output'];
  tag: Scalars['String']['output'];
};


/** Record of type 🏷️ Trends & Inspiration Tag (trends_inspiration_tag) */
export type TrendsInspirationTagRecord_AllSlugLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏷️ Trends & Inspiration Tag (trends_inspiration_tag) */
export type TrendsInspirationTagRecord_AllTagLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type 🏷️ Trends & Inspiration Tag (trends_inspiration_tag) */
export type TrendsInspirationTagRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏷️ Trends & Inspiration Tag (trends_inspiration_tag) */
export type TrendsInspirationTagRecordSlugArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type 🏷️ Trends & Inspiration Tag (trends_inspiration_tag) */
export type TrendsInspirationTagRecordTagArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type TrendsInspirationTagRecordListListNonNullMultiLocaleField = {
  __typename: 'TrendsInspirationTagRecordListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<TrendsInspirationTagRecord>;
};

/** Specifies how to filter by upload type */
export type TypeFilter = {
  /** Search uploads with the specified type */
  eq?: InputMaybe<UploadType>;
  /** Search uploads with the specified types */
  in?: InputMaybe<Array<InputMaybe<UploadType>>>;
  /** Exclude uploads with the specified type */
  neq?: InputMaybe<UploadType>;
  /** Search uploads without the specified types */
  notIn?: InputMaybe<Array<InputMaybe<UploadType>>>;
};

/** Block of type ⚡ Upcoming Events section (upcoming_events_section) */
export type UpcomingEventsSectionRecord = RecordInterface & {
  __typename: 'UpcomingEventsSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  itemCount: Scalars['IntType']['output'];
  linkPage: PageRecord;
  linkText: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Block of type ⚡ Upcoming Events section (upcoming_events_section) */
export type UpcomingEventsSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by update datetime */
export type UpdatedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Specifies how to filter by default alt */
export type UploadAltFilter = {
  /** Search the uploads with the specified alt */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Search uploads with the specified values as default alt */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude the uploads with the specified alt */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Search uploads that do not have the specified values as default alt */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by auhtor */
export type UploadAuthorFilter = {
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by basename */
export type UploadBasenameFilter = {
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by colors */
export type UploadColorsFilter = {
  /** Filter uploads that have all of the specified colors */
  allIn?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that have at least one of the specified colors */
  anyIn?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that have the specified colors */
  contains?: InputMaybe<ColorBucketType>;
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that do not have any of the specified colors */
  notIn?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
};

/** Specifies how to filter by copyright */
export type UploadCopyrightFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by creation datetime */
export type UploadCreatedAtFilter = {
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's less than the one specified */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Exclude uploads with an exact match */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Specifies how to filter by filename */
export type UploadFilenameFilter = {
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type UploadFilter = {
  AND?: InputMaybe<Array<InputMaybe<UploadFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<UploadFilter>>>;
  _createdAt?: InputMaybe<UploadCreatedAtFilter>;
  _updatedAt?: InputMaybe<UploadUpdatedAtFilter>;
  alt?: InputMaybe<UploadAltFilter>;
  author?: InputMaybe<UploadAuthorFilter>;
  basename?: InputMaybe<UploadBasenameFilter>;
  colors?: InputMaybe<UploadColorsFilter>;
  copyright?: InputMaybe<UploadCopyrightFilter>;
  filename?: InputMaybe<UploadFilenameFilter>;
  format?: InputMaybe<UploadFormatFilter>;
  height?: InputMaybe<UploadHeightFilter>;
  id?: InputMaybe<UploadIdFilter>;
  inUse?: InputMaybe<InUseFilter>;
  md5?: InputMaybe<UploadMd5Filter>;
  mimeType?: InputMaybe<UploadMimeTypeFilter>;
  notes?: InputMaybe<UploadNotesFilter>;
  orientation?: InputMaybe<OrientationFilter>;
  resolution?: InputMaybe<ResolutionFilter>;
  size?: InputMaybe<UploadSizeFilter>;
  smartTags?: InputMaybe<UploadTagsFilter>;
  tags?: InputMaybe<UploadTagsFilter>;
  title?: InputMaybe<UploadTitleFilter>;
  type?: InputMaybe<TypeFilter>;
  width?: InputMaybe<UploadWidthFilter>;
};

/** Specifies how to filter by format */
export type UploadFormatFilter = {
  /** Search the asset with the specified format */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets with the specified formats */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude the asset with the specified format */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets that do not have the specified formats */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Specifies how to filter by height */
export type UploadHeightFilter = {
  /** Search assets with the specified height */
  eq?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger than the specified height */
  gt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified height */
  gte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets smaller than the specified height */
  lt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified height */
  lte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search assets that do not have the specified height */
  neq?: InputMaybe<Scalars['IntType']['input']>;
};

/** Specifies how to filter by ID */
export type UploadIdFilter = {
  /** Search the asset with the specified ID */
  eq?: InputMaybe<Scalars['UploadId']['input']>;
  /** Search assets with the specified IDs */
  in?: InputMaybe<Array<InputMaybe<Scalars['UploadId']['input']>>>;
  /** Exclude the asset with the specified ID */
  neq?: InputMaybe<Scalars['UploadId']['input']>;
  /** Search assets that do not have the specified IDs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['UploadId']['input']>>>;
};

/** Specifies how to filter by MD5 */
export type UploadMd5Filter = {
  /** Search the asset with the specified MD5 */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets with the specified MD5s */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude the asset with the specified MD5 */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets that do not have the specified MD5s */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Specifies how to filter by mime type */
export type UploadMimeTypeFilter = {
  /** Search the asset with the specified mime type */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets with the specified mime types */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude the asset with the specified mime type */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets that do not have the specified mime types */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by notes */
export type UploadNotesFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export enum UploadOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  BasenameAsc = 'basename_ASC',
  BasenameDesc = 'basename_DESC',
  FilenameAsc = 'filename_ASC',
  FilenameDesc = 'filename_DESC',
  FormatAsc = 'format_ASC',
  FormatDesc = 'format_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MimeTypeAsc = 'mimeType_ASC',
  MimeTypeDesc = 'mimeType_DESC',
  ResolutionAsc = 'resolution_ASC',
  ResolutionDesc = 'resolution_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC'
}

export enum UploadOrientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
  Square = 'square'
}

/** Specifies how to filter by size */
export type UploadSizeFilter = {
  /** Search assets with the specified size (in bytes) */
  eq?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger than the specified size (in bytes) */
  gt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified size (in bytes) */
  gte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets smaller than the specified size (in bytes) */
  lt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified size (in bytes) */
  lte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search assets that do not have the specified size (in bytes) */
  neq?: InputMaybe<Scalars['IntType']['input']>;
};

/** Specifies how to filter by tags */
export type UploadTagsFilter = {
  /** Filter uploads linked to all of the specified tags */
  allIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter uploads linked to at least one of the specified tags */
  anyIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter uploads linked to the specified tag */
  contains?: InputMaybe<Scalars['String']['input']>;
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter uploads not linked to any of the specified tags */
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Specifies how to filter by default title */
export type UploadTitleFilter = {
  /** Search the asset with the specified title */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Filter assets with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Search assets with the specified as default title */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude the asset with the specified title */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets that do not have the specified as default title */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export enum UploadType {
  Archive = 'archive',
  Audio = 'audio',
  Image = 'image',
  Pdfdocument = 'pdfdocument',
  Presentation = 'presentation',
  Richtext = 'richtext',
  Spreadsheet = 'spreadsheet',
  Video = 'video'
}

/** Specifies how to filter by update datetime */
export type UploadUpdatedAtFilter = {
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's less than the one specified */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Exclude uploads with an exact match */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UploadVideoField = {
  __typename: 'UploadVideoField';
  alt?: Maybe<Scalars['String']['output']>;
  blurUpThumb?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  framerate?: Maybe<Scalars['Int']['output']>;
  height: Scalars['IntType']['output'];
  mp4Url?: Maybe<Scalars['String']['output']>;
  muxAssetId: Scalars['String']['output'];
  muxPlaybackId: Scalars['String']['output'];
  streamingUrl: Scalars['String']['output'];
  thumbhash?: Maybe<Scalars['String']['output']>;
  thumbnailUrl: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  width: Scalars['IntType']['output'];
};


export type UploadVideoFieldAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


export type UploadVideoFieldBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: Scalars['Float']['input'];
  quality?: Scalars['Int']['input'];
  size?: Scalars['Int']['input'];
};


export type UploadVideoFieldMp4UrlArgs = {
  exactRes?: InputMaybe<VideoMp4Res>;
  res?: InputMaybe<VideoMp4Res>;
};


export type UploadVideoFieldThumbnailUrlArgs = {
  format?: InputMaybe<MuxThumbnailFormatType>;
};


export type UploadVideoFieldTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by width */
export type UploadWidthFilter = {
  /** Search assets with the specified width */
  eq?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger than the specified width */
  gt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified width */
  gte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets smaller than the specified width */
  lt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified width */
  lte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search assets that do not have the specified width */
  neq?: InputMaybe<Scalars['IntType']['input']>;
};

export type UspModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<UspModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<UspModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _locales?: InputMaybe<LocalesFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  icon?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  link?: InputMaybe<LinkFilter>;
  optImage?: InputMaybe<FileFilter>;
  optLinkText?: InputMaybe<StringFilter>;
  optText?: InputMaybe<TextFilter>;
  position?: InputMaybe<PositionFilter>;
  shortText?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum UspModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OptLinkTextAsc = 'optLinkText_ASC',
  OptLinkTextDesc = 'optLinkText_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  ShortTextAsc = 'shortText_ASC',
  ShortTextDesc = 'shortText_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Record of type ⭐ USP (usp) */
export type UspRecord = RecordInterface & {
  __typename: 'UspRecord';
  _allLinkLocales?: Maybe<Array<PageRecordNonNullMultiLocaleField>>;
  _allOptLinkTextLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allOptTextLocales?: Maybe<Array<StringMultiLocaleField>>;
  _allShortTextLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _allTitleLocales?: Maybe<Array<StringNonNullMultiLocaleField>>;
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _locales: Array<SiteLocale>;
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['ItemId']['output'];
  link: PageRecord;
  optImage?: Maybe<ImageFileField>;
  optLinkText?: Maybe<Scalars['String']['output']>;
  optText?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['IntType']['output']>;
  shortText: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


/** Record of type ⭐ USP (usp) */
export type UspRecord_AllLinkLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type ⭐ USP (usp) */
export type UspRecord_AllOptLinkTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type ⭐ USP (usp) */
export type UspRecord_AllOptTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type ⭐ USP (usp) */
export type UspRecord_AllShortTextLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type ⭐ USP (usp) */
export type UspRecord_AllTitleLocalesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
};


/** Record of type ⭐ USP (usp) */
export type UspRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type ⭐ USP (usp) */
export type UspRecordLinkArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type ⭐ USP (usp) */
export type UspRecordOptLinkTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type ⭐ USP (usp) */
export type UspRecordOptTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Record of type ⭐ USP (usp) */
export type UspRecordShortTextArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};


/** Record of type ⭐ USP (usp) */
export type UspRecordTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type UspRecordListListNonNullMultiLocaleField = {
  __typename: 'UspRecordListListNonNullMultiLocaleField';
  locale?: Maybe<SiteLocale>;
  value: Array<UspRecord>;
};

/** Block of type ⭐ USP section (usp_section) */
export type UspSectionRecord = RecordInterface & {
  __typename: 'UspSectionRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  items: Array<UspRecord>;
  segments: Array<CustomerSegmentRecord>;
  title: Scalars['String']['output'];
};


/** Block of type ⭐ USP section (usp_section) */
export type UspSectionRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export enum VideoMp4Res {
  High = 'high',
  Low = 'low',
  Medium = 'medium'
}

export type FocalPoint = {
  __typename: 'focalPoint';
  x: Scalars['FloatType']['output'];
  y: Scalars['FloatType']['output'];
};

export type SearchContentQuery_allTrendsInspirations_TrendsInspirationRecord_sections_ContentSectionRecord_body_ContentSectionModelBodyField = { __typename: 'ContentSectionModelBodyField', value: unknown };

export type SearchContentQuery_allTrendsInspirations_TrendsInspirationRecord_sections_ContentSectionRecord = { __typename: 'ContentSectionRecord', body?: SearchContentQuery_allTrendsInspirations_TrendsInspirationRecord_sections_ContentSectionRecord_body_ContentSectionModelBodyField | null };

export type SearchContentQuery_allTrendsInspirations_TrendsInspirationRecord_sections_HeaderSectionRecord = { __typename: 'HeaderSectionRecord' };

export type SearchContentQuery_allTrendsInspirations_TrendsInspirationRecord_sections_LatestTrendsSectionRecord = { __typename: 'LatestTrendsSectionRecord' };

export type SearchContentQuery_allTrendsInspirations_TrendsInspirationRecord_sections_MagazineSectionRecord = { __typename: 'MagazineSectionRecord' };

export type SearchContentQuery_allTrendsInspirations_TrendsInspirationRecord_sections_NewsletterSubscriptionSectionRecord = { __typename: 'NewsletterSubscriptionSectionRecord' };

export type SearchContentQuery_allTrendsInspirations_TrendsInspirationRecord_sections_SplitSectionRecord = { __typename: 'SplitSectionRecord' };

export type SearchContentQuery_allTrendsInspirations_TrendsInspirationRecord_sections_UpcomingEventsSectionRecord = { __typename: 'UpcomingEventsSectionRecord' };

export type SearchContentQuery_allTrendsInspirations_TrendsInspirationRecord_sections = SearchContentQuery_allTrendsInspirations_TrendsInspirationRecord_sections_ContentSectionRecord | SearchContentQuery_allTrendsInspirations_TrendsInspirationRecord_sections_HeaderSectionRecord | SearchContentQuery_allTrendsInspirations_TrendsInspirationRecord_sections_LatestTrendsSectionRecord | SearchContentQuery_allTrendsInspirations_TrendsInspirationRecord_sections_MagazineSectionRecord | SearchContentQuery_allTrendsInspirations_TrendsInspirationRecord_sections_NewsletterSubscriptionSectionRecord | SearchContentQuery_allTrendsInspirations_TrendsInspirationRecord_sections_SplitSectionRecord | SearchContentQuery_allTrendsInspirations_TrendsInspirationRecord_sections_UpcomingEventsSectionRecord;

export type SearchContentQuery_allTrendsInspirations_TrendsInspirationRecord = { __typename: 'TrendsInspirationRecord', id: string, title: string, slug: string, sections: Array<SearchContentQuery_allTrendsInspirations_TrendsInspirationRecord_sections> };

export type SearchContentQuery_allNews_NewsRecord_sections_ContentSectionRecord_body_ContentSectionModelBodyField = { __typename: 'ContentSectionModelBodyField', value: unknown };

export type SearchContentQuery_allNews_NewsRecord_sections_ContentSectionRecord = { __typename: 'ContentSectionRecord', body?: SearchContentQuery_allNews_NewsRecord_sections_ContentSectionRecord_body_ContentSectionModelBodyField | null };

export type SearchContentQuery_allNews_NewsRecord_sections_HeaderSectionRecord = { __typename: 'HeaderSectionRecord' };

export type SearchContentQuery_allNews_NewsRecord_sections_NewsletterSubscriptionSectionRecord = { __typename: 'NewsletterSubscriptionSectionRecord' };

export type SearchContentQuery_allNews_NewsRecord_sections_SplitSectionRecord = { __typename: 'SplitSectionRecord' };

export type SearchContentQuery_allNews_NewsRecord_sections_UpcomingEventsSectionRecord = { __typename: 'UpcomingEventsSectionRecord' };

export type SearchContentQuery_allNews_NewsRecord_sections = SearchContentQuery_allNews_NewsRecord_sections_ContentSectionRecord | SearchContentQuery_allNews_NewsRecord_sections_HeaderSectionRecord | SearchContentQuery_allNews_NewsRecord_sections_NewsletterSubscriptionSectionRecord | SearchContentQuery_allNews_NewsRecord_sections_SplitSectionRecord | SearchContentQuery_allNews_NewsRecord_sections_UpcomingEventsSectionRecord;

export type SearchContentQuery_allNews_NewsRecord = { __typename: 'NewsRecord', id: string, title: string, slug: string, sections: Array<SearchContentQuery_allNews_NewsRecord_sections> };

export type SearchContentQuery_Query = { allTrendsInspirations: Array<SearchContentQuery_allTrendsInspirations_TrendsInspirationRecord>, allNews: Array<SearchContentQuery_allNews_NewsRecord> };


export type SearchContentQueryVariables = Exact<{
  locale?: InputMaybe<SiteLocale>;
  filterLocales?: InputMaybe<Array<SiteLocale>>;
}>;


export type SearchContentQuery = SearchContentQuery_Query;

export type EventsDetailQuery_event_EventRecord_sections_ContentSectionRecord = (
  { __typename: 'ContentSectionRecord' }
  & Custom_ContentSectionRecordFragment
);

export type EventsDetailQuery_event_EventRecord_sections_FormSectionRecord = (
  { __typename: 'FormSectionRecord' }
  & Custom_FormSectionRecordFragment
);

export type EventsDetailQuery_event_EventRecord_sections_HeaderSectionRecord = (
  { __typename: 'HeaderSectionRecord' }
  & Custom_HeaderSectionRecordFragment
);

export type EventsDetailQuery_event_EventRecord_sections_NewsletterSubscriptionSectionRecord = (
  { __typename: 'NewsletterSubscriptionSectionRecord' }
  & Custom_NewsletterSubscriptionSectionRecordFragment
);

export type EventsDetailQuery_event_EventRecord_sections_UpcomingEventsSectionRecord = (
  { __typename: 'UpcomingEventsSectionRecord' }
  & Custom_UpcomingEventsSectionRecordFragment
);

export type EventsDetailQuery_event_EventRecord_sections = EventsDetailQuery_event_EventRecord_sections_ContentSectionRecord | EventsDetailQuery_event_EventRecord_sections_FormSectionRecord | EventsDetailQuery_event_EventRecord_sections_HeaderSectionRecord | EventsDetailQuery_event_EventRecord_sections_NewsletterSubscriptionSectionRecord | EventsDetailQuery_event_EventRecord_sections_UpcomingEventsSectionRecord;

export type EventsDetailQuery_event_EventRecord__seoMetaTags_Tag = { __typename: 'Tag', content?: string | null, tag: string, attributes?: Record<string, string> | null };

export type EventsDetailQuery_event_EventRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type EventsDetailQuery_event_EventRecord = { __typename: 'EventRecord', id: string, title: string, teaserText: string, sections: Array<EventsDetailQuery_event_EventRecord_sections>, _seoMetaTags: Array<EventsDetailQuery_event_EventRecord__seoMetaTags_Tag>, _allSlugLocales?: Array<EventsDetailQuery_event_EventRecord__allSlugLocales_StringNonNullMultiLocaleField> | null };

export type EventsDetailQuery_Query = { event?: EventsDetailQuery_event_EventRecord | null };


export type EventsDetailQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<SiteLocale>;
}>;


export type EventsDetailQuery = EventsDetailQuery_Query;

export type EventsQuery_allEvents_EventRecord_teaserImage_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImage_WithoutPlaceholderFragment
);

export type EventsQuery_allEvents_EventRecord_teaserImage_ImageFileField = { __typename: 'ImageFileField', responsiveImage: EventsQuery_allEvents_EventRecord_teaserImage_ImageFileField_responsiveImage_ResponsiveImage };

export type EventsQuery_allEvents_EventRecord = { __typename: 'EventRecord', id: string, title: string, startDate: string, endDate?: string | null, teaserText: string, teaserLinkText: string, slug: string, teaserImage: EventsQuery_allEvents_EventRecord_teaserImage_ImageFileField };

export type EventsQuery__allEventsMeta_CollectionMetadata = { __typename: 'CollectionMetadata', count: number };

export type EventsQuery_Query = { allEvents: Array<EventsQuery_allEvents_EventRecord>, _allEventsMeta: EventsQuery__allEventsMeta_CollectionMetadata };


export type EventsQueryVariables = Exact<{
  locale?: InputMaybe<SiteLocale>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
  filterLocales?: InputMaybe<Array<SiteLocale>>;
  filterEndDate?: InputMaybe<Scalars['Date']['input']>;
}>;


export type EventsQuery = EventsQuery_Query;

export type LayoutQuery_layout_LayoutRecord_topNavigation_PageRecord = { __typename: 'PageRecord', title: string, slug: string, id: string };

export type LayoutQuery_layout_LayoutRecord_mainNavigation_PageRecord = { __typename: 'PageRecord', slug: string, title: string, id: string };

export type LayoutQuery_layout_LayoutRecord_headerButtonLink_PageRecord = { __typename: 'PageRecord', slug: string };

export type LayoutQuery_layout_LayoutRecord_usps_UspRecord_link_PageRecord = { __typename: 'PageRecord', slug: string };

export type LayoutQuery_layout_LayoutRecord_usps_UspRecord = { __typename: 'UspRecord', id: string, title: string, shortText: string, icon: string, link: LayoutQuery_layout_LayoutRecord_usps_UspRecord_link_PageRecord };

export type LayoutQuery_layout_LayoutRecord_socialMediaLinks_SocialMediaIconRecord = { __typename: 'SocialMediaIconRecord', id: string, name: string, url: string, iconName?: string | null };

export type LayoutQuery_layout_LayoutRecord_newsletterSubscriptionForm_NewsletterSubscriptionRecord = { __typename: 'NewsletterSubscriptionRecord', id: string, title: string, text: string, buttonLabel: string, inputPlaceholder: string, remoteFormUrl: string };

export type LayoutQuery_layout_LayoutRecord_footerLinks_LegalPageRecord = { __typename: 'LegalPageRecord', id: string, title: string, slug: string };

export type LayoutQuery_layout_LayoutRecord_footerColumns_FooterColumnRecord_links_PageLinkRecord_pageLink_PageRecord = { __typename: 'PageRecord', title: string, slug: string, id: string };

export type LayoutQuery_layout_LayoutRecord_footerColumns_FooterColumnRecord_links_CustomLinkRecord = { __typename: 'CustomLinkRecord', _modelApiKey: string, id: string, customLink: string, text: string };

export type LayoutQuery_layout_LayoutRecord_footerColumns_FooterColumnRecord_links_PageLinkRecord = { __typename: 'PageLinkRecord', _modelApiKey: string, id: string, text: string, pageLink: LayoutQuery_layout_LayoutRecord_footerColumns_FooterColumnRecord_links_PageLinkRecord_pageLink_PageRecord };

export type LayoutQuery_layout_LayoutRecord_footerColumns_FooterColumnRecord_links = LayoutQuery_layout_LayoutRecord_footerColumns_FooterColumnRecord_links_CustomLinkRecord | LayoutQuery_layout_LayoutRecord_footerColumns_FooterColumnRecord_links_PageLinkRecord;

export type LayoutQuery_layout_LayoutRecord_footerColumns_FooterColumnRecord = { __typename: 'FooterColumnRecord', id: string, title: string, links: Array<LayoutQuery_layout_LayoutRecord_footerColumns_FooterColumnRecord_links> };

export type LayoutQuery_layout_LayoutRecord_parentPageEvents_PageRecord = { __typename: 'PageRecord', title: string, slug: string };

export type LayoutQuery_layout_LayoutRecord_parentPageNews_PageRecord = { __typename: 'PageRecord', title: string, slug: string };

export type LayoutQuery_layout_LayoutRecord_parentPageStores_PageRecord = { __typename: 'PageRecord', title: string, slug: string };

export type LayoutQuery_layout_LayoutRecord_parentPageTrends_PageRecord = { __typename: 'PageRecord', title: string, slug: string };

export type LayoutQuery_layout_LayoutRecord = { __typename: 'LayoutRecord', headerButtonText: string, webshopButtonText: string, footerSubtitle?: string | null, topNavigation: Array<LayoutQuery_layout_LayoutRecord_topNavigation_PageRecord>, mainNavigation: Array<LayoutQuery_layout_LayoutRecord_mainNavigation_PageRecord>, headerButtonLink: LayoutQuery_layout_LayoutRecord_headerButtonLink_PageRecord, usps: Array<LayoutQuery_layout_LayoutRecord_usps_UspRecord>, socialMediaLinks: Array<LayoutQuery_layout_LayoutRecord_socialMediaLinks_SocialMediaIconRecord>, newsletterSubscriptionForm: LayoutQuery_layout_LayoutRecord_newsletterSubscriptionForm_NewsletterSubscriptionRecord, footerLinks: Array<LayoutQuery_layout_LayoutRecord_footerLinks_LegalPageRecord>, footerColumns: Array<LayoutQuery_layout_LayoutRecord_footerColumns_FooterColumnRecord>, parentPageEvents?: LayoutQuery_layout_LayoutRecord_parentPageEvents_PageRecord | null, parentPageNews?: LayoutQuery_layout_LayoutRecord_parentPageNews_PageRecord | null, parentPageStores?: LayoutQuery_layout_LayoutRecord_parentPageStores_PageRecord | null, parentPageTrends?: LayoutQuery_layout_LayoutRecord_parentPageTrends_PageRecord | null };

export type LayoutQuery_Query = { layout?: LayoutQuery_layout_LayoutRecord | null };


export type LayoutQueryVariables = Exact<{
  locale?: InputMaybe<SiteLocale>;
}>;


export type LayoutQuery = LayoutQuery_Query;

export type LegalQuery_legalPage_LegalPageRecord__seoMetaTags_Tag = { __typename: 'Tag', attributes?: Record<string, string> | null, content?: string | null, tag: string };

export type LegalQuery_legalPage_LegalPageRecord_content_LegalPageModelContentField = { __typename: 'LegalPageModelContentField', blocks: Array<string>, links: Array<string>, value: unknown };

export type LegalQuery_legalPage_LegalPageRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type LegalQuery_legalPage_LegalPageRecord = { __typename: 'LegalPageRecord', id: string, slug: string, title: string, _seoMetaTags: Array<LegalQuery_legalPage_LegalPageRecord__seoMetaTags_Tag>, content: LegalQuery_legalPage_LegalPageRecord_content_LegalPageModelContentField, _allSlugLocales?: Array<LegalQuery_legalPage_LegalPageRecord__allSlugLocales_StringNonNullMultiLocaleField> | null };

export type LegalQuery_Query = { legalPage?: LegalQuery_legalPage_LegalPageRecord | null };


export type LegalQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<SiteLocale>;
}>;


export type LegalQuery = LegalQuery_Query;

export type NewsDetailQuery_news_NewsRecord_sections_ContentSectionRecord = (
  { __typename: 'ContentSectionRecord' }
  & Custom_ContentSectionRecordFragment
);

export type NewsDetailQuery_news_NewsRecord_sections_HeaderSectionRecord = (
  { __typename: 'HeaderSectionRecord' }
  & Custom_HeaderSectionRecordFragment
);

export type NewsDetailQuery_news_NewsRecord_sections_NewsletterSubscriptionSectionRecord = (
  { __typename: 'NewsletterSubscriptionSectionRecord' }
  & Custom_NewsletterSubscriptionSectionRecordFragment
);

export type NewsDetailQuery_news_NewsRecord_sections_SplitSectionRecord = (
  { __typename: 'SplitSectionRecord' }
  & Custom_SplitSectionRecordFragment
);

export type NewsDetailQuery_news_NewsRecord_sections_UpcomingEventsSectionRecord = (
  { __typename: 'UpcomingEventsSectionRecord' }
  & Custom_UpcomingEventsSectionRecordFragment
);

export type NewsDetailQuery_news_NewsRecord_sections = NewsDetailQuery_news_NewsRecord_sections_ContentSectionRecord | NewsDetailQuery_news_NewsRecord_sections_HeaderSectionRecord | NewsDetailQuery_news_NewsRecord_sections_NewsletterSubscriptionSectionRecord | NewsDetailQuery_news_NewsRecord_sections_SplitSectionRecord | NewsDetailQuery_news_NewsRecord_sections_UpcomingEventsSectionRecord;

export type NewsDetailQuery_news_NewsRecord__seoMetaTags_Tag = { __typename: 'Tag', content?: string | null, tag: string, attributes?: Record<string, string> | null };

export type NewsDetailQuery_news_NewsRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type NewsDetailQuery_news_NewsRecord = { __typename: 'NewsRecord', id: string, title: string, _publishedAt?: string | null, sections: Array<NewsDetailQuery_news_NewsRecord_sections>, _seoMetaTags: Array<NewsDetailQuery_news_NewsRecord__seoMetaTags_Tag>, _allSlugLocales?: Array<NewsDetailQuery_news_NewsRecord__allSlugLocales_StringNonNullMultiLocaleField> | null };

export type NewsDetailQuery_Query = { news?: NewsDetailQuery_news_NewsRecord | null };


export type NewsDetailQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<SiteLocale>;
}>;


export type NewsDetailQuery = NewsDetailQuery_Query;

export type NewsQuery_allNews_NewsRecord_teaserImage_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type NewsQuery_allNews_NewsRecord_teaserImage_ImageFileField = { __typename: 'ImageFileField', responsiveImage: NewsQuery_allNews_NewsRecord_teaserImage_ImageFileField_responsiveImage_ResponsiveImage };

export type NewsQuery_allNews_NewsRecord = { __typename: 'NewsRecord', id: string, title: string, teaserText: string, slug: string, teaserImage: NewsQuery_allNews_NewsRecord_teaserImage_ImageFileField };

export type NewsQuery__allNewsMeta_CollectionMetadata = { __typename: 'CollectionMetadata', count: number };

export type NewsQuery_Query = { allNews: Array<NewsQuery_allNews_NewsRecord>, _allNewsMeta: NewsQuery__allNewsMeta_CollectionMetadata };


export type NewsQueryVariables = Exact<{
  locale?: InputMaybe<SiteLocale>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
  filterLocales?: InputMaybe<Array<SiteLocale>>;
}>;


export type NewsQuery = NewsQuery_Query;

export type PageQuery_page_PageRecord_sections_AllEventsSectionRecord = (
  { __typename: 'AllEventsSectionRecord' }
  & Custom_AllEventsSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_AllNewsSectionRecord = { __typename: 'AllNewsSectionRecord' };

export type PageQuery_page_PageRecord_sections_AllStoresSectionRecord = { __typename: 'AllStoresSectionRecord' };

export type PageQuery_page_PageRecord_sections_AllTrendsSectionRecord = { __typename: 'AllTrendsSectionRecord' };

export type PageQuery_page_PageRecord_sections_BrandsSectionRecord = (
  { __typename: 'BrandsSectionRecord' }
  & Custom_BrandsSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_CallToActionSectionRecord = (
  { __typename: 'CallToActionSectionRecord' }
  & Custom_CallToActionSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_ContentGridSectionRecord = { __typename: 'ContentGridSectionRecord' };

export type PageQuery_page_PageRecord_sections_ContentSectionRecord = (
  { __typename: 'ContentSectionRecord' }
  & Custom_ContentSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_CustomerCtaSectionRecord = { __typename: 'CustomerCtaSectionRecord' };

export type PageQuery_page_PageRecord_sections_FaqSectionRecord = (
  { __typename: 'FaqSectionRecord' }
  & Custom_FaqSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_FormSectionRecord = (
  { __typename: 'FormSectionRecord' }
  & Custom_FormSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_HeaderSectionRecord = (
  { __typename: 'HeaderSectionRecord' }
  & Custom_HeaderSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_HomepageHeroSectionRecord = (
  { __typename: 'HomepageHeroSectionRecord' }
  & Custom_HomepageHeroSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_LatestTrendsSectionRecord = (
  { __typename: 'LatestTrendsSectionRecord' }
  & Custom_LatestTrendsSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_MagazineSectionRecord = (
  { __typename: 'MagazineSectionRecord' }
  & Custom_MagazineSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_NewsletterSubscriptionSectionRecord = (
  { __typename: 'NewsletterSubscriptionSectionRecord' }
  & Custom_NewsletterSubscriptionSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_ProductGroupDetailSectionRecord = (
  { __typename: 'ProductGroupDetailSectionRecord' }
  & Custom_ProductGroupDetailSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_ProductGroupOverviewSectionRecord = (
  { __typename: 'ProductGroupOverviewSectionRecord' }
  & Custom_ProductGroupOverviewSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_SearchSectionRecord = { __typename: 'SearchSectionRecord' };

export type PageQuery_page_PageRecord_sections_SplitSectionRecord = (
  { __typename: 'SplitSectionRecord' }
  & Custom_SplitSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_StepsSectionRecord = (
  { __typename: 'StepsSectionRecord' }
  & Custom_StepsSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_TestimonialsSectionRecord = (
  { __typename: 'TestimonialsSectionRecord' }
  & Custom_TestimonialsSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_UpcomingEventsSectionRecord = (
  { __typename: 'UpcomingEventsSectionRecord' }
  & Custom_UpcomingEventsSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections_UspSectionRecord = (
  { __typename: 'UspSectionRecord' }
  & Custom_UspSectionRecordFragment
);

export type PageQuery_page_PageRecord_sections = PageQuery_page_PageRecord_sections_AllEventsSectionRecord | PageQuery_page_PageRecord_sections_AllNewsSectionRecord | PageQuery_page_PageRecord_sections_AllStoresSectionRecord | PageQuery_page_PageRecord_sections_AllTrendsSectionRecord | PageQuery_page_PageRecord_sections_BrandsSectionRecord | PageQuery_page_PageRecord_sections_CallToActionSectionRecord | PageQuery_page_PageRecord_sections_ContentGridSectionRecord | PageQuery_page_PageRecord_sections_ContentSectionRecord | PageQuery_page_PageRecord_sections_CustomerCtaSectionRecord | PageQuery_page_PageRecord_sections_FaqSectionRecord | PageQuery_page_PageRecord_sections_FormSectionRecord | PageQuery_page_PageRecord_sections_HeaderSectionRecord | PageQuery_page_PageRecord_sections_HomepageHeroSectionRecord | PageQuery_page_PageRecord_sections_LatestTrendsSectionRecord | PageQuery_page_PageRecord_sections_MagazineSectionRecord | PageQuery_page_PageRecord_sections_NewsletterSubscriptionSectionRecord | PageQuery_page_PageRecord_sections_ProductGroupDetailSectionRecord | PageQuery_page_PageRecord_sections_ProductGroupOverviewSectionRecord | PageQuery_page_PageRecord_sections_SearchSectionRecord | PageQuery_page_PageRecord_sections_SplitSectionRecord | PageQuery_page_PageRecord_sections_StepsSectionRecord | PageQuery_page_PageRecord_sections_TestimonialsSectionRecord | PageQuery_page_PageRecord_sections_UpcomingEventsSectionRecord | PageQuery_page_PageRecord_sections_UspSectionRecord;

export type PageQuery_page_PageRecord_parentPage_PageRecord = { __typename: 'PageRecord', id: string, title: string, slug: string };

export type PageQuery_page_PageRecord__seoMetaTags_Tag = { __typename: 'Tag', content?: string | null, tag: string, attributes?: Record<string, string> | null };

export type PageQuery_page_PageRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type PageQuery_page_PageRecord = { __typename: 'PageRecord', id: string, title: string, sections: Array<PageQuery_page_PageRecord_sections>, parentPage?: PageQuery_page_PageRecord_parentPage_PageRecord | null, _seoMetaTags: Array<PageQuery_page_PageRecord__seoMetaTags_Tag>, _allSlugLocales?: Array<PageQuery_page_PageRecord__allSlugLocales_StringNonNullMultiLocaleField> | null };

export type PageQuery_Query = { page?: PageQuery_page_PageRecord | null };


export type PageQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<SiteLocale>;
}>;


export type PageQuery = PageQuery_Query;

export type StoresDetailQuery_store_StoreRecord_location_LatLonField = { __typename: 'LatLonField', latitude: number, longitude: number };

export type StoresDetailQuery_store_StoreRecord_openingHours_HourRecord = { __typename: 'HourRecord', id: string, dayOfWeek: string, startHour?: string | null, endHour?: string | null };

export type StoresDetailQuery_store_StoreRecord_sections_HeaderSectionRecord = (
  { __typename: 'HeaderSectionRecord' }
  & Custom_HeaderSectionRecordFragment
);

export type StoresDetailQuery_store_StoreRecord_sections_MagazineSectionRecord = (
  { __typename: 'MagazineSectionRecord' }
  & Custom_MagazineSectionRecordFragment
);

export type StoresDetailQuery_store_StoreRecord_sections_NewsletterSubscriptionSectionRecord = (
  { __typename: 'NewsletterSubscriptionSectionRecord' }
  & Custom_NewsletterSubscriptionSectionRecordFragment
);

export type StoresDetailQuery_store_StoreRecord_sections = StoresDetailQuery_store_StoreRecord_sections_HeaderSectionRecord | StoresDetailQuery_store_StoreRecord_sections_MagazineSectionRecord | StoresDetailQuery_store_StoreRecord_sections_NewsletterSubscriptionSectionRecord;

export type StoresDetailQuery_store_StoreRecord__seoMetaTags_Tag = { __typename: 'Tag', content?: string | null, tag: string, attributes?: Record<string, string> | null };

export type StoresDetailQuery_store_StoreRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type StoresDetailQuery_store_StoreRecord = { __typename: 'StoreRecord', id: string, name: string, address: string, optPhoneNumber?: string | null, optFaxNumber?: string | null, optEmailAddress?: string | null, location?: StoresDetailQuery_store_StoreRecord_location_LatLonField | null, openingHours: Array<StoresDetailQuery_store_StoreRecord_openingHours_HourRecord>, sections: Array<StoresDetailQuery_store_StoreRecord_sections>, _seoMetaTags: Array<StoresDetailQuery_store_StoreRecord__seoMetaTags_Tag>, _allSlugLocales?: Array<StoresDetailQuery_store_StoreRecord__allSlugLocales_StringNonNullMultiLocaleField> | null };

export type StoresDetailQuery_Query = { store?: StoresDetailQuery_store_StoreRecord | null };


export type StoresDetailQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<SiteLocale>;
}>;


export type StoresDetailQuery = StoresDetailQuery_Query;

export type StoresQuery_allStores_StoreRecord_location_LatLonField = { __typename: 'LatLonField', latitude: number, longitude: number };

export type StoresQuery_allStores_StoreRecord_openingHours_HourRecord = { __typename: 'HourRecord', id: string, dayOfWeek: string, startHour?: string | null, endHour?: string | null };

export type StoresQuery_allStores_StoreRecord_teaserImage_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type StoresQuery_allStores_StoreRecord_teaserImage_ImageFileField = { __typename: 'ImageFileField', responsiveImage: StoresQuery_allStores_StoreRecord_teaserImage_ImageFileField_responsiveImage_ResponsiveImage };

export type StoresQuery_allStores_StoreRecord = { __typename: 'StoreRecord', id: string, name: string, address: string, optPhoneNumber?: string | null, optFaxNumber?: string | null, optEmailAddress?: string | null, teaserLinkText: string, slug: string, location?: StoresQuery_allStores_StoreRecord_location_LatLonField | null, openingHours: Array<StoresQuery_allStores_StoreRecord_openingHours_HourRecord>, teaserImage: StoresQuery_allStores_StoreRecord_teaserImage_ImageFileField };

export type StoresQuery__allStoresMeta_CollectionMetadata = { __typename: 'CollectionMetadata', count: number };

export type StoresQuery_Query = { allStores: Array<StoresQuery_allStores_StoreRecord>, _allStoresMeta: StoresQuery__allStoresMeta_CollectionMetadata };


export type StoresQueryVariables = Exact<{
  locale?: InputMaybe<SiteLocale>;
  filterLocales?: InputMaybe<Array<SiteLocale>>;
}>;


export type StoresQuery = StoresQuery_Query;

export type TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord_tags_TrendsInspirationTagRecord = { __typename: 'TrendsInspirationTagRecord', id: string, slug: string, tag: string };

export type TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord_sections_ContentSectionRecord = (
  { __typename: 'ContentSectionRecord' }
  & Custom_ContentSectionRecordFragment
);

export type TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord_sections_HeaderSectionRecord = (
  { __typename: 'HeaderSectionRecord' }
  & Custom_HeaderSectionRecordFragment
);

export type TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord_sections_LatestTrendsSectionRecord = (
  { __typename: 'LatestTrendsSectionRecord' }
  & Custom_LatestTrendsSectionRecordFragment
);

export type TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord_sections_MagazineSectionRecord = (
  { __typename: 'MagazineSectionRecord' }
  & Custom_MagazineSectionRecordFragment
);

export type TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord_sections_NewsletterSubscriptionSectionRecord = (
  { __typename: 'NewsletterSubscriptionSectionRecord' }
  & Custom_NewsletterSubscriptionSectionRecordFragment
);

export type TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord_sections_SplitSectionRecord = (
  { __typename: 'SplitSectionRecord' }
  & Custom_SplitSectionRecordFragment
);

export type TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord_sections_UpcomingEventsSectionRecord = (
  { __typename: 'UpcomingEventsSectionRecord' }
  & Custom_UpcomingEventsSectionRecordFragment
);

export type TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord_sections = TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord_sections_ContentSectionRecord | TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord_sections_HeaderSectionRecord | TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord_sections_LatestTrendsSectionRecord | TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord_sections_MagazineSectionRecord | TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord_sections_NewsletterSubscriptionSectionRecord | TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord_sections_SplitSectionRecord | TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord_sections_UpcomingEventsSectionRecord;

export type TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord__seoMetaTags_Tag = { __typename: 'Tag', content?: string | null, tag: string, attributes?: Record<string, string> | null };

export type TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord = { __typename: 'TrendsInspirationRecord', id: string, title: string, _publishedAt?: string | null, tags: Array<TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord_tags_TrendsInspirationTagRecord>, sections: Array<TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord_sections>, _seoMetaTags: Array<TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord__seoMetaTags_Tag>, _allSlugLocales?: Array<TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord__allSlugLocales_StringNonNullMultiLocaleField> | null };

export type TrendsDetailQuery_Query = { trendsInspiration?: TrendsDetailQuery_trendsInspiration_TrendsInspirationRecord | null };


export type TrendsDetailQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<SiteLocale>;
}>;


export type TrendsDetailQuery = TrendsDetailQuery_Query;

export type TrendsTagsQuery_allTrendsInspirationTags_TrendsInspirationTagRecord = { __typename: 'TrendsInspirationTagRecord', id: string, slug: string, tag: string };

export type TrendsTagsQuery_Query = { allTrendsInspirationTags: Array<TrendsTagsQuery_allTrendsInspirationTags_TrendsInspirationTagRecord> };


export type TrendsTagsQueryVariables = Exact<{
  locale?: InputMaybe<SiteLocale>;
}>;


export type TrendsTagsQuery = TrendsTagsQuery_Query;

export type TrendsQuery_allTrendsInspirations_TrendsInspirationRecord_tags_TrendsInspirationTagRecord = { __typename: 'TrendsInspirationTagRecord', id: string, slug: string, tag: string };

export type TrendsQuery_allTrendsInspirations_TrendsInspirationRecord_teaserImage_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type TrendsQuery_allTrendsInspirations_TrendsInspirationRecord_teaserImage_ImageFileField = { __typename: 'ImageFileField', responsiveImage: TrendsQuery_allTrendsInspirations_TrendsInspirationRecord_teaserImage_ImageFileField_responsiveImage_ResponsiveImage };

export type TrendsQuery_allTrendsInspirations_TrendsInspirationRecord = { __typename: 'TrendsInspirationRecord', id: string, title: string, teaserText: string, slug: string, tags: Array<TrendsQuery_allTrendsInspirations_TrendsInspirationRecord_tags_TrendsInspirationTagRecord>, teaserImage: TrendsQuery_allTrendsInspirations_TrendsInspirationRecord_teaserImage_ImageFileField };

export type TrendsQuery__allTrendsInspirationsMeta_CollectionMetadata = { __typename: 'CollectionMetadata', count: number };

export type TrendsQuery_Query = { allTrendsInspirations: Array<TrendsQuery_allTrendsInspirations_TrendsInspirationRecord>, _allTrendsInspirationsMeta: TrendsQuery__allTrendsInspirationsMeta_CollectionMetadata };


export type TrendsQueryVariables = Exact<{
  locale?: InputMaybe<SiteLocale>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
  filterLocales?: InputMaybe<Array<SiteLocale>>;
  filterTags?: InputMaybe<Array<InputMaybe<Scalars['ItemId']['input']>>>;
}>;


export type TrendsQuery = TrendsQuery_Query;

export type SitemapQuery_allPages_PageRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type SitemapQuery_allPages_PageRecord_seoMeta_SeoField = { __typename: 'SeoField', noIndex?: boolean | null };

export type SitemapQuery_allPages_PageRecord = { __typename: 'PageRecord', _publishedAt?: string | null, _allSlugLocales?: Array<SitemapQuery_allPages_PageRecord__allSlugLocales_StringNonNullMultiLocaleField> | null, seoMeta?: SitemapQuery_allPages_PageRecord_seoMeta_SeoField | null };

export type SitemapQuery_allEvents_EventRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type SitemapQuery_allEvents_EventRecord_seoMeta_SeoField = { __typename: 'SeoField', noIndex?: boolean | null };

export type SitemapQuery_allEvents_EventRecord = { __typename: 'EventRecord', _publishedAt?: string | null, _allSlugLocales?: Array<SitemapQuery_allEvents_EventRecord__allSlugLocales_StringNonNullMultiLocaleField> | null, seoMeta?: SitemapQuery_allEvents_EventRecord_seoMeta_SeoField | null };

export type SitemapQuery_allNews_NewsRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type SitemapQuery_allNews_NewsRecord_seoMeta_SeoField = { __typename: 'SeoField', noIndex?: boolean | null };

export type SitemapQuery_allNews_NewsRecord = { __typename: 'NewsRecord', _publishedAt?: string | null, _allSlugLocales?: Array<SitemapQuery_allNews_NewsRecord__allSlugLocales_StringNonNullMultiLocaleField> | null, seoMeta?: SitemapQuery_allNews_NewsRecord_seoMeta_SeoField | null };

export type SitemapQuery_allStores_StoreRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type SitemapQuery_allStores_StoreRecord_seoMeta_SeoField = { __typename: 'SeoField', noIndex?: boolean | null };

export type SitemapQuery_allStores_StoreRecord = { __typename: 'StoreRecord', _publishedAt?: string | null, _allSlugLocales?: Array<SitemapQuery_allStores_StoreRecord__allSlugLocales_StringNonNullMultiLocaleField> | null, seoMeta?: SitemapQuery_allStores_StoreRecord_seoMeta_SeoField | null };

export type SitemapQuery_allTrendsInspirations_TrendsInspirationRecord__allSlugLocales_StringNonNullMultiLocaleField = { __typename: 'StringNonNullMultiLocaleField', locale?: SiteLocale | null, value: string };

export type SitemapQuery_allTrendsInspirations_TrendsInspirationRecord_seoMeta_SeoField = { __typename: 'SeoField', noIndex?: boolean | null };

export type SitemapQuery_allTrendsInspirations_TrendsInspirationRecord = { __typename: 'TrendsInspirationRecord', _publishedAt?: string | null, _allSlugLocales?: Array<SitemapQuery_allTrendsInspirations_TrendsInspirationRecord__allSlugLocales_StringNonNullMultiLocaleField> | null, seoMeta?: SitemapQuery_allTrendsInspirations_TrendsInspirationRecord_seoMeta_SeoField | null };

export type SitemapQuery_Query = { allPages: Array<SitemapQuery_allPages_PageRecord>, allEvents: Array<SitemapQuery_allEvents_EventRecord>, allNews: Array<SitemapQuery_allNews_NewsRecord>, allStores: Array<SitemapQuery_allStores_StoreRecord>, allTrendsInspirations: Array<SitemapQuery_allTrendsInspirations_TrendsInspirationRecord> };


export type SitemapQueryVariables = Exact<{ [key: string]: never; }>;


export type SitemapQuery = SitemapQuery_Query;

export type Custom_AllEventsSectionRecordFragment = { __typename: 'AllEventsSectionRecord', id: string, title: string };

export type Custom_BrandsSectionRecordFragment_BrandsSectionRecord_items_BrandRecord_logo_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_BrandsSectionRecordFragment_BrandsSectionRecord_items_BrandRecord_logo_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_BrandsSectionRecordFragment_BrandsSectionRecord_items_BrandRecord_logo_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_BrandsSectionRecordFragment_BrandsSectionRecord_items_BrandRecord = { __typename: 'BrandRecord', id: string, name: string, optLinkUrl?: string | null, logo: Custom_BrandsSectionRecordFragment_BrandsSectionRecord_items_BrandRecord_logo_ImageFileField };

export type Custom_BrandsSectionRecordFragment = { __typename: 'BrandsSectionRecord', id: string, title: string, optIntro?: string | null, items: Array<Custom_BrandsSectionRecordFragment_BrandsSectionRecord_items_BrandRecord> };

export type Custom_CallToActionSectionRecordFragment_CallToActionSectionRecord_pageLink_PageLinkRecord_pageLink_PageRecord = { __typename: 'PageRecord', title: string, slug: string };

export type Custom_CallToActionSectionRecordFragment_CallToActionSectionRecord_pageLink_PageLinkRecord = { __typename: 'PageLinkRecord', text: string, pageLink: Custom_CallToActionSectionRecordFragment_CallToActionSectionRecord_pageLink_PageLinkRecord_pageLink_PageRecord };

export type Custom_CallToActionSectionRecordFragment_CallToActionSectionRecord_backgroundImage_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_CallToActionSectionRecordFragment_CallToActionSectionRecord_backgroundImage_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_CallToActionSectionRecordFragment_CallToActionSectionRecord_backgroundImage_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_CallToActionSectionRecordFragment = { __typename: 'CallToActionSectionRecord', id: string, superTitle: string, title: string, text: string, pageLink: Array<Custom_CallToActionSectionRecordFragment_CallToActionSectionRecord_pageLink_PageLinkRecord>, backgroundImage: Custom_CallToActionSectionRecordFragment_CallToActionSectionRecord_backgroundImage_ImageFileField };

export type Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_ImageBlockRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_ImageBlockRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_ImageBlockRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_ButtonRecord = { __typename: 'ButtonRecord', _modelApiKey: string, id: string, label: string, url: string, primary: boolean };

export type Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_ImageBlockRecord = { __typename: 'ImageBlockRecord', _modelApiKey: string, id: string, image: Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_ImageBlockRecord_image_ImageFileField };

export type Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_SplitItemRecord = (
  { __typename: 'SplitItemRecord' }
  & Custom_SplitItemRecordFragment
);

export type Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks = Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_ButtonRecord | Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_ImageBlockRecord | Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks_SplitItemRecord;

export type Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField = { __typename: 'ContentSectionModelBodyField', links: Array<string>, value: unknown, blocks: Array<Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField_blocks> };

export type Custom_ContentSectionRecordFragment = { __typename: 'ContentSectionRecord', id: string, body?: Custom_ContentSectionRecordFragment_ContentSectionRecord_body_ContentSectionModelBodyField | null };

export type Custom_CustomLinkRecordFragment = { __typename: 'CustomLinkRecord', _modelApiKey: string, id: string, text: string, customLink: string };

export type Custom_FaqSectionRecordFragment_FaqSectionRecord_items_FaqRecord = { __typename: 'FaqRecord', id: string, question: string, answer: string };

export type Custom_FaqSectionRecordFragment_FaqSectionRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_FaqSectionRecordFragment_FaqSectionRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_FaqSectionRecordFragment_FaqSectionRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_FaqSectionRecordFragment = { __typename: 'FaqSectionRecord', id: string, title: string, imagePosition: boolean, items: Array<Custom_FaqSectionRecordFragment_FaqSectionRecord_items_FaqRecord>, image: Custom_FaqSectionRecordFragment_FaqSectionRecord_image_ImageFileField };

export type Custom_FormSectionRecordFragment_FormSectionRecord_optImage_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_FormSectionRecordFragment_FormSectionRecord_optImage_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_FormSectionRecordFragment_FormSectionRecord_optImage_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_FormSectionRecordFragment = { __typename: 'FormSectionRecord', id: string, title: string, optIntro?: string | null, tallyFormId: string, optImage?: Custom_FormSectionRecordFragment_FormSectionRecord_optImage_ImageFileField | null };

export type Custom_HeaderSectionRecordFragment_HeaderSectionRecord_item_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_HeaderSectionRecordFragment_HeaderSectionRecord_item_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_HeaderSectionRecordFragment_HeaderSectionRecord_item_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_HeaderSectionRecordFragment = { __typename: 'HeaderSectionRecord', id: string, optIntro?: string | null, item: Custom_HeaderSectionRecordFragment_HeaderSectionRecord_item_ImageFileField };

export type Custom_HomepageHeroSectionRecordFragment_HomepageHeroSectionRecord_heroImage_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_HomepageHeroSectionRecordFragment_HomepageHeroSectionRecord_heroImage_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_HomepageHeroSectionRecordFragment_HomepageHeroSectionRecord_heroImage_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_HomepageHeroSectionRecordFragment_HomepageHeroSectionRecord_pageLink_PageLinkRecord_pageLink_PageRecord = { __typename: 'PageRecord', slug: string };

export type Custom_HomepageHeroSectionRecordFragment_HomepageHeroSectionRecord_pageLink_CustomLinkRecord = { __typename: 'CustomLinkRecord', _modelApiKey: string, id: string, text: string, customLink: string };

export type Custom_HomepageHeroSectionRecordFragment_HomepageHeroSectionRecord_pageLink_PageLinkRecord = { __typename: 'PageLinkRecord', _modelApiKey: string, id: string, text: string, pageLink: Custom_HomepageHeroSectionRecordFragment_HomepageHeroSectionRecord_pageLink_PageLinkRecord_pageLink_PageRecord };

export type Custom_HomepageHeroSectionRecordFragment_HomepageHeroSectionRecord_pageLink = Custom_HomepageHeroSectionRecordFragment_HomepageHeroSectionRecord_pageLink_CustomLinkRecord | Custom_HomepageHeroSectionRecordFragment_HomepageHeroSectionRecord_pageLink_PageLinkRecord;

export type Custom_HomepageHeroSectionRecordFragment_HomepageHeroSectionRecord_imageLinks_ImageLinkRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_HomepageHeroSectionRecordFragment_HomepageHeroSectionRecord_imageLinks_ImageLinkRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_HomepageHeroSectionRecordFragment_HomepageHeroSectionRecord_imageLinks_ImageLinkRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_HomepageHeroSectionRecordFragment_HomepageHeroSectionRecord_imageLinks_ImageLinkRecord_optPageLink_PageRecord = { __typename: 'PageRecord', slug: string };

export type Custom_HomepageHeroSectionRecordFragment_HomepageHeroSectionRecord_imageLinks_ImageLinkRecord = { __typename: 'ImageLinkRecord', title: string, optCustomLink?: string | null, image: Custom_HomepageHeroSectionRecordFragment_HomepageHeroSectionRecord_imageLinks_ImageLinkRecord_image_ImageFileField, optPageLink?: Custom_HomepageHeroSectionRecordFragment_HomepageHeroSectionRecord_imageLinks_ImageLinkRecord_optPageLink_PageRecord | null };

export type Custom_HomepageHeroSectionRecordFragment = { __typename: 'HomepageHeroSectionRecord', id: string, heroTitle: string, heroText: string, heroImage: Custom_HomepageHeroSectionRecordFragment_HomepageHeroSectionRecord_heroImage_ImageFileField, pageLink: Array<Custom_HomepageHeroSectionRecordFragment_HomepageHeroSectionRecord_pageLink>, imageLinks: Array<Custom_HomepageHeroSectionRecordFragment_HomepageHeroSectionRecord_imageLinks_ImageLinkRecord> };

export type Custom_LatestTrendsSectionRecordFragment_LatestTrendsSectionRecord_linkPage_PageRecord = { __typename: 'PageRecord', slug: string };

export type Custom_LatestTrendsSectionRecordFragment = { __typename: 'LatestTrendsSectionRecord', id: string, title: string, linkText: string, itemCount: number, linkPage: Custom_LatestTrendsSectionRecordFragment_LatestTrendsSectionRecord_linkPage_PageRecord };

export type Custom_MagazineSectionRecordFragment_MagazineSectionRecord_item_MagazineRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_MagazineSectionRecordFragment_MagazineSectionRecord_item_MagazineRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_MagazineSectionRecordFragment_MagazineSectionRecord_item_MagazineRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_MagazineSectionRecordFragment_MagazineSectionRecord_item_MagazineRecord = { __typename: 'MagazineRecord', title: string, optExtraTitle?: string | null, optText?: string | null, linkText: string, url: string, image: Custom_MagazineSectionRecordFragment_MagazineSectionRecord_item_MagazineRecord_image_ImageFileField };

export type Custom_MagazineSectionRecordFragment = { __typename: 'MagazineSectionRecord', id: string, item: Custom_MagazineSectionRecordFragment_MagazineSectionRecord_item_MagazineRecord };

export type Custom_NewsletterSubscriptionSectionRecordFragment_NewsletterSubscriptionSectionRecord_form_NewsletterSubscriptionRecord_optImage_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_NewsletterSubscriptionSectionRecordFragment_NewsletterSubscriptionSectionRecord_form_NewsletterSubscriptionRecord_optImage_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_NewsletterSubscriptionSectionRecordFragment_NewsletterSubscriptionSectionRecord_form_NewsletterSubscriptionRecord_optImage_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_NewsletterSubscriptionSectionRecordFragment_NewsletterSubscriptionSectionRecord_form_NewsletterSubscriptionRecord = { __typename: 'NewsletterSubscriptionRecord', id: string, title: string, text: string, buttonLabel: string, inputPlaceholder: string, remoteFormUrl: string, optImage?: Custom_NewsletterSubscriptionSectionRecordFragment_NewsletterSubscriptionSectionRecord_form_NewsletterSubscriptionRecord_optImage_ImageFileField | null };

export type Custom_NewsletterSubscriptionSectionRecordFragment = { __typename: 'NewsletterSubscriptionSectionRecord', id: string, form: Custom_NewsletterSubscriptionSectionRecordFragment_NewsletterSubscriptionSectionRecord_form_NewsletterSubscriptionRecord };

export type Custom_PageLinkRecordFragment_PageLinkRecord_pageLink_PageRecord = { __typename: 'PageRecord', slug: string };

export type Custom_PageLinkRecordFragment = { __typename: 'PageLinkRecord', _modelApiKey: string, id: string, text: string, pageLink: Custom_PageLinkRecordFragment_PageLinkRecord_pageLink_PageRecord };

export type Custom_ProductGroupDetailSectionRecordFragment_ProductGroupDetailSectionRecord_groups_ProductGroupRecord_icon_FileField = { __typename: 'FileField', id: string, url: string };

export type Custom_ProductGroupDetailSectionRecordFragment_ProductGroupDetailSectionRecord_groups_ProductGroupRecord = { __typename: 'ProductGroupRecord', id: string, name: string, icon: Custom_ProductGroupDetailSectionRecordFragment_ProductGroupDetailSectionRecord_groups_ProductGroupRecord_icon_FileField };

export type Custom_ProductGroupDetailSectionRecordFragment_ProductGroupDetailSectionRecord_footerImage_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_ProductGroupDetailSectionRecordFragment_ProductGroupDetailSectionRecord_footerImage_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_ProductGroupDetailSectionRecordFragment_ProductGroupDetailSectionRecord_footerImage_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_ProductGroupDetailSectionRecordFragment = { __typename: 'ProductGroupDetailSectionRecord', id: string, title: string, text: string, groups: Array<Custom_ProductGroupDetailSectionRecordFragment_ProductGroupDetailSectionRecord_groups_ProductGroupRecord>, footerImage?: Custom_ProductGroupDetailSectionRecordFragment_ProductGroupDetailSectionRecord_footerImage_ImageFileField | null };

export type Custom_ProductGroupOverviewSectionRecordFragment_ProductGroupOverviewSectionRecord_groups_ProductGroupRecord_icon_FileField = { __typename: 'FileField', id: string, url: string };

export type Custom_ProductGroupOverviewSectionRecordFragment_ProductGroupOverviewSectionRecord_groups_ProductGroupRecord = { __typename: 'ProductGroupRecord', id: string, name: string, icon: Custom_ProductGroupOverviewSectionRecordFragment_ProductGroupOverviewSectionRecord_groups_ProductGroupRecord_icon_FileField };

export type Custom_ProductGroupOverviewSectionRecordFragment_ProductGroupOverviewSectionRecord_pageLink_PageLinkRecord_pageLink_PageRecord = { __typename: 'PageRecord', slug: string };

export type Custom_ProductGroupOverviewSectionRecordFragment_ProductGroupOverviewSectionRecord_pageLink_PageLinkRecord = { __typename: 'PageLinkRecord', id: string, text: string, pageLink: Custom_ProductGroupOverviewSectionRecordFragment_ProductGroupOverviewSectionRecord_pageLink_PageLinkRecord_pageLink_PageRecord };

export type Custom_ProductGroupOverviewSectionRecordFragment_ProductGroupOverviewSectionRecord_footerImage_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_ProductGroupOverviewSectionRecordFragment_ProductGroupOverviewSectionRecord_footerImage_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_ProductGroupOverviewSectionRecordFragment_ProductGroupOverviewSectionRecord_footerImage_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_ProductGroupOverviewSectionRecordFragment_ProductGroupOverviewSectionRecord_footerImageLeft_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_ProductGroupOverviewSectionRecordFragment_ProductGroupOverviewSectionRecord_footerImageLeft_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_ProductGroupOverviewSectionRecordFragment_ProductGroupOverviewSectionRecord_footerImageLeft_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_ProductGroupOverviewSectionRecordFragment_ProductGroupOverviewSectionRecord_footerImageRight_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_ProductGroupOverviewSectionRecordFragment_ProductGroupOverviewSectionRecord_footerImageRight_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_ProductGroupOverviewSectionRecordFragment_ProductGroupOverviewSectionRecord_footerImageRight_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_ProductGroupOverviewSectionRecordFragment = { __typename: 'ProductGroupOverviewSectionRecord', id: string, title: string, text: string, groups: Array<Custom_ProductGroupOverviewSectionRecordFragment_ProductGroupOverviewSectionRecord_groups_ProductGroupRecord>, pageLink: Array<Custom_ProductGroupOverviewSectionRecordFragment_ProductGroupOverviewSectionRecord_pageLink_PageLinkRecord>, footerImage?: Custom_ProductGroupOverviewSectionRecordFragment_ProductGroupOverviewSectionRecord_footerImage_ImageFileField | null, footerImageLeft?: Custom_ProductGroupOverviewSectionRecordFragment_ProductGroupOverviewSectionRecord_footerImageLeft_ImageFileField | null, footerImageRight?: Custom_ProductGroupOverviewSectionRecordFragment_ProductGroupOverviewSectionRecord_footerImageRight_ImageFileField | null };

export type Custom_SplitItemRecordFragment_SplitItemRecord_image_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_SplitItemRecordFragment_SplitItemRecord_image_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_SplitItemRecordFragment_SplitItemRecord_image_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_SplitItemRecordFragment_SplitItemRecord_optLinkItem_CustomLinkRecord = (
  { __typename: 'CustomLinkRecord' }
  & Custom_CustomLinkRecordFragment
);

export type Custom_SplitItemRecordFragment_SplitItemRecord_optLinkItem_PageLinkRecord = (
  { __typename: 'PageLinkRecord' }
  & Custom_PageLinkRecordFragment
);

export type Custom_SplitItemRecordFragment_SplitItemRecord_optLinkItem = Custom_SplitItemRecordFragment_SplitItemRecord_optLinkItem_CustomLinkRecord | Custom_SplitItemRecordFragment_SplitItemRecord_optLinkItem_PageLinkRecord;

export type Custom_SplitItemRecordFragment = { __typename: 'SplitItemRecord', _modelApiKey: string, id: string, text: string, imagePosition: boolean, image: Custom_SplitItemRecordFragment_SplitItemRecord_image_ImageFileField, optLinkItem?: Custom_SplitItemRecordFragment_SplitItemRecord_optLinkItem | null };

export type Custom_SplitSectionRecordFragment_SplitSectionRecord_items_SplitItemRecord = (
  { __typename: 'SplitItemRecord' }
  & Custom_SplitItemRecordFragment
);

export type Custom_SplitSectionRecordFragment = { __typename: 'SplitSectionRecord', id: string, items: Array<Custom_SplitSectionRecordFragment_SplitSectionRecord_items_SplitItemRecord> };

export type Custom_StepsSectionRecordFragment_StepsSectionRecord_steps_StepsCardRecord = { __typename: 'StepsCardRecord', title: string, text: string };

export type Custom_StepsSectionRecordFragment = { __typename: 'StepsSectionRecord', id: string, title: string, steps: Array<Custom_StepsSectionRecordFragment_StepsSectionRecord_steps_StepsCardRecord> };

export type Custom_TestimonialsSectionRecordFragment_TestimonialsSectionRecord_items_TestimonialRecord_personImage_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_TestimonialsSectionRecordFragment_TestimonialsSectionRecord_items_TestimonialRecord_personImage_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_TestimonialsSectionRecordFragment_TestimonialsSectionRecord_items_TestimonialRecord_personImage_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_TestimonialsSectionRecordFragment_TestimonialsSectionRecord_items_TestimonialRecord = { __typename: 'TestimonialRecord', id: string, quote: string, personName: string, personTitle: string, personImage?: Custom_TestimonialsSectionRecordFragment_TestimonialsSectionRecord_items_TestimonialRecord_personImage_ImageFileField | null };

export type Custom_TestimonialsSectionRecordFragment = { __typename: 'TestimonialsSectionRecord', id: string, title: string, optIntro?: string | null, items: Array<Custom_TestimonialsSectionRecordFragment_TestimonialsSectionRecord_items_TestimonialRecord> };

export type Custom_UpcomingEventsSectionRecordFragment_UpcomingEventsSectionRecord_linkPage_PageRecord = { __typename: 'PageRecord', slug: string };

export type Custom_UpcomingEventsSectionRecordFragment = { __typename: 'UpcomingEventsSectionRecord', id: string, title: string, linkText: string, itemCount: number, linkPage: Custom_UpcomingEventsSectionRecordFragment_UpcomingEventsSectionRecord_linkPage_PageRecord };

export type Custom_UspSectionRecordFragment_UspSectionRecord_items_UspRecord_link_PageRecord = { __typename: 'PageRecord', id: string, slug: string };

export type Custom_UspSectionRecordFragment_UspSectionRecord_items_UspRecord_optImage_ImageFileField_responsiveImage_ResponsiveImage = (
  { __typename: 'ResponsiveImage' }
  & DatoImage_ResponsiveImageFragment
);

export type Custom_UspSectionRecordFragment_UspSectionRecord_items_UspRecord_optImage_ImageFileField = { __typename: 'ImageFileField', responsiveImage: Custom_UspSectionRecordFragment_UspSectionRecord_items_UspRecord_optImage_ImageFileField_responsiveImage_ResponsiveImage };

export type Custom_UspSectionRecordFragment_UspSectionRecord_items_UspRecord = { __typename: 'UspRecord', id: string, title: string, optText?: string | null, optLinkText?: string | null, link: Custom_UspSectionRecordFragment_UspSectionRecord_items_UspRecord_link_PageRecord, optImage?: Custom_UspSectionRecordFragment_UspSectionRecord_items_UspRecord_optImage_ImageFileField | null };

export type Custom_UspSectionRecordFragment = { __typename: 'UspSectionRecord', id: string, title: string, items: Array<Custom_UspSectionRecordFragment_UspSectionRecord_items_UspRecord> };

export type DatoImage_ResponsiveImageFragment = { __typename: 'ResponsiveImage', width: number, height: number, src: string, srcSet: string, webpSrcSet: string, alt?: string | null, title?: string | null, sizes: string, base64?: string | null, aspectRatio: number };

export type DatoImage_ResponsiveImage_WithoutPlaceholderFragment = { __typename: 'ResponsiveImage', width: number, height: number, src: string, srcSet: string, webpSrcSet: string, alt?: string | null, title?: string | null, sizes: string, aspectRatio: number };

export const Custom_AllEventsSectionRecordFragmentDoc = gql`
    fragment Custom_AllEventsSectionRecord on AllEventsSectionRecord {
  id
  title
}
    `;
export const DatoImage_ResponsiveImageFragmentDoc = gql`
    fragment DatoImage_ResponsiveImage on ResponsiveImage {
  width
  height
  src
  srcSet
  webpSrcSet
  alt
  title
  sizes
  base64
  aspectRatio
}
    `;
export const Custom_BrandsSectionRecordFragmentDoc = gql`
    fragment Custom_BrandsSectionRecord on BrandsSectionRecord {
  id
  title
  optIntro(markdown: true)
  items {
    id
    name
    logo {
      responsiveImage(imgixParams: {w: 100, auto: [compress, format]}) {
        ...DatoImage_ResponsiveImage
      }
    }
    optLinkUrl
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_CallToActionSectionRecordFragmentDoc = gql`
    fragment Custom_CallToActionSectionRecord on CallToActionSectionRecord {
  id
  superTitle
  title
  text(markdown: true)
  pageLink {
    text
    pageLink {
      title
      slug
    }
  }
  backgroundImage {
    responsiveImage(imgixParams: {}) {
      ...DatoImage_ResponsiveImage
    }
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_CustomLinkRecordFragmentDoc = gql`
    fragment Custom_CustomLinkRecord on CustomLinkRecord {
  _modelApiKey
  id
  text
  customLink
}
    `;
export const Custom_PageLinkRecordFragmentDoc = gql`
    fragment Custom_PageLinkRecord on PageLinkRecord {
  _modelApiKey
  id
  text
  pageLink {
    slug
  }
}
    `;
export const Custom_SplitItemRecordFragmentDoc = gql`
    fragment Custom_SplitItemRecord on SplitItemRecord {
  _modelApiKey
  id
  text(markdown: false)
  imagePosition
  image {
    responsiveImage(
      imgixParams: {fit: crop, w: 800, h: 534, auto: [compress, format]}
    ) {
      ...DatoImage_ResponsiveImage
    }
  }
  optLinkItem {
    ...Custom_CustomLinkRecord
    ...Custom_PageLinkRecord
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}
${Custom_CustomLinkRecordFragmentDoc}
${Custom_PageLinkRecordFragmentDoc}`;
export const Custom_ContentSectionRecordFragmentDoc = gql`
    fragment Custom_ContentSectionRecord on ContentSectionRecord {
  id
  body {
    blocks {
      ... on ButtonRecord {
        _modelApiKey
        id
        label
        url
        primary
      }
      ... on ImageBlockRecord {
        _modelApiKey
        id
        image {
          responsiveImage(
            imgixParams: {fit: crop, w: 1536, h: 470, auto: [compress, format]}
          ) {
            ...DatoImage_ResponsiveImage
          }
        }
      }
      ...Custom_SplitItemRecord
    }
    links
    value
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}
${Custom_SplitItemRecordFragmentDoc}`;
export const Custom_FaqSectionRecordFragmentDoc = gql`
    fragment Custom_FaqSectionRecord on FaqSectionRecord {
  id
  title
  items {
    id
    question
    answer
  }
  image {
    responsiveImage(imgixParams: {auto: [compress, format]}) {
      ...DatoImage_ResponsiveImage
    }
  }
  imagePosition
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_FormSectionRecordFragmentDoc = gql`
    fragment Custom_FormSectionRecord on FormSectionRecord {
  id
  title
  optIntro(markdown: true)
  tallyFormId
  optImage {
    responsiveImage(imgixParams: {auto: [compress, format]}) {
      ...DatoImage_ResponsiveImage
    }
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_HeaderSectionRecordFragmentDoc = gql`
    fragment Custom_HeaderSectionRecord on HeaderSectionRecord {
  id
  optIntro
  item {
    responsiveImage(
      imgixParams: {fit: crop, w: 1536, h: 470, auto: [compress, format]}
    ) {
      ...DatoImage_ResponsiveImage
    }
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_HomepageHeroSectionRecordFragmentDoc = gql`
    fragment Custom_HomepageHeroSectionRecord on HomepageHeroSectionRecord {
  id
  heroTitle
  heroText(markdown: true)
  heroImage {
    responsiveImage(
      imgixParams: {fit: crop, w: 825, h: 435, auto: [compress, format]}
    ) {
      ...DatoImage_ResponsiveImage
    }
  }
  pageLink {
    ... on CustomLinkRecord {
      _modelApiKey
      id
      text
      customLink
    }
    ... on PageLinkRecord {
      _modelApiKey
      id
      text
      pageLink {
        slug
      }
    }
  }
  imageLinks {
    title
    image {
      responsiveImage(
        imgixParams: {fit: crop, w: 700, h: 175, auto: [compress, format]}
      ) {
        ...DatoImage_ResponsiveImage
      }
    }
    optPageLink {
      slug
    }
    optCustomLink
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_LatestTrendsSectionRecordFragmentDoc = gql`
    fragment Custom_LatestTrendsSectionRecord on LatestTrendsSectionRecord {
  id
  title
  linkText
  linkPage {
    slug
  }
  itemCount
}
    `;
export const Custom_MagazineSectionRecordFragmentDoc = gql`
    fragment Custom_MagazineSectionRecord on MagazineSectionRecord {
  id
  item {
    title
    optExtraTitle
    optText
    linkText
    url
    image {
      responsiveImage(
        imgixParams: {fit: max, w: 400, h: 400, auto: [compress, format]}
      ) {
        ...DatoImage_ResponsiveImage
      }
    }
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_NewsletterSubscriptionSectionRecordFragmentDoc = gql`
    fragment Custom_NewsletterSubscriptionSectionRecord on NewsletterSubscriptionSectionRecord {
  id
  form {
    id
    title
    text
    buttonLabel
    inputPlaceholder
    remoteFormUrl
    optImage {
      responsiveImage(imgixParams: {h: 290, auto: [compress, format]}) {
        ...DatoImage_ResponsiveImage
      }
    }
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_ProductGroupDetailSectionRecordFragmentDoc = gql`
    fragment Custom_ProductGroupDetailSectionRecord on ProductGroupDetailSectionRecord {
  id
  title
  text
  groups {
    id
    name
    icon {
      id
      url
    }
  }
  footerImage {
    responsiveImage(imgixParams: {auto: [compress, format]}) {
      ...DatoImage_ResponsiveImage
    }
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_ProductGroupOverviewSectionRecordFragmentDoc = gql`
    fragment Custom_ProductGroupOverviewSectionRecord on ProductGroupOverviewSectionRecord {
  id
  title
  text(markdown: true)
  groups {
    id
    name
    icon {
      id
      url
    }
  }
  pageLink {
    id
    text
    pageLink {
      slug
    }
  }
  footerImage {
    responsiveImage(imgixParams: {auto: [compress, format]}) {
      ...DatoImage_ResponsiveImage
    }
  }
  footerImageLeft {
    responsiveImage(imgixParams: {h: 200, auto: [compress, format]}) {
      ...DatoImage_ResponsiveImage
    }
  }
  footerImageRight {
    responsiveImage(imgixParams: {h: 200, auto: [compress, format]}) {
      ...DatoImage_ResponsiveImage
    }
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_SplitSectionRecordFragmentDoc = gql`
    fragment Custom_SplitSectionRecord on SplitSectionRecord {
  id
  items {
    ...Custom_SplitItemRecord
  }
}
    ${Custom_SplitItemRecordFragmentDoc}`;
export const Custom_StepsSectionRecordFragmentDoc = gql`
    fragment Custom_StepsSectionRecord on StepsSectionRecord {
  id
  title
  steps {
    title
    text(markdown: true)
  }
}
    `;
export const Custom_TestimonialsSectionRecordFragmentDoc = gql`
    fragment Custom_TestimonialsSectionRecord on TestimonialsSectionRecord {
  id
  title
  optIntro(markdown: true)
  items {
    id
    quote(markdown: true)
    personName
    personTitle
    personImage {
      responsiveImage(imgixParams: {w: 46, h: 46, auto: [compress, format]}) {
        ...DatoImage_ResponsiveImage
      }
    }
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const Custom_UpcomingEventsSectionRecordFragmentDoc = gql`
    fragment Custom_UpcomingEventsSectionRecord on UpcomingEventsSectionRecord {
  id
  title
  linkText
  linkPage {
    slug
  }
  itemCount
}
    `;
export const Custom_UspSectionRecordFragmentDoc = gql`
    fragment Custom_UspSectionRecord on UspSectionRecord {
  id
  title
  items {
    id
    title
    link {
      id
      slug
    }
    optImage {
      responsiveImage(
        imgixParams: {fit: crop, w: 616, h: 347, auto: [compress, format]}
      ) {
        ...DatoImage_ResponsiveImage
      }
    }
    optText(markdown: true)
    optLinkText
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const DatoImage_ResponsiveImage_WithoutPlaceholderFragmentDoc = gql`
    fragment DatoImage_ResponsiveImage_WithoutPlaceholder on ResponsiveImage {
  width
  height
  src
  srcSet
  webpSrcSet
  alt
  title
  sizes
  aspectRatio
}
    `;
export const SearchContentDocument = gql`
    query SearchContent($locale: SiteLocale, $filterLocales: [SiteLocale!]) {
  allTrendsInspirations(
    first: 100
    locale: $locale
    filter: {_locales: {allIn: $filterLocales}}
  ) {
    id
    title
    slug
    sections {
      __typename
      ... on ContentSectionRecord {
        body {
          value
        }
      }
    }
  }
  allNews(
    first: 100
    locale: $locale
    filter: {_locales: {allIn: $filterLocales}}
  ) {
    id
    title
    slug
    sections {
      __typename
      ... on ContentSectionRecord {
        body {
          value
        }
      }
    }
  }
}
    `;
export const EventsDetailDocument = gql`
    query EventsDetail($slug: String, $locale: SiteLocale) {
  event(filter: {slug: {eq: $slug}}, locale: $locale) {
    id
    title
    teaserText
    sections {
      __typename
      ...Custom_ContentSectionRecord
      ...Custom_FormSectionRecord
      ...Custom_HeaderSectionRecord
      ...Custom_NewsletterSubscriptionSectionRecord
      ...Custom_UpcomingEventsSectionRecord
    }
    _seoMetaTags {
      content
      tag
      attributes
    }
    _allSlugLocales {
      locale
      value
    }
  }
}
    ${Custom_ContentSectionRecordFragmentDoc}
${Custom_FormSectionRecordFragmentDoc}
${Custom_HeaderSectionRecordFragmentDoc}
${Custom_NewsletterSubscriptionSectionRecordFragmentDoc}
${Custom_UpcomingEventsSectionRecordFragmentDoc}`;
export const EventsDocument = gql`
    query Events($locale: SiteLocale, $first: IntType, $skip: IntType, $filterLocales: [SiteLocale!], $filterEndDate: Date) {
  allEvents(
    locale: $locale
    orderBy: startDate_ASC
    first: $first
    skip: $skip
    filter: {_locales: {allIn: $filterLocales}, endDate: {gte: $filterEndDate}}
  ) {
    id
    title
    startDate
    endDate
    teaserText
    teaserLinkText
    teaserImage {
      responsiveImage(
        imgixParams: {fit: crop, w: 320, h: 85, auto: [compress, format]}
      ) {
        ...DatoImage_ResponsiveImage_WithoutPlaceholder
      }
    }
    slug
  }
  _allEventsMeta(
    locale: $locale
    filter: {_locales: {allIn: $filterLocales}, endDate: {gte: $filterEndDate}}
  ) {
    count
  }
}
    ${DatoImage_ResponsiveImage_WithoutPlaceholderFragmentDoc}`;
export const LayoutDocument = gql`
    query Layout($locale: SiteLocale) {
  layout(locale: $locale) {
    topNavigation {
      title
      slug
      id
    }
    mainNavigation {
      slug
      title
      id
    }
    headerButtonLink {
      slug
    }
    headerButtonText
    webshopButtonText
    usps {
      id
      title
      shortText
      icon
      link {
        slug
      }
    }
    socialMediaLinks {
      id
      name
      url
      iconName
    }
    newsletterSubscriptionForm {
      id
      title
      text
      buttonLabel
      inputPlaceholder
      remoteFormUrl
    }
    footerSubtitle(markdown: false)
    footerLinks {
      id
      title
      slug
    }
    footerColumns {
      id
      title
      links {
        ... on PageLinkRecord {
          _modelApiKey
          id
          text
          pageLink {
            title
            slug
            id
          }
        }
        ... on CustomLinkRecord {
          _modelApiKey
          id
          customLink
          text
        }
      }
    }
    parentPageEvents {
      title
      slug
    }
    parentPageNews {
      title
      slug
    }
    parentPageStores {
      title
      slug
    }
    parentPageTrends {
      title
      slug
    }
  }
}
    `;
export const LegalDocument = gql`
    query Legal($slug: String, $locale: SiteLocale) {
  legalPage(fallbackLocales: nl_BE, locale: $locale, filter: {slug: {eq: $slug}}) {
    _seoMetaTags(locale: $locale) {
      attributes
      content
      tag
    }
    content {
      blocks
      links
      value
    }
    id
    slug
    title
    _allSlugLocales {
      locale
      value
    }
  }
}
    `;
export const NewsDetailDocument = gql`
    query NewsDetail($slug: String, $locale: SiteLocale) {
  news(filter: {slug: {eq: $slug}}, locale: $locale) {
    id
    title
    sections {
      __typename
      ...Custom_ContentSectionRecord
      ...Custom_HeaderSectionRecord
      ...Custom_NewsletterSubscriptionSectionRecord
      ...Custom_SplitSectionRecord
      ...Custom_UpcomingEventsSectionRecord
    }
    _publishedAt
    _seoMetaTags {
      content
      tag
      attributes
    }
    _allSlugLocales {
      locale
      value
    }
  }
}
    ${Custom_ContentSectionRecordFragmentDoc}
${Custom_HeaderSectionRecordFragmentDoc}
${Custom_NewsletterSubscriptionSectionRecordFragmentDoc}
${Custom_SplitSectionRecordFragmentDoc}
${Custom_UpcomingEventsSectionRecordFragmentDoc}`;
export const NewsDocument = gql`
    query News($locale: SiteLocale, $first: IntType, $skip: IntType, $filterLocales: [SiteLocale!]) {
  allNews(
    locale: $locale
    orderBy: _publishedAt_DESC
    first: $first
    skip: $skip
    filter: {_locales: {allIn: $filterLocales}}
  ) {
    id
    title
    teaserText
    teaserImage {
      responsiveImage(
        imgixParams: {fit: crop, w: 616, h: 347, auto: [compress, format]}
      ) {
        ...DatoImage_ResponsiveImage
      }
    }
    slug
  }
  _allNewsMeta(locale: $locale, filter: {_locales: {allIn: $filterLocales}}) {
    count
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const PageDocument = gql`
    query Page($slug: String, $locale: SiteLocale) {
  page(filter: {slug: {eq: $slug}}, locale: $locale) {
    id
    title
    sections {
      __typename
      ...Custom_AllEventsSectionRecord
      ...Custom_BrandsSectionRecord
      ...Custom_CallToActionSectionRecord
      ...Custom_FormSectionRecord
      ...Custom_ContentSectionRecord
      ...Custom_FaqSectionRecord
      ...Custom_HeaderSectionRecord
      ...Custom_HomepageHeroSectionRecord
      ...Custom_LatestTrendsSectionRecord
      ...Custom_MagazineSectionRecord
      ...Custom_NewsletterSubscriptionSectionRecord
      ...Custom_ProductGroupDetailSectionRecord
      ...Custom_ProductGroupOverviewSectionRecord
      ...Custom_SplitSectionRecord
      ...Custom_StepsSectionRecord
      ...Custom_TestimonialsSectionRecord
      ...Custom_UpcomingEventsSectionRecord
      ...Custom_UspSectionRecord
    }
    parentPage {
      id
      title
      slug
    }
    _seoMetaTags {
      content
      tag
      attributes
    }
    _allSlugLocales {
      locale
      value
    }
  }
}
    ${Custom_AllEventsSectionRecordFragmentDoc}
${Custom_BrandsSectionRecordFragmentDoc}
${Custom_CallToActionSectionRecordFragmentDoc}
${Custom_FormSectionRecordFragmentDoc}
${Custom_ContentSectionRecordFragmentDoc}
${Custom_FaqSectionRecordFragmentDoc}
${Custom_HeaderSectionRecordFragmentDoc}
${Custom_HomepageHeroSectionRecordFragmentDoc}
${Custom_LatestTrendsSectionRecordFragmentDoc}
${Custom_MagazineSectionRecordFragmentDoc}
${Custom_NewsletterSubscriptionSectionRecordFragmentDoc}
${Custom_ProductGroupDetailSectionRecordFragmentDoc}
${Custom_ProductGroupOverviewSectionRecordFragmentDoc}
${Custom_SplitSectionRecordFragmentDoc}
${Custom_StepsSectionRecordFragmentDoc}
${Custom_TestimonialsSectionRecordFragmentDoc}
${Custom_UpcomingEventsSectionRecordFragmentDoc}
${Custom_UspSectionRecordFragmentDoc}`;
export const StoresDetailDocument = gql`
    query StoresDetail($slug: String, $locale: SiteLocale) {
  store(filter: {slug: {eq: $slug}}, locale: $locale) {
    id
    name
    address
    location {
      latitude
      longitude
    }
    optPhoneNumber
    optFaxNumber
    optEmailAddress
    openingHours {
      id
      dayOfWeek
      startHour
      endHour
    }
    sections {
      __typename
      ...Custom_HeaderSectionRecord
      ...Custom_MagazineSectionRecord
      ...Custom_NewsletterSubscriptionSectionRecord
    }
    _seoMetaTags {
      content
      tag
      attributes
    }
    _allSlugLocales {
      locale
      value
    }
  }
}
    ${Custom_HeaderSectionRecordFragmentDoc}
${Custom_MagazineSectionRecordFragmentDoc}
${Custom_NewsletterSubscriptionSectionRecordFragmentDoc}`;
export const StoresDocument = gql`
    query Stores($locale: SiteLocale, $filterLocales: [SiteLocale!]) {
  allStores(locale: $locale, filter: {_locales: {allIn: $filterLocales}}) {
    id
    name
    address
    location {
      latitude
      longitude
    }
    optPhoneNumber
    optFaxNumber
    optEmailAddress
    openingHours {
      id
      dayOfWeek
      startHour
      endHour
    }
    teaserImage {
      responsiveImage(
        imgixParams: {fit: crop, w: 616, h: 347, auto: [compress, format]}
      ) {
        ...DatoImage_ResponsiveImage
      }
    }
    teaserLinkText
    slug
  }
  _allStoresMeta(locale: $locale, filter: {_locales: {allIn: $filterLocales}}) {
    count
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const TrendsDetailDocument = gql`
    query TrendsDetail($slug: String, $locale: SiteLocale) {
  trendsInspiration(filter: {slug: {eq: $slug}}, locale: $locale) {
    id
    title
    tags {
      id
      slug
      tag
    }
    sections {
      __typename
      ...Custom_ContentSectionRecord
      ...Custom_HeaderSectionRecord
      ...Custom_LatestTrendsSectionRecord
      ...Custom_MagazineSectionRecord
      ...Custom_NewsletterSubscriptionSectionRecord
      ...Custom_SplitSectionRecord
      ...Custom_UpcomingEventsSectionRecord
    }
    _publishedAt
    _seoMetaTags {
      content
      tag
      attributes
    }
    _allSlugLocales {
      locale
      value
    }
  }
}
    ${Custom_ContentSectionRecordFragmentDoc}
${Custom_HeaderSectionRecordFragmentDoc}
${Custom_LatestTrendsSectionRecordFragmentDoc}
${Custom_MagazineSectionRecordFragmentDoc}
${Custom_NewsletterSubscriptionSectionRecordFragmentDoc}
${Custom_SplitSectionRecordFragmentDoc}
${Custom_UpcomingEventsSectionRecordFragmentDoc}`;
export const TrendsTagsDocument = gql`
    query TrendsTags($locale: SiteLocale) {
  allTrendsInspirationTags(locale: $locale) {
    id
    slug
    tag
  }
}
    `;
export const TrendsDocument = gql`
    query Trends($locale: SiteLocale, $first: IntType, $skip: IntType, $filterLocales: [SiteLocale!], $filterTags: [ItemId]) {
  allTrendsInspirations(
    locale: $locale
    orderBy: _publishedAt_DESC
    first: $first
    skip: $skip
    filter: {_locales: {allIn: $filterLocales}, tags: {anyIn: $filterTags}}
  ) {
    id
    title
    tags {
      id
      slug
      tag
    }
    teaserText
    teaserImage {
      responsiveImage(
        imgixParams: {fit: crop, w: 616, h: 347, auto: [compress, format]}
      ) {
        ...DatoImage_ResponsiveImage
      }
    }
    slug
  }
  _allTrendsInspirationsMeta(
    locale: $locale
    filter: {_locales: {allIn: $filterLocales}, tags: {anyIn: $filterTags}}
  ) {
    count
  }
}
    ${DatoImage_ResponsiveImageFragmentDoc}`;
export const SitemapDocument = gql`
    query Sitemap {
  allPages(first: 500) {
    _allSlugLocales {
      locale
      value
    }
    _publishedAt
    seoMeta {
      noIndex
    }
  }
  allEvents(first: 500) {
    _allSlugLocales {
      locale
      value
    }
    _publishedAt
    seoMeta {
      noIndex
    }
  }
  allNews(first: 500) {
    _allSlugLocales {
      locale
      value
    }
    _publishedAt
    seoMeta {
      noIndex
    }
  }
  allStores(first: 500) {
    _allSlugLocales {
      locale
      value
    }
    _publishedAt
    seoMeta {
      noIndex
    }
  }
  allTrendsInspirations(first: 500) {
    _allSlugLocales {
      locale
      value
    }
    _publishedAt
    seoMeta {
      noIndex
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();
const SearchContentDocumentString = print(SearchContentDocument);
const EventsDetailDocumentString = print(EventsDetailDocument);
const EventsDocumentString = print(EventsDocument);
const LayoutDocumentString = print(LayoutDocument);
const LegalDocumentString = print(LegalDocument);
const NewsDetailDocumentString = print(NewsDetailDocument);
const NewsDocumentString = print(NewsDocument);
const PageDocumentString = print(PageDocument);
const StoresDetailDocumentString = print(StoresDetailDocument);
const StoresDocumentString = print(StoresDocument);
const TrendsDetailDocumentString = print(TrendsDetailDocument);
const TrendsTagsDocumentString = print(TrendsTagsDocument);
const TrendsDocumentString = print(TrendsDocument);
const SitemapDocumentString = print(SitemapDocument);
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    SearchContent(variables?: SearchContentQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: SearchContentQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<SearchContentQuery>(SearchContentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SearchContent', 'query', variables);
    },
    EventsDetail(variables?: EventsDetailQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: EventsDetailQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<EventsDetailQuery>(EventsDetailDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'EventsDetail', 'query', variables);
    },
    Events(variables?: EventsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: EventsQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<EventsQuery>(EventsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Events', 'query', variables);
    },
    Layout(variables?: LayoutQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: LayoutQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<LayoutQuery>(LayoutDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Layout', 'query', variables);
    },
    Legal(variables?: LegalQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: LegalQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<LegalQuery>(LegalDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Legal', 'query', variables);
    },
    NewsDetail(variables?: NewsDetailQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: NewsDetailQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<NewsDetailQuery>(NewsDetailDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'NewsDetail', 'query', variables);
    },
    News(variables?: NewsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: NewsQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<NewsQuery>(NewsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'News', 'query', variables);
    },
    Page(variables?: PageQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: PageQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<PageQuery>(PageDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Page', 'query', variables);
    },
    StoresDetail(variables?: StoresDetailQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: StoresDetailQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<StoresDetailQuery>(StoresDetailDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'StoresDetail', 'query', variables);
    },
    Stores(variables?: StoresQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: StoresQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<StoresQuery>(StoresDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Stores', 'query', variables);
    },
    TrendsDetail(variables?: TrendsDetailQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: TrendsDetailQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<TrendsDetailQuery>(TrendsDetailDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TrendsDetail', 'query', variables);
    },
    TrendsTags(variables?: TrendsTagsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: TrendsTagsQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<TrendsTagsQuery>(TrendsTagsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TrendsTags', 'query', variables);
    },
    Trends(variables?: TrendsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: TrendsQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<TrendsQuery>(TrendsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Trends', 'query', variables);
    },
    Sitemap(variables?: SitemapQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<{ data: SitemapQuery; errors?: GraphQLError[]; extensions?: any; headers: Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<SitemapQuery>(SitemapDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Sitemap', 'query', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;