import type { WebhookPayload } from "@esc-bv/ebs-nuxt-datocms-preview/server/api/datocms/preview-links";
import { getSiteLocaleFromString } from "~~/server/utils/localeMapping";
import { getSlugPrefix } from "~~/server/utils/slugPrefixes";

export default defineAppConfig({
  ui: {
    primary: "th-primary",
    badge: {
      strategy: "override",
      font: "font-semibold",
      rounded: "rounded-2xl",
      size: { xs: "text-xs px-xs py-xs leading-4" },
      variant: { solid: "bg-white text-primary" },
    },
    button: {
      strategy: "override",
      base: "flex flex-wrap justify-center transition",
      rounded: "rounded-full",
      color: {
        black: {
          solid: "bg-th-neutral-500 hover:bg-th-neutral-400",
        },
      },
      size: {
        xs: "text-xs px-sm py-xs",
        sm: "text-sm px-sm py-xs",
        md: "text-md md:text-lg px-md md:px-lg py-sm md:py-md",
      },
      variant: {
        solid: "border-{color}-500 bg-{color}-500 hover:bg-{color}-400 text-white",
        outline: "border-{color}-500 bg-transparent hover:bg-{color}-50 text-{color}-500",
      },
    },
    input: {
      strategy: "override",
      base: "relative block w-full border-2 border-th-neutral-100 font-medium disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none",
      variant: {
        none: "bg-white focus:border-non focus:ring-0 focus:shadow-none",
      },
      default: {
        size: "xl",
        variant: "none",
      },
    },
    pagination: {
      strategy: "override",
      base: "border-0 ring-0 shadow-none size-10 flex justify-center",
      rounded: "rounded-md first-of-type:rounded-md last-of-type:rounded-md",
    },
  },

  datoCmsPlugin: {
    secretValue: "<secretValue>",
  },

  datoCmsPreview: {
    webhookSecretValue: "5d43666b53d7448e",
    cryptoPassword: "47GOFH9ndb4YfQnu3yg4aWX4MuDK05hY",
    generatePreviewUrl: generatePreviewUrl,
  },
});

function generatePreviewUrl({ item, itemType, locale }: WebhookPayload) {
  const localePrefix = locale.toLowerCase();
  const siteLocale = getSiteLocaleFromString(localePrefix);
  const slug = (item.attributes.slug && item.attributes.slug[locale]) || "";
  switch (itemType.attributes.api_key) {
    case "legal_page":
      return `/${localePrefix}${getSlugPrefix("legal", siteLocale)}/${slug}`;
    case "event":
      return `/${localePrefix}${getSlugPrefix("events", siteLocale)}/${slug}`;
    case "news":
      return `/${localePrefix}${getSlugPrefix("news", siteLocale)}/${slug}`;
    case "trends_inspiration":
      return `/${localePrefix}${getSlugPrefix("trends", siteLocale)}/${slug}`;
    case "store":
      return `/${localePrefix}${getSlugPrefix("stores", siteLocale)}/${slug}`;
    case "page":
      return `/${localePrefix}/${slug}`;
    case "layout":
      return `/${localePrefix}/`;
    default:
      return null;
  }
}
