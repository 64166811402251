import { SiteLocale } from "~~/server/graphql/datocms/generated";

const enumToString: Map<SiteLocale, string> = new Map([
  [SiteLocale.NlBe, "nl-be"],
  [SiteLocale.FrBe, "fr-be"],
]);

const stringToEnum: Map<string, SiteLocale> = new Map([
  ["nl-be", SiteLocale.NlBe],
  ["fr-be", SiteLocale.FrBe],
]);

export function getSiteLocaleFromString(locale: string): SiteLocale {
  return stringToEnum.get(locale.toLocaleLowerCase()) as SiteLocale;
}
export function getStringFromSiteLocale(locale: SiteLocale): string {
  return enumToString.get(locale) as string;
}
