import revive_payload_client_RAkovWPYXp from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.10.7_db0@0.2.4_encoding@0.1.13_eslint@9.20.1_km52ij6plhbn4iwjto2ucnby7a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_BQnc7dAOw2 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.10.7_db0@0.2.4_encoding@0.1.13_eslint@9.20.1_km52ij6plhbn4iwjto2ucnby7a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_lD0Phz66vv from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.10.7_db0@0.2.4_encoding@0.1.13_eslint@9.20.1_km52ij6plhbn4iwjto2ucnby7a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_cNxaIPSt2V from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.1.0_magicast@0.3.5_vue@3.5.13_typescript@5.3.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_W9anPXXaiY from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.10.7_db0@0.2.4_encoding@0.1.13_eslint@9.20.1_km52ij6plhbn4iwjto2ucnby7a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_QTea538rOf from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.10.7_db0@0.2.4_encoding@0.1.13_eslint@9.20.1_km52ij6plhbn4iwjto2ucnby7a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_82bbpXEJG6 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.10.7_db0@0.2.4_encoding@0.1.13_eslint@9.20.1_km52ij6plhbn4iwjto2ucnby7a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_nCmsveGHCy from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.10.7_db0@0.2.4_encoding@0.1.13_eslint@9.20.1_km52ij6plhbn4iwjto2ucnby7a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_zlvi6dcIsi from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_s0Bi4FlzTC from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.10.7_db0@0.2.4_encoding@0.1.13_eslint@9.20.1_km52ij6plhbn4iwjto2ucnby7a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_Ki8d0uHOnv from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_typescript@5.3.3_vite@6.1.1_zrnhgfyzpdd6flfbf2xkqtna6i/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_pLOpnhMZ8H from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_typescript@5.3.3_vite@6.1.1_zrnhgfyzpdd6flfbf2xkqtna6i/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_LukuoLNjRc from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_typescript@5.3.3_vite@6.1.1_zrnhgfyzpdd6flfbf2xkqtna6i/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_fxr5QpR3De from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_7OHI6728Q2 from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_vite@6.1.1_@types+node@20.10.7_jiti@2.4.2_sass@1.85.0_terser_xoulldp4f3rbfplhcfge2xcl7q/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import siteConfig_sxDi4DvDNz from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.12_magicast@0.3.5_rollup@4.34.8_vue@3.5.13_typescript@5.3.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_L25RwWttlx from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.12_magicast@0.3.5_rollup@4.34.8_vue@3.5.13_typescript@5.3.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_w4qrpt0XSL from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.12_magicast@0.3.5_rollup@4.34.8_vue@3.5.13_typescript@5.3.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_8F2AnWv0Ta from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.12_magicast@0.3.5_rollup@4.34.8_vue@3.5.13_typescript@5.3.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import i18n_otnoXBZKfH from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.1.0_magicast@0.3.5_vue@3.5.13_typescript@5.3.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import switch_locale_path_ssr_g5UTYElM7p from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.8_vue@3.5.13_typescript@5.3.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_TuxEeIxr6S from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.8_vue@3.5.13_typescript@5.3.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import sentry_client_a4hAB0MOGi from "/vercel/path0/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_RAkovWPYXp,
  unhead_BQnc7dAOw2,
  router_lD0Phz66vv,
  _0_siteConfig_cNxaIPSt2V,
  payload_client_W9anPXXaiY,
  navigation_repaint_client_QTea538rOf,
  check_outdated_build_client_82bbpXEJG6,
  chunk_reload_client_nCmsveGHCy,
  components_plugin_zlvi6dcIsi,
  prefetch_client_s0Bi4FlzTC,
  slideovers_Ki8d0uHOnv,
  modals_pLOpnhMZ8H,
  colors_LukuoLNjRc,
  plugin_client_fxr5QpR3De,
  plugin_7OHI6728Q2,
  siteConfig_sxDi4DvDNz,
  inferSeoMetaPlugin_L25RwWttlx,
  titles_w4qrpt0XSL,
  defaultsWaitI18n_8F2AnWv0Ta,
  i18n_otnoXBZKfH,
  switch_locale_path_ssr_g5UTYElM7p,
  i18n_TuxEeIxr6S,
  sentry_client_a4hAB0MOGi
]