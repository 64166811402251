<script setup lang="ts">
// FALLBACK
useHead({
  title: "Spuntini",
});

// https://scripts.nuxt.com/scripts/tracking/google-tag-manager
// Call GTM via useGtmTracking in other places.
const { GTM_ID } = useRuntimeConfig().public;

useScriptGoogleTagManager({
  id: GTM_ID,
});
</script>

<template>
  <NuxtLoadingIndicator color="#C81414" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
