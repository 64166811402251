import type { PageType } from "~~/models/pages";
import { SiteLocale } from "~~/server/graphql/datocms/generated";

export function getSlugPrefix(pageType: PageType, locale: SiteLocale) {
  switch (pageType) {
    case "news":
      switch (locale) {
        case SiteLocale.FrBe:
          return "/nouvelles";
        default:
          return "/nieuws";
      }

    case "events":
      switch (locale) {
        case SiteLocale.FrBe:
          return "/evenements";
        default:
          return "/evenementen";
      }

    case "stores":
      switch (locale) {
        case SiteLocale.FrBe:
          return "/etablissements";
        default:
          return "/vestigingen";
      }

    case "trends":
      switch (locale) {
        case SiteLocale.FrBe:
          return "/tendances-inspiration";
        default:
          return "/trends-inspiratie";
      }

    case "legal":
      return "/legal";

    default:
      return "";
  }
}
