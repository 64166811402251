<script setup lang="ts">
// https://nuxt.com/docs/getting-started/error-handling#error-page
const props = defineProps({
  error: Object,
});

useHead({
  title: `Spuntini - Error ${props.error?.statusCode}`,
});
</script>

<template>
  <Head>
    <Meta name="robots" content="noindex" />
  </Head>

  <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div class="text-center">
      <p class="text-primary-600 text-base font-semibold">
        {{ error?.statusCode }} error
      </p>
      <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
        {{ error?.statusMessage || error?.message || 'Unknown error' }}
      </h1>
      <p v-if="error?.statusCode === 404" class="mt-6 text-base leading-7 text-gray-600">
        Sorry, we couldn't find the page you're looking for.
      </p>
      <div class="mt-10 flex items-center justify-center gap-x-6">
        <UButton to="/" external>
          Go back home
        </UButton>
        <!-- <a href="#" class="text-sm font-semibold text-gray-900">Contact support <span aria-hidden="true">&rarr;</span></a> -->
      </div>
      
    </div>
  </main>
</template>
